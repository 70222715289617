import Path from "./path";
import {BaseConnector} from "@duet3d/connectors";

export interface ListFileItem {
    name: string;
    fullName: string;
    fullPath: string;
    lastModified: Date | null;
    size: bigint | number;
}

export interface ListDirectoryItem {
    name: string;
    fullPath: string;
    lastModified: Date | null;
}

export interface DirectoryList {
    dirs: ListDirectoryItem[];
    files: ListFileItem[];
}

const loadDirectory = async (connection: BaseConnector, currentBasePath: string, directory: string): Promise<DirectoryList> => {

    if (connection !== undefined) {
        const fullPath = Path.combine(currentBasePath, directory.substring(1));

        return connection.getFileList(fullPath)
            .then((files) => {
                return files.reduce((acc, cur, idx) => {
                    if (cur.isDirectory) {
                        acc.dirs = acc.dirs.concat({
                            name: cur.name,
                            fullPath: Path.combine(currentBasePath, directory.substring(1), cur.name).replace("//", "/"),
                            lastModified: cur.lastModified,
                        } as ListDirectoryItem).sort((a, b) => a.name.localeCompare(b.name));
                    } else {
                        acc.files = acc.files.concat({
                            fullName: cur.name,
                            name: Path.stripMacroFilename(cur.name),
                            fullPath: Path.combine(currentBasePath, directory.substring(1), cur.name).replace("//", "/"),
                            lastModified: cur.lastModified,
                            size: cur.size,
                        } as ListFileItem).sort((a, b) => a.name.localeCompare(b.name));
                    }
                    return acc;
                }, {files: [], dirs: []} as DirectoryList);
            })
            .catch((e) => {
                throw new Error(e);
            })
    }
    return {dirs: [], files: []};
}

export default loadDirectory;
