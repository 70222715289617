import {reduxStore, useAppSelector} from "../ReduxStore";
import {enqueueSnackbar} from "notistack";
import i18n from "../i18n"
import {snackbarError, snackbarInfo, snackbarSuccess} from "../Helpers/SnackbarMode";
import Path from "./path";
import {FileTransfer} from "./FileTransfer";
import {BaseConnector} from "@duet3d/connectors";
import JSZip from "jszip";
import {rejects} from "node:assert";
import fileUpload from "../Components/FileUpload";
import {dispatchEvent} from "../EventSystem/EventDispatcher";
const {t} = i18n;

const webExtensions: Array<string> = [".htm", ".html", ".ico", ".xml", ".css", ".map", ".js", ".ttf", ".eot", ".svg", ".woff", ".woff2", ".jpeg", ".jpg", ".png"];

/**
 * Types of uploads
 */
export enum UploadType {
    /**
     * Upload to /gcodes
     */
    gcodes = "gcodes",

    /**
     * Upload & start (to /gcodes)
     */
    start = "start",

    /**
     * Upload to /macros
     */
    macros = "macros",

    /**
     * Upload to /filaments
     */
    filaments = "filaments",

    /**
     * Upload to /firmware (used to be /sys)
     */
    firmware = "firmware",

    /**
     * Upload to /menu
     */
    menu = "menu",

    /**
     * Upload to /sys
     */
    system = "system",

    /**
     * Upload to /www
     */
    web = "web",

    /**
     * Upload for plugin installation
     */
    plugin = "plugin",

    /**
     * Upload for general updates (firmware, web interface)
     */
    update = "update"
}

export interface UploadFileData {
    filename: string;
    content: string | Blob | File;
}

export class UploadController {
    private fileTransfer: FileTransfer;
    private store: any;

    constructor(fileTransfer: FileTransfer) {
        this.fileTransfer = fileTransfer;
    }

    public setStore(store: any) {
        this.store = store;
    }

    public upload(data: UploadFileData[], path: string, target: UploadType, silent: boolean = false) {
        return new Promise<void>((resolve, reject) => {
            if (target === UploadType.start) {
                this.processStart(resolve, reject, data, path);
                return;
            }
            if (target === UploadType.filaments) {
                if (data.length !== 1) {
                    enqueueSnackbar(t("error.uploadNoSingleZIP"), snackbarError());
                    reject("uploadNoSingleZIP");
                    return;
                }
                if (!data[0].filename.endsWith(".zip")) {
                    enqueueSnackbar(t("error.uploadNoSingleZIP"), snackbarError());
                    reject("uploadNoSingleZIP");
                    return;
                }
                const extensions = this.accept(UploadType.gcodes)?.split(",") || "";

                JSZip.loadAsync(data[0].content)
                    .then((zip) => {
                        if (!Object.keys(zip.files).reduce((acc, cur, idx) => {
                            const extension = "." + cur.split(".").pop() as string;
                            if (!extensions.includes(extension)) {
                                return acc;
                            }
                            return true;
                        }, false)) {
                            enqueueSnackbar(t("error.uploadNoFiles"), snackbarError());
                            reject("uploadNoFiles");
                            return;
                        }
                        const dir = Object.keys(zip.files)[0];
                        if (!zip.files[dir].dir) {
                            enqueueSnackbar(t("error.uploadNoFiles"), snackbarError());
                            reject("uploadNoFiles");
                            return;
                        }
                        this.fileTransfer.connector().makeDirectory(
                                this.store.getState().objectModel.current.model.directories.filaments + dir
                            )
                            .then(async () => {
                                for (const file of Object.keys(zip.files)) {
                                    const extension = "." + file.split(".").pop() as string;
                                    if (extensions.includes(extension)) {
                                        await this.fileTransfer.upload(
                                            this.store.getState().objectModel.current.model.directories.filaments + file,
                                            await zip.files[file].async("blob"),
                                            undefined,
                                            undefined,
                                            true
                                        );
                                    }
                                }
                                enqueueSnackbar(t("dialog.fileTransfer.uploadDoneTitle"), snackbarSuccess());
                                resolve();
                            })
                            .catch((reason) => {
                                enqueueSnackbar(t("error.operationFailed", {"0": reason}), snackbarError());
                                reject("operationFailed");
                            })
                    })
                return;
            }
            if (target === UploadType.gcodes || target === UploadType.macros ||
                target === UploadType.system || target === UploadType.firmware) {

                if (data.length === 1 && data[0].filename.endsWith(".zip")) {
                    this.uploadZip(resolve, reject, data[0], path, target);
                    return;
                }
                if (data.reduce((acc, cur, idx) => {
                    if (cur.filename.endsWith(".zip")) {
                        return true;
                    }
                    return acc;
                }, false)) {
                    enqueueSnackbar(t("error.uploadNoSingleZIP"), snackbarError());
                    reject("uploadNoSingleZIP");
                    return;
                }
                this.uploadFiles(resolve, reject, data, path, target, silent);
                return;
            }
            reject("noFileUploadMethod");
        });
    }

    private uploadFiles(resolve: any, reject: any, files: UploadFileData[], path: string, target: UploadType, silent?: boolean) {
        Promise.all(files.map((f) => {
                const destination = Path.combine(this.destinationDirectory(target), path.substring(1), f.filename).replace("//", "/");
                return this.fileTransfer.upload(destination, f.content, undefined, undefined, silent);
        }))
            .then(() => resolve())
            .catch((reason) => reject(reason));
    }

    private uploadZip(resolve: any, reject: any, zipfile: UploadFileData, path: string, target: UploadType, basePath: string | undefined = undefined) {
        const extensions = this.accept(target)?.split(",") || "";
        if (basePath === undefined) {
            basePath = this.destinationDirectory(target);
        }

        const destinationBase = Path.combine(this.destinationDirectory(target), path.substring(1)).replace("//", "/");

        JSZip.loadAsync(zipfile.content)
            .then((zip) => {
                if (!Object.keys(zip.files).reduce((acc, cur, idx) => {
                    if (!zip.files[cur].dir) {
                        const extension = "." + cur.split(".").pop() as string;
                        if (!extensions.includes(extension) && !extensions.includes("*")) {
                            return acc;
                        }
                        return true;
                    }
                    return acc;
                }, false)) {
                    enqueueSnackbar(t("error.uploadNoFiles"), snackbarError());
                    reject("uploadNoFiles");
                    return;
                }

                (async () => {
                    const filelist: {[key: string]: {basename: string, progress: number}} = {};

                    for (const file of Object.keys(zip.files)) {
                        const current = zip.files[file]
                        if (current.dir) {
                            continue;
                        }
                        const basename = file.split("/").pop() as string;
                        const extension = "." + basename.split(".").pop() as string;
                        if (extensions.includes(extension) || extensions.includes("*")) {
                            filelist[file] = {basename: basename, progress: 0};
                        }
                    }

                    dispatchEvent("upload", JSON.stringify({open: true, zipfile: zipfile.filename, filelist: filelist}));

                    for (const file of Object.keys(zip.files)) {
                        const current = zip.files[file]
                        if (current.dir) {
                            const dn = Path.combine(destinationBase, current.name);
                            try {
                                await this.fileTransfer.connector().makeDirectory(dn);
                            }
                            catch (e) {}
                        }
                        else {
                            const extension = "." + file.split(".").pop() as string;
                            if (extensions.includes(extension) || extensions.includes("*")) {
                                try {
                                    const dest = Path.combine(destinationBase, file);
                                    await this.fileTransfer.upload(
                                        dest,
                                        await current.async("blob"),
                                        undefined,
                                        (loaded, total, retries) => {
                                            filelist[file].progress = 100 - (total - loaded) * 100 / total;
                                            dispatchEvent("upload", JSON.stringify({
                                                open: true,
                                                zipfile: zipfile.filename,
                                                filelist: filelist
                                            }));
                                        },
                                        true,
                                    );
                                }
                                catch (err)
                                {
                                    console.error(err);
                                }
                            }
                        }
                    }
                    dispatchEvent("upload", JSON.stringify({open: false}));

                    resolve();
                })();
            })
            .catch((reason) => {
                enqueueSnackbar(t("error.operationFailed", {"0": reason}), snackbarError());
                reject("operationFailed");
            })
    }

    private processStart(resolve: any, reject: any, data: UploadFileData[], path: string) {
        if (data.length !== 1) {
            enqueueSnackbar(t("error.uploadStartWrongFileCount"), snackbarError());
            reject("uploadStartWrongFileCount");
            return;
        }

        (async () => {
            let content = data[0].content;

            if (data[0].filename.endsWith(".zip")) {
                let zip = undefined
                try {
                    zip = await JSZip.loadAsync(data[0].content);
                }
                catch (e) {
                    console.error(e);
                    reject("error");
                }

                if (zip === undefined) {
                    reject("error");
                    return;
                }

                if (Object.keys(zip.files).length !== 1) {
                    enqueueSnackbar(t("error.uploadStartWrongFileCount"), snackbarError());
                    reject("uploadStartWrongFileCount");
                }
                const extensions = this.accept(UploadType.gcodes)?.split(",") || "";
                const file = Object.keys(zip.files)[0];
                const extension = "." + file.split(".").pop() as string;
                if (!extensions.includes(extension) && !extensions.includes("*")) {
                    enqueueSnackbar(t("error.uploadNoFiles"), snackbarError());
                    reject("uploadNoFiles");
                }

                content = await zip.files[file].async("blob");
            }

            const destination = Path.combine(this.destinationDirectory(UploadType.start), path.substring(1), data[0].filename).replace("//", "/");
            this.fileTransfer.upload(destination, content)
                .then(() => {
                    this.fileTransfer.connector().sendCode(`M32 "${destination}"`, false)
                        .then(() => {
                            enqueueSnackbar(t("notification.upload.printing", {"0": data[0].filename}), snackbarInfo());
                            resolve();
                        })
                        .catch((reason) => {
                            enqueueSnackbar(t("notification.upload.failed", {"0": data[0].filename}), snackbarInfo());
                            reject("error");
                        })
                })
                .catch((reason) => {
                    reject(reason);
                    console.log(reason);
                })
        })();
    }

    public typeFromPath(path: string) {
        if (path === this.store.getState().objectModel.current.model.directories.filaments.replace(/\/+$/, "")) {
            return UploadType.filaments;
        } else if (path === this.store.getState().objectModel.current.model.directories.firmware.replace(/\/+$/, "")) {
            return UploadType.firmware;
        } else if (path === this.store.getState().objectModel.current.model.directories.web.replace(/\/+$/, "")) {
            return UploadType.web;
        } else if (path === this.store.getState().objectModel.current.model.directories.gCodes.replace(/\/+$/, "")) {
            return UploadType.gcodes;
        } else if (path === this.store.getState().objectModel.current.model.directories.menu.replace(/\/+$/, "")) {
            return UploadType.menu;
        } else if (path === this.store.getState().objectModel.current.model.directories.macros.replace(/\/+$/, "")) {
            return UploadType.macros;
        } else if (path === this.store.getState().objectModel.current.model.directories.system.replace(/\/+$/, "")) {
            return UploadType.system;
        } else {
            return undefined as unknown as UploadType;
        }
    }

    public accept(target: UploadType): string | undefined {
        switch (target) {
            case UploadType.gcodes: return ".g,.gcode,.gc,.gco,.nc,.ngc,.tap";
            case UploadType.start: return ".g,.gcode,.gc,.gco,.nc,.ngc,.tap,.zip";
            case UploadType.macros: return "*";
            case UploadType.filaments: return ".zip";
            case UploadType.firmware: return ".zip,.bin,.uf2";
            case UploadType.menu: return "*";
            case UploadType.system: return ".zip,.bin,.uf2,.json,.g,.csv,.xml" + ((reduxStore.getState().objectModel.current.model.sbc !== null) ? ",.deb" : "");
            case UploadType.web: return ".zip,.csv,.json,.htm,.html,.ico,.xml,.css,.map,.js,.ttf,.eot,.svg,.woff,.woff2,.jpeg,.jpg,.png,.gz";
            case UploadType.plugin: return ".zip";
            case UploadType.update: return ".zip,.bin,.uf2";
            default:
                return undefined;
        }
    }

    private destinationDirectory(target: UploadType): string | undefined {
        switch (target) {
            case UploadType.gcodes: return reduxStore.getState().objectModel.current.model.directories.gCodes;
            case UploadType.start: return reduxStore.getState().objectModel.current.model.directories.gCodes;
            case UploadType.firmware: return reduxStore.getState().objectModel.current.model.directories.firmware;
            case UploadType.macros: return reduxStore.getState().objectModel.current.model.directories.macros;
            case UploadType.filaments: return reduxStore.getState().objectModel.current.model.directories.filaments;
            case UploadType.menu: return reduxStore.getState().objectModel.current.model.directories.menu;
            case UploadType.system: return reduxStore.getState().objectModel.current.model.directories.system;
            case UploadType.web: return reduxStore.getState().objectModel.current.model.directories.web;
            case UploadType.plugin: return undefined;   // not applicable
            case UploadType.update: return reduxStore.getState().objectModel.current.model.directories.firmware;
            default:
                return undefined;
        }
    }
}
