import {withTranslation} from "react-i18next";
import {LayoutBase} from "../../../Reducers/PanelContainer";
import {styled, Theme, useTheme} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../ReduxStore";
import React, {useContext, useEffect, useRef, useState} from "react";
import {FitAddon} from "@xterm/addon-fit";
import {SerializeAddon} from "@xterm/addon-serialize";
import {Terminal} from "@xterm/xterm";
import "@xterm/xterm/css/xterm.css";
import {ConnectionCtx, consoleMessages} from "../../MachineAccessWrapper";
import {BaseConnector} from "@duet3d/connectors";
import {Message, MessageType} from "@duet3d/objectmodel";
import getTheme from "../../../Themes/base";
import * as readline from "readline";
import i18n from "../../../i18n";
import PanelHeader from "../PanelComponents/PanelHeader";
import EventListener from "../../../EventSystem/EventListener";
import {saveTerminal, TerminalState} from "../../../Reducers/Terminal";
import {addHistory} from "../../../Reducers/History";

const rl = require("readline-browser");
const getStringWidth = require("string-width");
const c = require("ansi-colors");
c.enabled = true;

const getMappedKeyName = (rawName: string) => {
    if (rawName === "Backspace") {
        return "backspace";
    } else if (rawName === "ArrowLeft") {
        return "left";
    } else if (rawName === "ArrowRight") {
        return "right";
    } else if (rawName === "ArrowUp") {
        return "up";
    } else if (rawName === "ArrowDown") {
        return "down";
    } else if (rawName === "Delete") {
        return "delete";
    } else if (rawName === "Enter") {
        return "enter";
    } else if (rawName === "Tab") {
        return "tab";
    } else if (rawName === "Home") {
        return "home";
    } else if (rawName === "End") {
        return "end";
    } else if (rawName === "Return") {
        return "return";
    }
    return rawName;
};

interface TerminalData {
    xterm: Terminal;
    fitAddon: FitAddon;
    serializeAddon: SerializeAddon;
    intf: readline.Interface;
}

const ConsolePanel = (props: any) => {
    const uiState = useAppSelector((state) => state.uiState);
    const dispatch = useAppDispatch();
    const connection = useContext(ConnectionCtx);
    const connRef = useRef<BaseConnector>(undefined as unknown as BaseConnector);
    const theme = useTheme();
    const term = useRef(null);
    const [termData, setTerm] = useState<TerminalData>({} as TerminalData);
    const terminalState = useAppSelector(state => state.terminal[props.panelId]);
    const globalHistory = useAppSelector(state => state.history);
    const lastLineRef = useRef(-1);

    connRef.current = connection;

    const {t} = props;
    const writeMessage = (line: string, m: Message, xterm?: Terminal) => {
        if (xterm === undefined) {
            xterm = termData.xterm;
        }
        if (xterm !== undefined) {
            const dateFormatted = m.time.toLocaleString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour12: false,
                hour: "numeric",
                minute: "numeric",
            });
            if (m.content.startsWith("Error:")) m.type = MessageType.error;
            const outputLine =
                m.type === MessageType.error
                    ? c.red(dateFormatted + " " + line)
                    : m.type === MessageType.warning
                      ? c.yellow(dateFormatted) + " " + line
                      : c.green(dateFormatted) + " " + line;
            xterm.write(outputLine + "\n");
        }
    };

    useEffect(() => {
        if (!term.current) {
            return;
        }
        const input = {
            on(event: any, listener: any) {
                if (event === "data") {
                } else if (event === "keypress") {
                    xterm.onData((char) => {
                        if (getStringWidth(char) >= 2) {
                            listener(char, {
                                sequence: char,
                                name: undefined,
                                ctrl: false,
                                meta: false,
                                shift: false,
                            });
                        }
                    });

                    xterm.onKey(({key, domEvent}) => {
                        const name = getMappedKeyName(domEvent.key);
                        const ctrl = domEvent.ctrlKey;
                        const shift = domEvent.shiftKey;

                        listener(key, {
                            sequence: name,
                            name,
                            ctrl,
                            shift,
                        });
                    });
                }
            },
            resume() {
                console.log("resume");
            },
            pause() {
                console.log("pause");
            },
        };

        const output = {
            isTTY: true,
            get columns() {
                return xterm.cols;
            },
            on(event: any, listener: any) {
                if (event === "resize") {
                    xterm.onResize(listener);
                }
            },
            write(data: string) {
                xterm.write(data);
            },
        };

        const startRepl = async () => {
            while (true) {
                let cmd = await new Promise<string>((resolve) => intf.question("$ > ", resolve));

                try {
                    if (connRef.current !== undefined) {
                        const reply = await connRef.current.sendCode(cmd, false)
                        if (reply !== "") {
                            xterm.write(reply + "\n");
                        }
                    }
                }
                catch (e) {
                    // TODO: Handle this
                }
            }
        }

        const xterm = new Terminal({
            convertEol: true,
            theme: {
                background: theme.palette.terminal.background,
                foreground: theme.palette.terminal.foreground,
                selectionForeground: theme.palette.terminal.selectionForeground,
                selectionBackground: theme.palette.terminal.selectionBackground,
                selectionInactiveBackground: theme.palette.terminal.selectionInactive,
                red: theme.palette.terminal.red,
                yellow: theme.palette.terminal.yellow,
                green: theme.palette.terminal.green,
                cursor: theme.palette.terminal.foreground,
            },
        });

        const fitAddon = new FitAddon();
        const serializeAddon = new SerializeAddon();

        xterm.loadAddon(fitAddon);
        xterm.loadAddon(serializeAddon);

        if (terminalState && terminalState.scrollback) {
            xterm.write(terminalState.scrollback);
        }

        lastLineRef.current = terminalState?.lastMessage || -1;

        consoleMessages
            .filter((m) => m.index > (terminalState?.lastMessage || -1))
            .map((m) => {
                writeMessage(m.message.content, m.message, xterm);
                lastLineRef.current = m.index;
            })

        saveTerminal({...terminalState, lastMessage: lastLineRef.current});

        // @ts-ignore
        xterm.open(term.current);
        fitAddon.fit();

        const intf = rl.createInterface({
            input: input,
            output: output,
            history: [...globalHistory],
        });

        intf.on("history", (hist: any) => {
            dispatch(addHistory(hist[0]));
        });

        startRepl();

        setTerm({
            xterm: xterm,
            fitAddon: fitAddon,
            serializeAddon: serializeAddon,
            intf: intf,
        });

        return () => {
            dispatch(saveTerminal({
                scrollback: serializeAddon.serialize({scrollback: 1000}),
                id: props.panelId,
                lastMessage: lastLineRef.current,
            }))
            xterm.dispose();
            fitAddon.dispose();
            setTerm({} as TerminalData);
        };
    }, [props.panelId, theme.palette.mode]);

    useEffect(() => {
        termData.fitAddon?.fit();
        if (props.message !== undefined && props.message.length) {
            const newLines = props.message.length;
            if (termData.xterm !== undefined) {
                termData.xterm.write("\u001b[0G");
                termData.xterm.write("\u001b[J");
                consoleMessages.slice(consoleMessages.length - newLines, consoleMessages.length + 1).map((m) => {
                    lastLineRef.current = m.index;
                    const lines = m.message.content.split("\n");
                    lines.map((l) => {
                        writeMessage(l, m.message);
                        return undefined;
                    });
                    return undefined;
                });
                termData.xterm.write("$ > " + termData.intf.line);
                termData.xterm.write(`\u001b[${termData.intf.getCursorPos()["cols"] + 1}G`);
            }
        }
    }, [termData, props]);

    return (
        <div className={`${props.className} panel-inner`}>
            <PanelHeader captionId={"panel.console.caption"} panelName={"console"} />
            <div style={{paddingBottom: "20px", overflow: "visible"}} className={"panel-content"}>
                <div id="terminal" ref={term} className={"terminal"}></div>
            </div>
        </div>
    );
};

const ConsolePanelWrapped = styled(withTranslation()((props: any) => {
    return (
        <EventListener topic={"console-message"} prop={"message"}>
            <ConsolePanel {...props}/>
        </EventListener>
    )
}))((props) => {
    return {
        containerType: "inline-size",
        containerName: "ConsolePanel",
        display: "flex",
        flexDirection: "column",
        ".panel-content": {
            flexGrow: "100",
            position: "relative",
            top: "0",
        },
        ".terminal": {
            position: "absolute",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
        },
    } as React.CSSProperties as unknown as any;
});

ConsolePanelWrapped.defaultProps = {
    minW: 4,
    minH: 8,
    meta: {
        friendlyName: i18n.t('panel.console.friendlyName'),
        description: i18n.t('panel.console.panelDescription'),
    }
} as LayoutBase;

export default ConsolePanelWrapped;
