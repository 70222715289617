import {Button, ButtonProps, styled} from "@mui/material";
import {Axis} from "@duet3d/objectmodel";
import {settings} from "../../../Machine/settings";
import CodeButton from "../CodeButton";
import {useAppSelector} from "../../../ReduxStore";
import {canMove} from "../../../Machine/Helpers";

interface JogButtonBarProps {
    className?: string;
    offsets: number[];
    reverse?: boolean;
    editMode?: boolean;
    axis: Axis;
}

const JogButtonBar = (props: JogButtonBarProps) => {
    const objectModel = useAppSelector((state) => state.objectModel);
    const uiState = useAppSelector((state) => state.uiState);

    let {offsets} = props;
    if (props.reverse) {
        offsets = offsets.slice().reverse();
    }

    const getMoveCode = (axis: Axis, index: number, decrementing: boolean) => {
        try {
            // @ts-ignore
            return `M120\nG91\nG1 ${/[a-z]/.test(axis.letter) ? "'" : ""}${axis.letter.toUpperCase()}${decrementing ? "-" : ""}${settings.moveSteps[axis.letter][index]} F${settings.moveFeedrate}\nM121`;
        } catch(e) {
            return `M120\nG91\nG1 ${/[a-z]/.test(axis.letter) ? "'" : ""}${axis.letter.toUpperCase()}${decrementing ? "-" : ""}${settings.moveSteps.default[index]} F${settings.moveFeedrate}\nM121`;
        }
    };

    const buttonProps: ButtonProps = {
        variant: "outlined",
        disabled: !canMove(uiState, objectModel, props.axis),
    };

    return (
        <div className={props.className}>
            {offsets.reduce((acc, cur, idx) => {
                let extraProps: {[key: string]: string} = {};
                if (props.reverse) {
                    idx = 4 - idx;
                }
                if (idx === 0) {
                    extraProps["className"] = "drop1";
                }
                if (idx === 2) {
                    extraProps["className"] = "drop2";
                }
                acc = [
                    ...acc,
                    <CodeButton
                        key={getMoveCode(props.axis, idx, !props.reverse || false)}
                        code={getMoveCode(props.axis, idx, !props.reverse || false)}
                    >
                        <Button {...{...buttonProps, disabled: props.editMode}} {...extraProps}>
                            {!props.reverse && "< "}
                            {props.axis.letter}
                            {!props.reverse ? "-" : "+"}
                            {cur}
                            {props.reverse && " >"}
                        </Button>
                    </CodeButton>,
                ];
                if (!getMoveCode(props.axis, idx, !props.reverse || false))
                    console.log(`missing move code ${props.axis.letter}`);

                return acc;
            }, [] as JSX.Element[])}
        </div>
    );
};

export default styled(JogButtonBar)((props) => {
    return {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        gap: "0",
        containerType: "inline-size",
        containerName: "buttonbar",
        ">button": {
            flexGrow: "100",
            flexBasis: "0px",
            width: "0",
            borderRadius: "0px",
            paddingLeft: "2px",
            paddingRight: "2px",
        },
        "@container buttonbar (width < 330px)": {
            ">button.drop1": {
                display: "none",
            },
        },
        "@container buttonbar (width < 280px)": {
            ">button.drop2": {
                display: "none",
            },
        },
        ">button:not(:first-of-type)": {
            borderLeft: "none",
        },
        ">button:first-of-type": {
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
        },
        ">button:last-of-type": {
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
        },
    } as React.CSSProperties as unknown as any;
});
