import {red} from "@mui/material/colors";
import {createTheme} from "@mui/material";
import {getCurrentLocale, themeCommon} from "./common";
import i18n from "../i18n";

// Dark theme
const theme = createTheme(
    {
        ...themeCommon,
        ...{
            palette: {
                mode: "dark",
                primary: {
                    main: "#c0c0c0",
                    light: "rgb(81, 91, 95)",
                    dark: "rgb(26, 35, 39)",
                    contrastText: "#000000",
                },
                secondary: {
                    main: "#6d6c6c",
                    contrastText: "rgba(0, 0, 0, 0.87)",
                },
                error: {
                    main: "#990000",
                },
                background: {
                    default: "#303030",
                    paper: "#404040",
                },
                text: {
                    primary: "#b0b0b0",
                    secondary: "#909090",
                },
                success: {
                    main: "#1f7a1f",
                },
                info: {
                    main: "#00a3cc",
                },
                warning: {
                    main: "#cc6600",
                },
                panels: {
                    movement: {
                        titleBar: {
                            foreground: "white",
                            background: "blue",
                        },
                    },
                    extrusion: {
                        titleBar: {
                            foreground: "white",
                            background: "red",
                        },
                    },
                    console: {
                        titleBar: {
                            foreground: "white",
                            background: "green",
                        },
                    },
                    status: {
                        titleBar: {
                            foreground: "black",
                            background: "yellow",
                        },
                        subheader: {
                            foreground: "#000000",
                            background: "#f4f4f4",
                        }
                    },
                    macro: {
                        titleBar: {
                            foreground: "white",
                            background: "purple",
                        },
                    },
                    temperature: {
                        titleBar: {
                            foreground: "white",
                            background: "orange",
                        },
                    },
                    tools: {
                        titleBar: {
                            foreground: "black",
                            background: "#66ff00",
                        },
                    },
                },
                buttons: {
                    notHomed: "rgb(251, 140, 0)",
                },
                terminal: {
                    background: "#000000",
                    foreground: "#ffffff",
                    red: "#ff5555",
                    yellow: "#ffff55",
                    green: "#55ff55",
                    selectionBackground: "#303030",
                    selectionForeground: "#808080",
                    selectionInactive: "#202020",
                },
                status: {
                    disconnected: {foreground: "#ffffff", background: "#E53935"},
                    starting: {foreground: "#FF8A80", background: "#03a9f4"},
                    updating: {foreground: "#000000", background: "#90CAF9"},
                    off: {foreground: "#ffffff", background: "#E53935"},
                    halted: {foreground: "#ffffff", background: "#F44336"},
                    pausing: {foreground: "#000000", background: "#ffab40"},
                    paused: {foreground: "#000000", background: "#ffee58"},
                    resuming: {foreground: "#000000", background: "#ffab40"},
                    processing: {foreground: "#ffffff", background: "#4CAF50"},
                    simulating: {foreground: "#000000", background: "#80d8ff"},
                    busy: {foreground: "#ffffff", background: "#ffc107"},
                    changingTool: {foreground: "#000000", background: "#E3F2FD"},
                    idle: {foreground: "#000000", background: "#dcedc8"},
                },
            },
        },
    },
    getCurrentLocale(),
);

export default theme;
