import {Button, styled, Typography} from "@mui/material";
import CodeButton from "../CodeButton";
import {useContext} from "react";
import {useAppSelector} from "../../../ReduxStore";
import {WithTranslation, withTranslation} from "react-i18next";
import AxisJogControl from "../AxisJogControl";
import {settings} from "../../../Machine/settings";

interface JogControlProps extends WithTranslation {
    className?: string;
    editMode?: boolean;
}

const JogControl = (props: JogControlProps) => {
    const objectModel = useAppSelector((state) => state.objectModel);
    const {t} = props;

    return (
        <div className={props.className}>
            <Button className={"dummy-button"} sx={{paddingTop: "0px", paddingBottom: "0px"}}>
                &nbsp;
            </Button>
            <div className={"break"}></div>
            {objectModel.current.model.move.axes.map((a) => {
                if (a.visible) {
                    return <AxisJogControl key={a.letter} className={"axes"} axis={a} editMode={props.editMode}/>;
                }
            })}
        </div>
    );
};

export default styled(withTranslation()(JogControl))((props) => {
    return {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        "@container motionpanel (width < 480px)": {
            ".axes": {
                flexDirection: "column",
                border: "2px solid black",
                marginBottom: "10px",
            },
            ".dummy-button": {
                display: "none",
            },
            ".break": {
                display: "none",
            },
            alignItems: "stretch",
        },
        "@container motionpanel (width < 640px)": {
            ".dummy-button": {
                display: "none",
            },
        },
        "@container motionpanel (width >= 480px)": {
            ".break": {
                flexBasis: "100%",
                height: "0",
            },
            ".dummy-button": {
                visibility: "hidden",
            },
            flexGrow: "1",
        },
    } as React.CSSProperties as unknown as any; // Makes intellisense work
});
