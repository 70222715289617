import {WithTranslation, withTranslation} from "react-i18next";
import {
    Button,
    DialogContent,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    styled,
    TextField,
    useMediaQuery, useTheme
} from "@mui/material";
import {ReactComponent as NozzleIcon} from "../../../Images/printer-3d-nozzle.svg"
import {ReactComponent as NozzleIconAlert} from "../../../Images/printer-3d-nozzle-alert.svg"
import {ReactComponent as MillIcon} from "../../../Images/saw-blade.svg"
import {ReactComponent as LaserIcon} from "../../../Images/star-four-points-circle-outline.svg"
import {Heater, HeaterState, ObjectModel, SpindleState, Tool} from "@duet3d/objectmodel";
import {displaySensorValue} from "../../../Utils/display";
import {useAppSelector} from "../../../ReduxStore";
import {useContext, useState} from "react";
import {ConnectionCtx} from "../../MachineAccessWrapper";
import {plotColors} from "../../../Helpers/PlotColors";
import Icon from '@mdi/react';
import {Icon as MuiIcon} from "@mui/material";
import {mdiPowerStandby, mdiPowerOff, mdiPowerOn, mdiAlertCircle, mdiTuneVertical} from '@mdi/js';
import MenuButton from "../MenuButton";
import {NumberValidator} from "../../../Machine/Helpers";
import {settings} from "../../../Machine/settings";
import HeaterSet from "../HeaterSet";

interface ToolControlProps extends WithTranslation {
    className?: string;
    model: ObjectModel;
    tool: Tool[];
    index: number;
    toolClick: (toolNumber: number) => void;
    currentTool: Tool | null;
}

const ToolControl = (props: ToolControlProps) => {
    const model: ObjectModel =  props.model;
    const uiState = useAppSelector(state => state.uiState);
    const theme = useTheme();
    const {t} = props;
    const connection = useContext(ConnectionCtx);
    const [heaterActiveTemps, setHeaterActiveTemps] = useState<{[key: number]: string}>({});
    const [heaterStandbyTemps, setHeaterStandbyTemps] = useState<{[key: number]: string}>({});
    const currentTool= props.tool.findIndex((t) => t.number === props.currentTool?.number)
    const toolToDisplay = currentTool !== -1 ? currentTool : 0;

    const getToolHeaters = (tool: Tool) => {
        const heaters = model.heat.heaters;
        const toolHeaters = tool.heaters
            .filter(heaterIndex => (heaterIndex >= 0) && (heaterIndex < heaters.length) && (heaters[heaterIndex] !== null))
            .map(heaterIndex => heaters[heaterIndex]);
        return (toolHeaters.length > 0) ? toolHeaters : [null];
    }

    const getHeaterName = (heater: Heater | null, heaterIndex: number) => {
        if ((heater !== null) && (heater.sensor >= 0) && (heater.sensor < model.sensors.analog.length)) {
            const sensor = model.sensors.analog[heater.sensor];
            if ((sensor !== null) && sensor.name) {
                const matches = /(.*)\[(.*)\]$/.exec(sensor.name);
                if (matches) {
                    return matches[1];
                }
                return sensor.name;
            }
        }
        return t("panel.tools.heater", {0: heaterIndex});
    }

    const getHeaterValue = (heater: Heater | null) => {
        if ((heater !== null) && (heater.sensor >= 0) && (heater.sensor < model.sensors.analog.length)) {
            const sensor = model.sensors.analog[heater.sensor];
            if (sensor !== null) {
                return displaySensorValue(sensor);
            }
        }
        return t("generic.noValue");
    }

    const getHeaterStateIcon = (heater: Heater | null) => {
        if (heater === null) {
            return <></>
        }

        if (heater.state === HeaterState.active) {
            return <Icon style={{color: theme.palette.success.main}} size={"20px"} path={mdiPowerOn} />
        }
        else if (heater.state === HeaterState.off) {
            return <Icon style={{color: theme.palette.info.main}} size={"20px"} path={mdiPowerOff} />
        }
        else if (heater.state === HeaterState.standby) {
            return <Icon style={{color: theme.palette.warning.main}} size={"20px"} path={mdiPowerStandby} />
        }
        else if (heater.state === HeaterState.fault) {
            return <Icon size={"20px"} color={theme.palette.error.main} path={mdiAlertCircle} />
        }
        else if (heater.state === HeaterState.tuning) {
            return <Icon size={"20px"} path={mdiTuneVertical} />
        }
    }

    const toolHeaterClick = async (tool: Tool, heater: Heater) => {
        if (uiState.frozen) {
            return;
        }

        switch (heater.state) {
            case HeaterState.off:       // Off -> Active
                await connection.sendCode(`M568 P${tool.number} A2`, true);
                break;

            case HeaterState.standby:   // Standby -> Off
                await connection.sendCode(`M568 P${tool.number} A0`, true);
                break;

            case HeaterState.active:    // Active -> Standby
                await connection.sendCode(`M568 P${tool.number} A1`, true);
                break;

            case HeaterState.fault:     // Fault -> Ask for reset
                // TODO: Use the event system for this
                // emit("resetHeaterFault", store.state.machine.model.heat.heaters.indexOf(heater));
                break;
        }
    }

    // Spindles
    const getSpindle = (tool: Tool) => {
        return (tool.spindle >= 0) && (tool.spindle < model.spindles.length) ? model.spindles[tool.spindle] : null;
    }

    const getSpindleSpeed = (tool: Tool) => {
        const spindle = getSpindle(tool);
        return spindle ? ((spindle.state === SpindleState.reverse) ? -spindle.current : spindle.current) : 0;
    }

    const getToolIcon = (tool: Tool): any => {

        if (tool !== null) {
            if (tool.extruders.length > 0) {
                if (model.heat.heaters.some((heater, heaterIndex) => (heater !== null) && (heater.state === HeaterState.fault) && tool.heaters.includes(heaterIndex))) {
                    return <NozzleIconAlert style={{pointerEvents: "none"}}/>;
                }
                return <NozzleIcon style={{pointerEvents: "none"}}/>;
            }
            if (tool.spindle >= 0) {
                return <MillIcon style={{pointerEvents: "none"}}/>;
            }
            if (tool.name.toLowerCase().includes("laser")) {
                // TODO the object model does not report if a laser is mapped to a tool
                return <LaserIcon style={{pointerEvents: "none"}}/>;
            }
        }
        return <></>;
    }

    const heaters = getToolHeaters(props.tool[0]).filter(h => h !== null && h.sensor !== -1) as Heater[];
    // TODO: Support machining and lasers
    if (heaters.length < 1) { // Tools without heaters are not listed here (why?)
        return <></>
    }

    const menuButtons = props.tool.map((tool: Tool, idx) => {
        return (
            <MenuItem key={tool.number}>{getToolIcon(tool)} Tool {tool.number}</MenuItem>
        )
    })

    const getToolActiveTemps = () => {
        return heaters.map((h) => {
            return h.active;
        })
    }

    const getToolStandbyTemps = () => {
        return heaters.map((h) => {
            return h.standby;
        })
    }

    const setHeaterActiveTemp = (h: Heater, idx: number, value: string) => {
        if (value === "") {
            value = "0";
        }
        const temps = {...getToolActiveTemps(), [idx]: Number(value)};
        setHeaterActiveTemps({...heaterActiveTemps, [h?.sensor || 0]: value});
        connection.sendCode(`M568 P${props.tool[0].number} S${Object.values(temps).join(":")}`, true);
    }

    const setHeaterStandbyTemp = (h: Heater, idx: number, value: string) => {
        if (value === "") {
            value = "0";
        }
        const temps = {...getToolStandbyTemps(), [idx]: Number(value)};
        setHeaterStandbyTemps({...heaterStandbyTemps, [h?.sensor || 0]: value});
        connection.sendCode(`M568 P${props.tool[0].number} R${Object.values(temps).join(":")}`, true);
    }

    function getFilament(tool: Tool) {
        if ((tool.filamentExtruder >= 0) && (tool.filamentExtruder < model.move.extruders.length)) {
            return model.move.extruders[tool.filamentExtruder].filament;
        }
        return null;
    }

    function canLoadFilament(tool: Tool) {
        return !uiState.frozen && (tool.filamentExtruder >= 0) && (tool.filamentExtruder < model.move.extruders.length);
    }

    async function changeFilament(tool: Tool) {
        await unloadFilament(tool);
        // TODO: Implement this the react way
        // await showFilamentDialog(tool);
    }

    async function unloadFilament(tool: Tool) {
        try {
            let code = "";
            if (model.state.currentTool !== tool.number) {
                code = `T${tool.number}\n`;
            }
            code += "M702";
            await connection.sendCode(code, false);
        } finally {
            // busyTool.value = null;
        }
    }

    return (
        <div className={props.className}>
            {heaters.map((h, idx) => {
                return (
                    <div className={"heaterBox"} key={idx}>
                        <div className={"heaterName"}
                             style={{fontWeight: "bold", color: plotColors[h.sensor], cursor: "pointer"}}
                             onClick={() => {
                                 toolHeaterClick(props.tool[toolToDisplay], h);
                             }}
                        >
                            {getHeaterName(h, idx)}
                        </div>
                        <div className={"heaterIcon"}>{getHeaterStateIcon(h)}</div>
                        <div className={"heaterTemp"}>{getHeaterValue(h)}</div>
                        <HeaterSet
                            label={"Active"}
                            onTempChange={(v) => setHeaterActiveTemp(h, idx, v)}
                            temp={(h?.active.toString() || undefined) || "0"}
                            temps={settings.temperatures.tool.active}
                        />
                        <HeaterSet
                            label={"Standby"}
                            onTempChange={(v) => setHeaterStandbyTemp(h, idx, v)}
                            temp={(h?.standby.toString() || undefined) || "0"}
                            temps={settings.temperatures.tool.standby}
                        />
                    </div>
                )
                    ;
            })}
            <div className={"toolName"}>
                {props.tool.length === 1
                    ? <div
                        className={"toolHeading"}
                        onClick={() => {
                            props.toolClick(props.tool[0].number);
                        }}
                    >
                        {getToolIcon(props.tool[0])} Tool {props.tool[0].number}
                    </div>
                    : <MenuButton
                        sx={{display: "inherit", height: ""}}
                        menu={
                            <Menu open={false}>
                                {menuButtons}
                            </Menu>
                        }
                        onSelection={(item) => {
                            const pieces = item.split(" ");
                            props.toolClick(Number(pieces[1]));
                        }}
                    >
                        <Button
                            sx={{
                                verticalAlign: "unset",
                                padding: "0",
                                margin: "0",
                                fontSize: "inherit",
                                height: "0.75rem",
                                lineHeight: "0.75rem"
                            }}
                            variant={"text"}
                        >
                            {getToolIcon(props.tool[toolToDisplay])} Tool {props.tool[toolToDisplay].number}<MuiIcon
                            sx={{transform: "scale(0.5)"}}>keyboard_arrow_down</MuiIcon>
                        </Button>
                    </MenuButton>
                }
            </div>
        </div>
    )
}

export default withTranslation()(styled(ToolControl)((props) => {
    return {
        position: "relative",
        borderTop: `1px solid ${props.theme.palette.primary.light}`,
        containerType: "inline-size",
        containerName: "toolContainer",
        marginTop: "0.37rem",
        paddingTop: "1rem",
        paddingBottom: "20px",
        gap: "7px",
        ".heaterBox": {
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            flexDirection: "row",
            gap: "10px",
            marginRight: "10px",
        },
        ".toolName": {
            position: "absolute",
            top: "-0.5rem",
            left: "7px",
            fontSize: "0.75rem",
            height: "1rem",
            backgroundColor: props.theme.palette.background.paper,
            paddingLeft: "7px",
            paddingRight: "7px",
        },
        ".toolHeading": {
            color: props.theme.palette.primary.main,
            cursor: "pointer",
        },
        ".heaterBox>div.heaterName": {
            flexBasis: "0",
            flexGrow: "25",
            paddingLeft: "20px",
        },
        ".heaterBox>div.heaterIcon": {
            flexBasis: "0",
            flexGrow: "0",
            paddingTop: "0.45rem",
        },
        ".heaterBox>div.heaterTemp": {
            flexBasis: "0",
            flexGrow: "20",
            minWidth: "4rem",
            textAlign: "right",
            whiteSpace: "nowrap",
        },
        // "@container toolContainer (width < 330px)": {
        //     "&>*": {
        //         backgroundColor: "red",
        //     },
        // },
        ".MuiInputAdornment-root": {
            margin: "0 !important",
        },
    };
}))

// 364
