import {createTheme} from "@mui/material";
import {getCurrentLocale, themeCommon} from "./common";

const theme = createTheme(
    {
        ...themeCommon,
        ...{
            palette: {
                mode: "light",
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: "#0072ce",
                    light: "#67a9f9",
                    dark: "#004282",
                    // dark: will be calculated from palette.primary.main,
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                },
                secondary: {
                    main: "#d3deea",
                    light: "#e3eaf1",
                    dark: "#93afca",
                },
                info: {
                    main: "#0072ce",
                    light: "#67a9f9",
                    dark: "#4373a3",
                    contrastText: "#ffffff",
                },
                success: {
                    main: "#00b100",
                    contrastText: "#ffffff",
                },
                warning: {
                    main: "#ffb520",
                    contrastText: "#ffffff",
                },
                error: {
                    main: "#a01320",
                    contrastText: "#ffffff",
                },
                background: {
                    default: "#ffffff",
                    paper: "#fdfdfd",
                },
                text: {
                    primary: "#000000",
                    secondary: "#808080",
                },
                action: {
                    disabledBackground: "#d0d0d0",
                    disabled: "grey",
                },
                panels: {
                    movement: {
                        titleBar: {
                            foreground: "white",
                            background: "blue",
                        },
                    },
                    extrusion: {
                        titleBar: {
                            foreground: "white",
                            background: "red",
                        },
                    },
                    console: {
                        titleBar: {
                            foreground: "white",
                            background: "green",
                        },
                    },
                    status: {
                        titleBar: {
                            foreground: "black",
                            background: "yellow",
                        },
                        subheader: {
                            foreground: "#000000",
                            background: "#f4f4f4",
                        }
                    },
                    macro: {
                        titleBar: {
                            foreground: "white",
                            background: "purple",
                        },
                    },
                    temperature: {
                        titleBar: {
                            foreground: "white",
                            background: "orange",
                        },
                    },
                    tools: {
                        titleBar: {
                            foreground: "black",
                            background: "#66ff00",
                        },
                    },
                },
                buttons: {
                    notHomed: "rgb(251, 140, 0)",
                },
                terminal: {
                    foreground: "#000000",
                    background: "#ffffff",
                    red: "#AA0000",
                    yellow: "#AAAA00",
                    green: "#00AA00",
                    selectionBackground: "#d0d0ff",
                    selectionForeground: "#000000",
                    selectionInactive: "#f0f0f0",
                },
                status: {
                    disconnected: {foreground: "#ffffff", background: "#E53935"},
                    starting: {foreground: "#FF8A80", background: "#03a9f4"},
                    updating: {foreground: "#000000", background: "#90CAF9"},
                    off: {foreground: "#ffffff", background: "#E53935"},
                    halted: {foreground: "#ffffff", background: "#F44336"},
                    pausing: {foreground: "#000000", background: "#ffab40"},
                    paused: {foreground: "#000000", background: "#ffee58"},
                    resuming: {foreground: "#000000", background: "#ffab40"},
                    processing: {foreground: "#ffffff", background: "#4CAF50"},
                    simulating: {foreground: "#000000", background: "#80d8ff"},
                    busy: {foreground: "#ffffff", background: "#ffc107"},
                    changingTool: {foreground: "#000000", background: "#E3F2FD"},
                    idle: {foreground: "#000000", background: "#dcedc8"},
                },
            },
        },
    },
    getCurrentLocale(),
);

export default theme;
