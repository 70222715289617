import {WithTranslation, withTranslation} from "react-i18next";
import {DataGrid, GridColDef, GridPaginationModel, GridRowSelectionModel, useGridApiRef} from "@mui/x-data-grid";
import {useContext, useEffect, useState} from "react";
import loadDirectory, {DirectoryList, ListDirectoryItem, ListFileItem} from "../../Utils/loadDirectory";
import {
    Breadcrumbs, Button,
    ClickAwayListener,
    Fade,
    Icon, Menu,
    menuClasses,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    styled, Theme, Tooltip, useMediaQuery,
} from "@mui/material";
import {ConnectionCtx, FileTransferCtx, UploadControllerCtx} from "../MachineAccessWrapper";
import prettyBytes from "pretty-bytes";
import Path from "../../Utils/path";
import MenuButton from "../Inputs/MenuButton";
import {reduxStore, useAppDispatch, useAppSelector} from "../../ReduxStore";
import {setUiState} from "../../Reducers/UiState";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import {progressSnackbar, snackbarError, snackbarSuccess} from "../../Helpers/SnackbarMode";
import {enqueueSnackbar} from "notistack";
import ValueEntryDialog from "../Dialogs/ValueEntryDialog";
import MonacoEditor from "../Dialogs/MonacoEditor";
import {atom, useSetAtom} from "jotai";
import {ProgressSnackbarData} from "../../Helpers/ProgressSnackbar";
import {FileTransfer} from "../../Utils/FileTransfer";
import JSZip, {file, JSZipObject} from "jszip";
import FileUpload from "../FileUpload";
import {UploadController, UploadFileData, UploadType} from "../../Utils/UploadController";
import CodeButton from "../Inputs/CodeButton";

interface FileListProps extends WithTranslation{
    className?: string;
}

interface ContextMenuData {
    mouseX: number;
    mouseY: number;
    context: ListDirectoryItem | ListFileItem;
}

const FileList = (props: FileListProps) => {
    const uiState = useAppSelector(state => state.uiState);
    const dispatch = useAppDispatch();
    const {t} = props;
    const connection = useContext(ConnectionCtx);
    const [path, setPath] = useState("/");
    const [directoryList, setDirectoryList] = useState({} as DirectoryList);
    // const [currentFilesystemPath, setCurrentFilesystemPath] = useState("")
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({pageSize:100, page: 0})
    const [contextMenu, setContextMenu] = useState<ContextMenuData | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | any>(null);
    const id = contextMenu ? "faked-reference-popper" : undefined;
    const store = reduxStore.getState().objectModel.current.model;
    const filesystemPaths = Object.keys(store.directories)
        .map(d => store.directories[d as keyof typeof store.directories] as string)
        .map((s) => {if (s.endsWith("/")) s = s.substring(0, s.length - 1); return s.trim();})
        .filter((s) => !s.includes("menu") && !s.includes("www"));
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [valueEntryOpen, setValueEntryOpen] = useState(false);
    const [onConfirm, setOnConfirm] = useState<any>(() => {});
    const [forceReload, setForceReload] = useState(0);
    const [originalName, setOriginalName] = useState("")
    const [editorOpen, setEditorOpen] = useState(false);
    const [editorBuffer, setEditorBuffer] = useState("");
    const [editorFile, setEditorFile] = useState<ListFileItem>({} as ListFileItem);
    const [dirnameEntryOpen, setDirnameEntryOpen] = useState(false);
    const [newFileEntryOpen, setNewFileEntryOpen] = useState(false);
    const gridApi = useGridApiRef();
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>({} as GridRowSelectionModel);
    const fileTransfer = useContext((FileTransferCtx));
    const [confirmMultiOpen, setConfirmMultiOpen] = useState(false);
    const [confirmRunOpen, setConfirmRunOpen] = useState(false);
    const [confirmStartOpen, setConfirmStartOpen] = useState(false);
    const [newFilamentEntryOpen, setNewFilamentEntryOpen] = useState(false);
    const uploadController = useContext(UploadControllerCtx);
    const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    // const [progressAtom, setProgressAtom] = useState(atom(0));
    // const setProgress = useSetAtom(progressAtom);

    useEffect(() => {
        if (uiState.selectedFilePath !== "") {
            loadDirectory(connection, uiState.selectedFilePath, path)
                .then((dir) => {
                    setPath(path);
                    setDirectoryList(dir);
                })
                .catch((e) => {
                    if (e.message.includes("not found")) {
                        // The directory was not found, try to create it
                        connection.makeDirectory(uiState.selectedFilePath);
                    }
                    else {
                        // console.log(e);
                    }
                })
        }
    }, [path, connection, uiState.selectedFilePath, forceReload]);

    if (uiState.selectedFilePath === undefined || !uiState.selectedFilePath || uiState.selectedFilePath === "") {
        dispatch(setUiState({...uiState, selectedFilePath: filesystemPaths[0]}));
        return <></>
    }

    if (directoryList === undefined || directoryList.files === undefined || directoryList.dirs === undefined) {
        return <></>
    }
    const columnDefs: GridColDef[] = [
        {
            field: "name",
            headerName: t("list.baseFileList.fileName"),
            renderHeader: (params) => <b>{params.colDef.headerName}</b>,
            renderCell: (params) => {
                return <>
                    {params.row.fullName !== undefined
                        ? <>
                            <Icon sx={{pointerEvents: "none", position: "relative", top: "6px"}}>insert_drive_file</Icon>
                            &nbsp;{params.row.fullName}
                        </>
                        : <>
                            {uiState.selectedFilePath.includes("filaments")
                                ? <Icon sx={{pointerEvents: "none", position: "relative", top: "6px"}}>mode_standby</Icon>
                                : <Icon sx={{pointerEvents: "none", position: "relative", top: "6px"}}>folder</Icon>
                            }
                            &nbsp;{params.row.name}
                        </>
                    }
                </>;
            },
            type: "string",
            width: 300,
            editable: false,
            sortable: false
        },
        {
            field: "size",
            headerName: t("list.baseFileList.size"),
            renderHeader: (params) => <b>{params.colDef.headerName}</b>,
            renderCell: (p) => isFinite(Number(p.value)) && prettyBytes(Number(p.value)),
            type: "string",
            width: 200,
            editable: false,
            sortable: false,
        },
        {
            field: "lastModified",
            headerName: t("list.baseFileList.lastModified"),
            renderHeader: (params) => <b>{params.colDef.headerName}</b>,
            type: "string",
            width: 300,
            editable: false,
            sortable: false
        },
    ];

    const handleClose = () => {
        setContextMenu(null);
    }

    const pieces = path.split("/");
    pieces.splice(0, 1);

    const StyledSpan = styled("span")``;

    const download = (url: string, filename: string) => {
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.target = "_blank";
        a.click();
    }

    const handleDelete = (e: any, c: ContextMenuData) => {
        setContextMenu(null);
        setOnConfirm(() => {
            return () => {
                setConfirmDeleteOpen(false);
                connection.delete(c.context.fullPath, uiState.selectedFilePath.includes("filaments"))
                .then(() => {
                    setForceReload(forceReload + 1);
                })
                .catch((e) => {
                })
            }
        })
        setConfirmDeleteOpen(true);
    }

    const handleEdit = (e: any, c: ContextMenuData) => {
        setContextMenu(null);

        fileTransfer.download(c.context.fullPath, "text")
            .then((contents) => {
                setEditorFile(c.context as ListFileItem);
                setEditorBuffer(contents);
                setEditorOpen(true);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const handleDownload = (e: any, c: ContextMenuData) => {
        setContextMenu(null);

        fileTransfer.download(c.context.fullPath, "blob", undefined, (loaded, total, retry) => {
            })
            .then((contents) => {
                const url = URL.createObjectURL(contents);
                // @ts-ignore
                download(url, c.context.fullName);

                URL.revokeObjectURL(url);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const handleRename = (e: any, c: ContextMenuData) => {
        setContextMenu(null);

        // @ts-ignore
        if (c.context.fullName !== undefined) {
            // @ts-ignore
            setOriginalName(c.context.fullName);
        }
        else {
            setOriginalName(c.context.name);
        }

        setOnConfirm((value: string) => {
            return (v: string) => {
                setValueEntryOpen(false);
                connection.move(c.context.fullPath, Path.combine(uiState.selectedFilePath, path.substring(1), v).replace("//", "/"))
                    .then(() => {
                        setForceReload(forceReload + 1);
                    })
                    .catch((e) => {
                    })
            }
        })

        setValueEntryOpen(true);
    }

    const handleSimulateJob = (e: any, c: ContextMenuData) => {
        setContextMenu(null);

    }

    const handleRunMacro = (e: any, c: ContextMenuData) => {
        setContextMenu(null);

        connection.sendCode(`M98 P"${c.context.fullPath}"`, false)
    }

    const handleStartJob = (e: any, c: ContextMenuData) => {
        setContextMenu(null);

        connection.sendCode(`M32 "${c.context.fullPath}"`, false)
    }

    const directoryBaseType = uiState.selectedFilePath.replace("/sys", "/system").replace("/www", "/web").substring(uiState.selectedFilePath.indexOf(":/") + 2);

    const handleMakeDirectory = () => {
        setDirnameEntryOpen(true);
    }

    const onCreateDir = (name: string) => {
        setDirnameEntryOpen(false);
        // connection.makeDirectory()
        const newDir = Path.combine(uiState.selectedFilePath, path.substring(1),name).replace("//", "/");
        connection.makeDirectory(newDir)
            .then(() => {
                setForceReload(forceReload + 1);
            })
    }

    const handleNewFile = () => {
        if (uiState.selectedFilePath.includes("filaments") && path === "/") {
            setNewFilamentEntryOpen(true);
            return;
        }
        setNewFileEntryOpen(true);
    }

    const onCreateFile = (name: string) => {
        setNewFileEntryOpen(false);

        const newFileName = Path.combine(uiState.selectedFilePath, path.substring(1),name).replace("//", "/");
        const item: ListFileItem = {
            name: name,
            fullName: name,
            fullPath: newFileName,
            size: 0,
            lastModified: null,
        }
        setEditorFile(item);
        setEditorBuffer("");
        setEditorOpen(true);
    }

    const onCreateFilament = (name: string) => {
        setNewFilamentEntryOpen(false);

        const newFilament = Path.combine(reduxStore.getState().objectModel.current.model.directories.filaments, name);

        connection.makeDirectory(newFilament)
            .then(() => {
                const files = ["/config.g", "/load.g", "/unload.g"];

                files.reduce((p, x) =>
                    p.then(() => connection.upload(newFilament + x, ""))
                , Promise.resolve())

                setForceReload(forceReload + 1);
            })
            .catch((reason) => {
                console.log(reason)
            })
    }

    const handleFileClick = (row: ListFileItem) => {
        if (uiState.selectedFilePath.includes("macros")) {
            setOriginalName(row.fullName);
            setConfirmRunOpen(true);
            setOnConfirm(() => {
                return () => {
                    setConfirmRunOpen(false);
                    handleRunMacro(undefined, {context: row, mouseY: 0, mouseX: 0})
                }
            })
        }
        if (uiState.selectedFilePath.includes("gcodes")) {
            setOriginalName(row.fullName);
            setConfirmStartOpen(true);
            setOnConfirm(() => {
                return () => {
                    setConfirmStartOpen(false);
                    handleStartJob(undefined, {context: row, mouseY: 0, mouseX: 0})
                }
            })
        }
        if (uiState.selectedFilePath.includes("sys") || uiState.selectedFilePath.includes("filaments")) {
            handleEdit(undefined, {context: row, mouseY: 0, mouseX: 0});
        }
        if (uiState.selectedFilePath.includes("firmware")) {
            handleDownload(undefined, {context: row, mouseY: 0, mouseX: 0});
        }
    }

    const handleDeleteMulti = () => {
        setConfirmMultiOpen(true);
    }


    const onDeleteMulti = () => {
        setConfirmMultiOpen(false);

        (async () => {
            for (const f of rowSelectionModel) {
                try {
                    await connection.delete(f as string);
                }
                catch (e) {
                    console.log(e);
                    setForceReload(forceReload + 1);
                    return;
                }
            }
            enqueueSnackbar(t("notification.delete.successMultiple", {"0": rowSelectionModel.length}), snackbarSuccess());
            setForceReload(forceReload + 1);
        })();
    }

    const handleDownloadMulti = () => {
        setContextMenu(null);

        const promises = rowSelectionModel.map((r) => {
            return fileTransfer.download(r as string, "blob");
        })

        Promise.all(promises)
            .then((results) => {
                let zip = new JSZip();

                results.reduce((acc, cur, idx) => {
                    const row = directoryList.files.find((f) => f.fullPath === rowSelectionModel[idx])
                    zip.file(row?.fullName || "", cur)
                    return acc;
                }, {})

                zip.generateAsync({type: "blob"})
                    .then((data) => {
                        const url = URL.createObjectURL(data);
                        // @ts-ignore
                        download(url, "download.zip");

                        URL.revokeObjectURL(url);
                    })
            })
    }

    const handleDownloadFilament = (e: any, c: ContextMenuData) => {
        setContextMenu(null);

        connection.getFileList(c.context.fullPath)
            .then((files) => {
                let zip = new JSZip();

                const folder = zip.folder(c.context.name) as JSZip;

                 Promise.all(files.map((f) => {
                    return fileTransfer.download(c.context.fullPath + "/" + f.name, "blob");
                }))
                    .then((fileData) => {
                        files.reduce(async (acc, cur, idx) => {
                            folder.file(cur.name, fileData[idx]);

                            return acc;
                        }, {})

                        zip.generateAsync({type: "blob"})
                            .then((data) => {
                                const url = URL.createObjectURL(data);
                                // @ts-ignore
                                download(url, c.context.name + ".zip");

                                URL.revokeObjectURL(url);
                            })
                    })
            })
            .catch((reason) => {
                console.log(reason);
            })
    }

    const handleDuplicateFilament = (e: any, c: ContextMenuData) => {

    }

    const handleFiles = (files: File[]) => {
        uploadController.upload(
                files.map((f) => { return {filename: f.name, content: f} as UploadFileData;}),
                path,
                uploadController.typeFromPath(uiState.selectedFilePath))
            .then(() => {
                setForceReload(forceReload + 1);
            })
            .catch((reason) => {
                console.log(reason);
            });
    }

    const createPopupMenu = () => {
        if (rowSelectionModel.length > 1) {
            return (
                <Popper
                    id={id}
                    open={!!contextMenu}
                    anchorEl={anchorEl}
                    transition
                    placement="bottom-start"
                >
                    {({TransitionProps}) => (
                        <ClickAwayListener onClickAway={handleClose}>
                            <Fade {...TransitionProps}>
                                <Paper className={menuClasses.paper}>
                                    <MenuList sx={{"& li>span:first-of-type": {marginRight: "20px"}}}
                                              className={menuClasses.list} autoFocus>
                                        {(contextMenu && contextMenu.context) && (
                                            directoryBaseType !== "filaments"
                                                ? [
                                                    <MenuItem key={Math.random()}
                                                              onClick={(e) => handleDownloadMulti()}><Icon>download</Icon>{t("list.baseFileList.downloadZIP")}
                                                    </MenuItem>,
                                                    <MenuItem key={Math.random()}
                                                              onClick={(e) => handleDeleteMulti()}><Icon>delete</Icon>{t("list.baseFileList.delete")}
                                                    </MenuItem>,
                                                ]
                                                : path === "/"
                                                    ?
                                                        [
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleDelete(e, contextMenu)}><Icon>delete</Icon>{t("list.baseFileList.delete")}
                                                            </MenuItem>,
                                                        ]
                                                    :
                                                        [
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleDownloadMulti()}><Icon>download</Icon>{t("list.baseFileList.downloadZIP")}
                                                            </MenuItem>,
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleDeleteMulti()}><Icon>delete</Icon>{t("list.baseFileList.delete")}
                                                            </MenuItem>,
                                                        ]
                                        )}

                                        </MenuList>
                                </Paper>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
            );
        }
        else {
            return (
                <Popper
                    id={id}
                    open={!!contextMenu}
                    anchorEl={anchorEl}
                    transition
                    placement="bottom-start"
                >
                    {({TransitionProps}) => (
                        <ClickAwayListener onClickAway={handleClose}>
                            <Fade {...TransitionProps}>
                                <Paper className={menuClasses.paper}>
                                    <MenuList sx={{"& li>span:first-of-type": {marginRight: "20px"}}}
                                              className={menuClasses.list} autoFocus>
                                        {contextMenu && contextMenu.context &&
                                            ((contextMenu.context as any).fullName === undefined
                                                    ? uiState.selectedFilePath.includes("filaments")
                                                        ? [
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleDownloadFilament(e, contextMenu)}><Icon>download</Icon>{t("list.baseFileList.downloadZIP")}
                                                            </MenuItem>,
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleDuplicateFilament(e, contextMenu)}><Icon>content_copy</Icon>{t("list.baseFileList.duplicate")}
                                                            </MenuItem>,
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleRename(e, contextMenu)}><Icon>edit</Icon>{t("list.baseFileList.rename")}
                                                            </MenuItem>,
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleDelete(e, contextMenu)}><Icon>delete</Icon>{t("list.baseFileList.delete")}
                                                            </MenuItem>,
                                                        ]
                                                        : [
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleRename(e, contextMenu)}><Icon>edit</Icon>{t("list.baseFileList.rename")}
                                                            </MenuItem>,
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleDelete(e, contextMenu)}><Icon>delete</Icon>{t("list.baseFileList.delete")}
                                                            </MenuItem>,
                                                        ]
                                                    : (contextMenu.context as any).fullName &&
                                                    ((contextMenu.context as any).fullName.endsWith(".g") ||
                                                        (contextMenu.context as any).fullName.endsWith(".gc") ||
                                                        (contextMenu.context as any).fullName.endsWith(".gcode"))
                                                        ? uiState.selectedFilePath.includes("macros")
                                                            ? [
                                                                <MenuItem key={Math.random()}
                                                                          onClick={(e) => handleRunMacro(e, contextMenu)}><Icon>play_circle</Icon>{t("list.macro.run")}
                                                                </MenuItem>,
                                                                <MenuItem key={Math.random()}
                                                                          onClick={(e) => handleDownload(e, contextMenu)}><Icon>download</Icon>{t("list.baseFileList.download")}
                                                                </MenuItem>,
                                                                <MenuItem key={Math.random()}
                                                                          onClick={(e) => handleEdit(e, contextMenu)}><Icon>edit_note</Icon>{t("list.baseFileList.edit")}
                                                                </MenuItem>,
                                                                <MenuItem key={Math.random()}
                                                                          onClick={(e) => handleRename(e, contextMenu)}><Icon>edit</Icon>{t("list.baseFileList.rename")}
                                                                </MenuItem>,
                                                                <MenuItem key={Math.random()}
                                                                          onClick={(e) => handleDelete(e, contextMenu)}><Icon>delete</Icon>{t("list.baseFileList.delete")}
                                                                </MenuItem>,
                                                            ]
                                                            : uiState.selectedFilePath.includes("filaments")
                                                                ?
                                                                    [
                                                                        <MenuItem key={Math.random()}
                                                                                  onClick={(e) => handleDownload(e, contextMenu)}><Icon>download</Icon>{t("list.baseFileList.download")}
                                                                        </MenuItem>,
                                                                        <MenuItem key={Math.random()}
                                                                                  onClick={(e) => handleEdit(e, contextMenu)}><Icon>edit_note</Icon>{t("list.baseFileList.edit")}
                                                                        </MenuItem>,
                                                                        <MenuItem key={Math.random()}
                                                                                  onClick={(e) => handleRename(e, contextMenu)}><Icon>edit</Icon>{t("list.baseFileList.rename")}
                                                                        </MenuItem>,
                                                                        <MenuItem key={Math.random()}
                                                                                  onClick={(e) => handleDelete(e, contextMenu)}><Icon>delete</Icon>{t("list.baseFileList.delete")}
                                                                        </MenuItem>,
                                                                    ]
                                                                :
                                                                [
                                                                    <MenuItem key={Math.random()}
                                                                              onClick={(e) => handleStartJob(e, contextMenu)}><Icon>play_circle</Icon>{t("list.jobs.start")}
                                                                    </MenuItem>,
                                                                    <MenuItem key={Math.random()}
                                                                              onClick={(e) => handleSimulateJob(e, contextMenu)}><Icon>fast_forward</Icon>{t("list.jobs.simulate")}
                                                                    </MenuItem>,
                                                                    <MenuItem key={Math.random()}
                                                                              onClick={(e) => handleDownload(e, contextMenu)}><Icon>download</Icon>{t("list.baseFileList.download")}
                                                                    </MenuItem>,
                                                                    <MenuItem key={Math.random()}
                                                                              onClick={(e) => handleEdit(e, contextMenu)}><Icon>edit_note</Icon>{t("list.baseFileList.edit")}
                                                                    </MenuItem>,
                                                                    <MenuItem key={Math.random()}
                                                                              onClick={(e) => handleRename(e, contextMenu)}><Icon>edit</Icon>{t("list.baseFileList.rename")}
                                                                    </MenuItem>,
                                                                    <MenuItem key={Math.random()}
                                                                              onClick={(e) => handleDelete(e, contextMenu)}><Icon>delete</Icon>{t("list.baseFileList.delete")}
                                                                    </MenuItem>,
                                                                ]
                                                        : [
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleDownload(e, contextMenu)}><Icon>download</Icon>{t("list.baseFileList.download")}
                                                            </MenuItem>,
                                                            (contextMenu.context as any).fullName
                                                            && ((contextMenu.context as any).fullName.endsWith(".bin") || (contextMenu.context as any).fullName.endsWith(".uf2")) ? undefined :
                                                                <MenuItem key={Math.random()}
                                                                          onClick={(e) => handleEdit(e, contextMenu)}><Icon>edit_note</Icon>{t("list.baseFileList.edit")}
                                                                </MenuItem>,
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleRename(e, contextMenu)}><Icon>edit</Icon>{t("list.baseFileList.rename")}
                                                            </MenuItem>,
                                                            <MenuItem key={Math.random()}
                                                                      onClick={(e) => handleDelete(e, contextMenu)}><Icon>delete</Icon>{t("list.baseFileList.delete")}
                                                            </MenuItem>,
                                                        ]
                                            )
                                        }
                                    </MenuList>
                                </Paper>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
            );
        }
    }

    let accept: string = uploadController.accept(uploadController.typeFromPath(uiState.selectedFilePath)) || "*";
    if (uiState.selectedFilePath.includes("gcodes") || uiState.selectedFilePath.includes("macros")) {
        accept = accept + ",.zip";
    }

    const createButtonBar = () => {
        if (!smallScreen) {
            return (
                <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
                    {(directoryBaseType === "macros" || directoryBaseType === "filaments") &&
                        <Button variant={"outlined"}
                                onClick={handleNewFile}><Icon>note_add</Icon>{t(`button.${directoryBaseType === "filaments" && path === "/" ? "newFilament" : "newFile"}.caption`)}
                        </Button>
                    }
                    {directoryBaseType !== "filaments" &&
                        <Button
                            variant={"outlined"}
                            onClick={handleMakeDirectory}><Icon>create_new_folder</Icon>{t("button.newDirectory.caption")}
                        </Button>
                    }
                    <Button onClick={() => {
                        setForceReload(forceReload + 1)
                    }}><Icon>refresh</Icon>{t("button.refresh.caption")}</Button>
                    <FileUpload accept={accept} onFiles={handleFiles}
                                tooltip={t(`button.upload.${directoryBaseType}.title`)}>
                        <Button><Icon>backup</Icon>{t(`button.upload.${directoryBaseType}.caption`)}</Button>
                    </FileUpload>
                </div>
            )
        }

        const menuButtons = [];

        if (directoryBaseType === "macros" || directoryBaseType === "filaments") {
            menuButtons.push(
                <MenuItem sx={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                    <Button variant={"outlined"}
                            onClick={handleNewFile}><Icon>note_add</Icon>{t(`button.${directoryBaseType === "filaments" && path === "/" ? "newFilament" : "newFile"}.caption`)}
                    </Button>
                </MenuItem>
            )
        }
        if (directoryBaseType !== "filaments") {
            menuButtons.push(
                <MenuItem sx={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                    <Button
                        variant={"outlined"}
                        onClick={handleMakeDirectory}><Icon>create_new_folder</Icon>{t("button.newDirectory.caption")}
                    </Button>
                </MenuItem>
            )
        }
        menuButtons.push(
            <MenuItem sx={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                <Button onClick={() => {
                    setForceReload(forceReload + 1)
                }}><Icon>refresh</Icon>{t("button.refresh.caption")}</Button>
            </MenuItem>
        )
        menuButtons.push(
            <MenuItem sx={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                <FileUpload accept={accept} onFiles={handleFiles}
                            tooltip={t(`button.upload.${directoryBaseType}.title`)}>
                    <Button><Icon>backup</Icon>{t(`button.upload.${directoryBaseType}.caption`)}</Button>
                </FileUpload>
            </MenuItem>
        )

        return (

            <MenuButton
                onSelection={(s) => {

                }}
                menu={
                    <Menu open={false}>
                        {menuButtons}
                    </Menu>
                }
            >
                <Button variant={"outlined"} sx={{paddingLeft: "5px", paddingRight: "2px", marginRight: "20px"}}>Actions <Icon>keyboard_arrow_down</Icon></Button>
            </MenuButton>
        )
    }

    const monacoLanguageFromFile = (file: ListFileItem) => {
        if (file === undefined || file.fullName === undefined) {
            return undefined;
        }
        const gcodes = ".g,.gcode,.gc,.gco,.nc,.ngc,.tap".split(",");
        const extension = "." + file.fullName.split(".").pop() as string;
        if (gcodes.includes(extension)) {
            return "gcode-fdm";
        }
        if (extension === ".json") {
            return "json";
        }
        // TODO: Handle more languages if needed
        return undefined;
    }

    return (
        <div className={props.className}>
            <div className={"pathNav"}>
                <div>
                    <MenuButton
                        onSelection={(s) => {
                            dispatch(setUiState({...uiState, selectedFilePath: s}));
                        }}
                        menu={
                            <Menu open={false}>
                                {filesystemPaths.map((p) => {
                                    return <MenuItem key={p}>{p}</MenuItem>;
                                })}
                            </Menu>
                        }
                    >
                        <Button variant={"outlined"} sx={{
                            paddingLeft: "5px",
                            paddingRight: "2px",
                            marginRight: "20px"
                        }}><Icon>folder_open</Icon><Icon>keyboard_arrow_down</Icon></Button>
                    </MenuButton>
                </div>

                <div style={{flex: "1"}}>
                    <StyledSpan sx={{cursor: "pointer", ":hover": {textDecoration: "underline"}}}
                                onClick={() => setPath("/")}>{uiState.selectedFilePath} /&nbsp;</StyledSpan>
                    <Breadcrumbs sx={{display: "inline-block"}} maxItems={4} itemsBeforeCollapse={2} itemsAfterCollapse={1}>
                        { pieces.reduce((acc, cur, idx) => {
                            if (idx < pieces.length - 1) {
                                const x = pieces.slice(0, idx + 1).join("/");
                                acc = acc.concat(<StyledSpan key={x} sx={{cursor: "pointer", ":hover": {textDecoration: "underline"}}} onClick={() => {
                                    setPath("/" + x);
                                }}>{cur}</StyledSpan>);
                            }
                            else {
                                acc = acc.concat(<span key={cur}>{cur}</span>);
                            }
                            return acc;
                        }, [] as JSX.Element[])}
                    </Breadcrumbs>
                </div>
                {createButtonBar()}
            </div>

            <DataGrid
                apiRef={gridApi}
                sx={{cursor: "pointer"}}
                columns={columnDefs}
                rowHeight={48}
                checkboxSelection={true}
                getRowId={(r) => r.fullPath}
                rows={directoryList.dirs.concat(directoryList.files)}
                onRowSelectionModelChange={(m) => {setRowSelectionModel(m)}}
                rowSelectionModel={rowSelectionModel}
                isRowSelectable={(p) => p.row.hasOwnProperty("fullName")}
                onRowClick={(row, e, details) => {
                    if (row.row.fullName === undefined) { // Dir
                        let p = Path.combine(path, row.row.name);
                        if (!p.startsWith("/")) {
                            p = "/" + p; // Keep it rooted
                        }
                        setPath(p);
                    }
                    else {
                        handleFileClick(row.row);
                    }
                }}
                disableRowSelectionOnClick={true}
                disableColumnResize={true}
                disableColumnFilter={true}
                disableColumnSorting={true}
                disableColumnMenu={true}
                // pagination={undefined}
                hideFooter={directoryList.dirs.length + directoryList.files.length <= paginationModel.pageSize}
                paginationModel={paginationModel}
                onPaginationModelChange={(model, details) => {
                    setPaginationModel(model);
                }}
                slotProps={{
                    row: {
                        onContextMenu: (e) => {
                            e.preventDefault();
                            // e.stopPropagation();
                            // @ts-ignore
                            const contextItem = directoryList.dirs.concat(directoryList.files).find((i) => i.fullPath === (e.target as HTMLDivElement).parentElement.dataset.id);
                            if (!contextItem)
                                return;

                            setContextMenu({
                                mouseX: e.clientX,
                                mouseY: e.clientY,
                                context: contextItem,
                            });

                            const virtualElement = {
                                getBoundingClientRect: () => ({
                                    width: 0,
                                    height: 0,
                                    top: e.clientY,
                                    right: e.clientX,
                                    bottom: e.clientY,
                                    left: e.clientX
                                })
                            };
                            setAnchorEl(virtualElement);
                        }
                    }
                }}
            />
            {createPopupMenu()}
            {confirmDeleteOpen &&
                <ConfirmDialog
                    open = {confirmDeleteOpen}
                    onClose = {() => {setConfirmDeleteOpen(false)}}
                    title = {t("main:list.baseFileList.confirmDeleteTitle")}
                    question = {t("main:list.baseFileList.confirmDeleteText")}
                    onConfirm = {onConfirm}
                    yesno = {true}
                />
            }
            {confirmRunOpen &&
                <ConfirmDialog
                    open = {confirmRunOpen}
                    onClose = {() => {setConfirmRunOpen(false)}}
                    title = {t("dialog.runMacro.title", {"0": originalName})}
                    question = {t("dialog.runMacro.prompt", {"0": originalName})}
                    onConfirm = {onConfirm}
                    yesno = {true}
                />
            }
            {confirmStartOpen &&
                <ConfirmDialog
                    open = {confirmStartOpen}
                    onClose = {() => {setConfirmStartOpen(false)}}
                    title = {t("dialog.startJob.title", {"0": originalName})}
                    question = {t("dialog.startJob.prompt", {"0": originalName})}
                    onConfirm = {onConfirm}
                    yesno = {true}
                />
            }
            {confirmMultiOpen &&
                <ConfirmDialog
                    open = {confirmMultiOpen}
                    onClose = {() => {setConfirmMultiOpen(false)}}
                    title = {t("main:list.baseFileList.confirmDeleteTitle")}
                    question = {t("main:list.baseFileList.confirmDeleteText", {count: 2})}
                    onConfirm = {onDeleteMulti}
                    yesno = {true}
                />
            }
            {valueEntryOpen &&
                <ValueEntryDialog
                    open = {valueEntryOpen}
                    onClose = {() => {setValueEntryOpen(false)}}
                    title = {t("main:list.baseFileList.confirmRenameTitle")}
                    prompt = {t("main:list.baseFileList.confirmRenameText")}
                    onConfirm = {onConfirm}
                    value = {originalName}
                />
            }
            {dirnameEntryOpen &&
                <ValueEntryDialog
                    open = {dirnameEntryOpen}
                    onClose = {() => {setDirnameEntryOpen(false)}}
                    title = {t("dialog.newDirectory.title")}
                    prompt = {t("dialog.newDirectory.prompt")}
                    onConfirm = {onCreateDir}
                    value = {""}
                />
            }
            {newFileEntryOpen &&
                <ValueEntryDialog
                    open = {newFileEntryOpen}
                    onClose = {() => {setNewFileEntryOpen(false)}}
                    title = {t("dialog.newFile.title")}
                    prompt = {t("dialog.newFile.prompt")}
                    onConfirm = {onCreateFile}
                    value = {""}
                />
            }
            {newFilamentEntryOpen &&
                <ValueEntryDialog
                    open = {newFilamentEntryOpen}
                    onClose = {() => {setNewFilamentEntryOpen(false)}}
                    title = {t("dialog.newFilament.title")}
                    prompt = {t("dialog.newFilament.prompt")}
                    onConfirm = {onCreateFilament}
                    value = {""}
                />
            }
            <MonacoEditor
                open={editorOpen}
                buffer={editorBuffer}
                language={monacoLanguageFromFile(editorFile)}
                fullPath={editorFile.fullPath}
                onSave={(buffer) => {
                    setEditorOpen(false);
                    fileTransfer.upload(editorFile.fullPath, buffer)
                        .then(() => {
                            setForceReload(forceReload + 1);
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                }}
                onClose={() => {
                    setEditorOpen(false);
                }}
            />
        </div>
    );
}

export default styled(withTranslation()(FileList))((props) => {
    return {
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none !important",
            // display: "none",
        },
        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-colummnHeader:focus": {
            outline: "none !important",
            // display: "none",

        },
        "& .MuiDataGrid-overlayWrapper": {
            height: "32px",
        },
        "& .pathNav": {
            display: "flex",
            flexDirection: "row",
            padding: "20px",
            alignItems: "center",
        },
    };
})
