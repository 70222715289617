import {styled, Tooltip} from "@mui/material";
import {cloneElement, ReactElement, ReactNode, useContext, useRef} from "react";
import {JSXSource} from "react/jsx-dev-runtime";
import {UploadController} from "../../Utils/UploadController";
import {UploadControllerCtx} from "../MachineAccessWrapper";

export type FileUploadProps = {
    accept: string;
    onFiles: (files: File[]) => void;
    className?: string;
    children: ReactElement;
    tooltip?: string;
};

const FileUpload: React.FC<FileUploadProps> = ({
                                                   accept,
                                                   onFiles,
                                                   className,
                                                   children,
                                                   tooltip,
                                               }) => {
    const inputRef = useRef(null);

    const stopDefaults = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };
    const dragEvents = {
        onDragEnter: (e: React.DragEvent) => {
            stopDefaults(e);
        },
        onDragLeave: (e: React.DragEvent) => {
            stopDefaults(e);
        },
        onDragOver: stopDefaults,
        onDrop: (e: React.DragEvent<HTMLElement>) => {
            stopDefaults(e);

            if (e.dataTransfer.files !== null && e.dataTransfer?.files?.length > 0) {
                const uploadedFiles = [];
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    uploadedFiles.push(e.dataTransfer.files[i]);
                }
                onFiles(uploadedFiles);
            }
        },
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null && e.target?.files?.length > 0) {
            const uploadedFiles = [];
            for (let i = 0; i < e.target.files.length; i++) {
                uploadedFiles.push(e.target.files[i]);
            }
            onFiles(uploadedFiles);
        }
        e.target.value = ""; // this is reset in order to allow re-uploading the same file (or a file with the same file name)
    };

    const handleChildClick = (e: React.MouseEvent<HTMLElement>) => {
        // @ts-ignore
        inputRef.current?.click();
    }

    return (
        <>
            <input
                ref={inputRef}
                onChange={handleChange}
                accept={accept}
                className="hidden"
                id="file-upload"
                type="file"
                multiple={true}
                style={{display: "none"}}
            />
            {tooltip
                ? <Tooltip title={tooltip}>
                    {cloneElement(children, {...dragEvents, onClick: handleChildClick})}
                </Tooltip>
                : cloneElement(children, {...dragEvents, onClick: handleChildClick})
            }
        </>
    );
};

export default FileUpload;
