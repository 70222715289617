import {Button, styled, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import {useContext, useRef} from "react";
import {LayoutBase} from "../../../Reducers/PanelContainer";
import objectModel from "../../../Reducers/ObjectModel";
import {purge, useAppSelector} from "../../../ReduxStore";
import CodeButton from "../../Inputs/CodeButton";

const TestPanel = (props: any): JSX.Element => {
    const {panel} = props;
    const objectModelState = useAppSelector((state) => state.objectModel);

    return (
        <div className={`${props.className} panel-inner`}>
            {/*{objectModelState.current.model.heat.heaters.length}*/}
            <Button disabled={props.editMode} onClick={() => {
                purge();
            }}><Typography noWrap={true}>Clear local data</Typography></Button>&nbsp;
            <CodeButton disabled={props.editMode} code={"M39"} caption={"Send M39"} />
        </div>
    );
};

const TestPanelWrapped = styled(withTranslation()(TestPanel))`
    display: flex;
    color: black;
    justify-content: center;
    align-items: center;
`;

TestPanelWrapped.defaultProps = {minW: 1, minH: 2, maxH: 2} as LayoutBase;

export default TestPanelWrapped;
