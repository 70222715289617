import React, {createContext, useContext, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {styled, Switch, useTheme} from "@mui/material";
import ThemeSwitcher from "./Components/Misc/ThemeSwitcher";
import DuetSwitch from "./Components/Inputs/Switch";
import LanguagePicker from "./Components/Misc/LanguagePicker";
import i18n from "./i18n";
import DynamicPageLayout from "./Components/DynamicPageLayout";
import {createBrowserHistory} from "history";
import {BrowserRouter, BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import ShowColors from "./Pages/ShowColors";
import Sidebar from "./Components/Navigation/Sidebar";
import AppFraming from "./Components/Navigation/AppFraming";
import TopBar from "./Components/Navigation/TopBar/TopBar";
import {useAppDispatch, useAppSelector} from "./ReduxStore";
import {setUiState} from "./Reducers/UiState";
import Playground from "./Pages/Playground";
import MacrosPage from "./Pages/Files";
import FilesPage from "./Pages/Files";
import Playpen from "./Pages/Playpen";
import FileUploadDisplay from "./Components/Dialogs/FileUploadDisplay";
import SettingsGeneral from "./Pages/SettingsGeneral";

interface AppProps {
    className?: string;
    t: any;
}
export const history = createBrowserHistory();

const App = (props: AppProps) => {
    const theme = useTheme();
    const {t} = props;
    const [buttonBar, setButtonBar] = useState<JSX.Element | undefined>(undefined);

    const populateButtonBar = (element: JSX.Element) => {
        setButtonBar(element);
    }

    const clearButtonBar = () => {
        setButtonBar(undefined);
    }

    const dashboardProps = {
        populateButtonBar: populateButtonBar,
        clearButtonBar: clearButtonBar,
    }

    console.log(i18n.language);
    return (
        <div className={`${props.className} App`} onContextMenu={(e) => e.preventDefault()}>
            <BrowserRouter>
                <AppFraming
                    navbar={<TopBar buttonBar={buttonBar} />}
                >
                    {/*<div>*/}
                    {/*    <div style={{float: "right"}}>*/}
                    {/*        <LanguagePicker*/}
                    {/*            current={i18n.language}*/}
                    {/*            direction="down"*/}
                    {/*            colDirection="left"*/}
                    {/*            onChange={() => {}}*/}
                    {/*            onChangeEnd={(lang: string) => {*/}
                    {/*                i18n.changeLanguage(lang);*/}
                    {/*            }}*/}
                    {/*            rows={10}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <Routes>
                        <Route
                            path={"/"}
                            element={
                                <DynamicPageLayout {...dashboardProps} panelId="Dashboard" panelName={"Dashboard"} sx={{clear: "both"}} />
                            }
                        />
                        <Route
                            path={"/user1"}
                            element={
                                <DynamicPageLayout {...dashboardProps} panelId="User1" panelName={"User 1"} sx={{clear: "both"}} />
                            }
                        />
                        <Route
                            path={"/colors"}
                            element={<ShowColors/>}
                        />
                        <Route
                            path={"/playground"}
                            element={<Playground />}
                        />
                        <Route
                            path={"/files"}
                            element={<FilesPage />}
                        />
                        <Route
                            path={"/playpen"}
                            element={<Playpen />}
                        />
                        <Route
                            path={"/settings/general"}
                            element={<SettingsGeneral />}
                        />
                    </Routes>
                </AppFraming>
            </BrowserRouter>
            <FileUploadDisplay />
        </div>
    );
};

export default withTranslation()(
    styled(App)((theme) => {
        return {
            ".panel-inner": {
                position: "absolute",
                left: "0",
                top: "0",
                right: "0",
                bottom: "0",
                overflow: "hidden",
                backgroundColor: theme.theme.palette.background.default,
                borderRadius: "6px",
            },
            ".panel-content": {
                position: "absolute",
                top: "24px",
                bottom: "0",
                left: "0",
                right: "0",
                margin: "5px",
                overflow: "hidden",
            },
        };
    }),
);
