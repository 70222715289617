import {createSlice} from "@reduxjs/toolkit"
import type {PayloadAction} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

export interface MenuItem {
    caption: string;
    icon: string;
    route: string;
    id: string;
}

export interface MenuSection {
    caption: string;
    open: boolean;
    icon: string;
    items: MenuItem[];
}

export interface MenuState {
    sectionOrder: string[];
    sections: {[sectionName: string]: MenuSection}
}

export interface UiState {
    frozen: boolean;
    extrudeAmount: number;
    extrudeFeedrate: number;
    extrudeMix: (number | "mix")[];
    menuOpen: boolean;
    menuState: MenuState;
    selectedFilePath: string;
    toolsExtrasShown: boolean;
}

const initialState: UiState = {
    frozen: false,
    extrudeAmount: 10,
    extrudeFeedrate: 2,
    extrudeMix: ["mix"],
    menuOpen: true,
    menuState: {sections: {}, sectionOrder: []},
    selectedFilePath: "",
    toolsExtrasShown: false,
};

const uiStateSlice = createSlice({
    name: "uiState",
    initialState,
    reducers: {
        setUiState(state, action: PayloadAction<UiState>) {
            Object.assign(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            Object.assign(state, initialState);
        });
    },
});

export const {setUiState} = uiStateSlice.actions;
export default uiStateSlice.reducer;
