import {WithTranslation, withTranslation} from "react-i18next";
import {Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, styled} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../ReduxStore";
import uiState, {setUiState} from "../../../Reducers/UiState";
import React, {Fragment, useEffect} from "react";
import {Icon} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
const cloneDeep = require("lodash.clonedeep");

interface SidebarProps extends WithTranslation {
    className?: string;
    closeMenu?: () => void;
}

const Sidebar = (props: SidebarProps) => {
    const uiState = useAppSelector(state => state.uiState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search)
    const reload = queryParameters.get("reload");

    useEffect(() => {
        if (uiState.menuState === undefined || uiState.menuState.sectionOrder.length === 0 || reload) {
            dispatch(setUiState({...uiState,
                menuState: {
                    sections: {
                        "control": {open: true, caption: "", icon: "tune", items: [
                                {
                                    caption: t("menu.control.dashboard"),
                                    icon: "dashboard",
                                    route: "/",
                                    id: "Dashboard",
                                },
                                {
                                    caption: t("User 1"),
                                    icon: "dashboard",
                                    route: "/user1",
                                    id: "User1",
                                },
                            ]},

                        "files": {open: true, caption: "", icon: "sd_storage", items: [
                                {
                                    caption: t("menu.files.browse"),
                                    icon: "folder",
                                    route: "/files",
                                    id: "Browse",
                                }
                            ]},

                        "playpen": {open: true, caption: "Playpen", icon: "play", items: [
                                {
                                    caption: "Page 1",
                                    icon: "play",
                                    route: "/playpen",
                                    id: "Playpen",
                                }
                            ]},
                        "settings": {open: true, caption: "", icon: "build", items: [
                                {
                                    caption: "General",
                                    icon: "tune",
                                    route: "/settings/general",
                                    id: "SettingsGeneral"
                                }
                            ]}
                    }, sectionOrder: ["control", "files", "playpen", "settings"]
                }
            }))
            navigate("/");
        }
    });

    const {t} = props;

    return (
        <div className={props.className}>
            <List>
                {uiState.menuState.sectionOrder.map((s) => {
                    return (
                        <Fragment key={s}>
                            <ListItemButton onClick={() => {
                                let m = cloneDeep(uiState.menuState);
                                m.sections[s].open = !m.sections[s].open;
                                dispatch(setUiState({...uiState, menuState: m}));
                            }}>
                                <ListItemIcon>
                                    <Icon>{uiState.menuState.sections[s].icon}</Icon>
                                </ListItemIcon>
                                <ListItemText>
                                    {uiState.menuState.sections[s].caption
                                        ? uiState.menuState.sections[s].caption
                                        : t(`menu.${s}.caption`)
                                    }
                                </ListItemText>
                                {uiState.menuState.sections[s].open ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                            </ListItemButton>
                            <Collapse in={uiState.menuState.sections[s].open} unmountOnExit={true}>
                                <List component={"div"} disablePadding>
                                    {uiState.menuState.sections[s].items.map((i) => {
                                        return (
                                            <ListItemButton
                                                key={i.caption}
                                                sx={{pl: 6}}
                                                onClick={() => {
                                                    navigate(i.route);
                                                    // @ts-ignore
                                                    props.closeMenu();
                                                }}
                                            >
                                                <ListItemIcon><Icon>{i.icon}</Icon></ListItemIcon>
                                                <ListItemText>{
                                                    t(`menu.${s}.${i.caption.toLowerCase()}`) !== `menu.${s}.${i.caption.toLowerCase()}`
                                                        ? t(`menu.${s}.${i.caption.toLowerCase()}`)
                                                        : i.caption
                                                }</ListItemText>
                                            </ListItemButton>
                                        );
                                    })}
                                </List>
                            </Collapse>
                        </Fragment>
                    );
                })}
                {/*<ListItem>*/}
                {/*    <ListItemIcon><TuneIcon /></ListItemIcon>*/}
                {/*    <ListItemText>{t("menu.control.caption")}</ListItemText>*/}
                {/*</ListItem>*/}
                {/*<ListItem>*/}
                {/*    <ListItemIcon><BuildIcon /></ListItemIcon>*/}
                {/*    <ListItemText>{t("menu.settings.caption")}</ListItemText>*/}
                {/*</ListItem>*/}
                {/*<Collapse>*/}

                {/*</Collapse>*/}
            </List>
        </div>
    )
}

export default styled(withTranslation()(Sidebar))((props) => {
    return {
        minHeight: "100%",
    };
})
