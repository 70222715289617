import {WithTranslation, withTranslation} from "react-i18next";
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    styled,
    TextField,
    Tooltip,
    useTheme
} from "@mui/material";
import {useState} from "react";
import CodeButton from "../../Inputs/CodeButton";
import {LoadingButton} from "@mui/lab";

interface ConnectionProgressProps extends WithTranslation {
    needHostname: boolean;
    setHostname: (h: string) => void;
    progress: number;
    halted: number;
}

const ConnectionProgress = (props: ConnectionProgressProps) => {
    const [hostname, setHostname] = useState("");
    const {t, needHostname} = props;
    const theme = useTheme();
    const [resetPending, setResetPending] = useState(false);

    return (
        <Dialog hideBackdrop={true} open={true}>
            <form onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                <DialogContent>
                    {needHostname
                        ? (
                            <>
                                <div>{t("enterHostname")}</div>
                                <TextField
                                    sx={{
                                        [theme.breakpoints.down("sm")]: {
                                            width: "180px",
                                        },
                                        [theme.breakpoints.up("sm")]: {
                                            width: "480px",
                                        },
                                    }}
                                    className={"text-entry"}
                                    variant={"standard"}
                                    label={t("dialog.connect.hostPlaceholder")}
                                    id="hostname"
                                    onChange={(e) => {
                                        setHostname(e.target.value);
                                    }}
                                    focused={true}
                                    autoFocus={true}
                                    onBlur={() => { // iOS workaround to reset unwanted body scroll
                                        window.scrollTo(0, 0);
                                        document.body.scrollTop = 0;
                                    }}
                                />
                            </>
                        )
                        : props.halted > 2
                            ?
                            (
                                <>
                                    <div>{t("dialog.connection.standBy")}</div>
                                    <LinearProgress
                                        className={"progress"}
                                        color={"primary"}
                                        variant={"indeterminate"}
                                        sx={{
                                            [theme.breakpoints.down("sm")]: {
                                                width: "180px",
                                            },
                                            [theme.breakpoints.up("sm")]: {
                                                width: "480px",
                                            },
                                        }}
                                    />
                                </>
                            )
                            : (
                                <>
                                    <div>{props.progress === -1 ? t("dialog.connect.reconnecting") : t("connecting")}</div>
                                    <LinearProgress
                                        className={"progress"}
                                        color={"primary"}
                                        variant={props.progress !== -1 ? "determinate" : "indeterminate"}
                                        sx={{
                                            [theme.breakpoints.down("sm")]: {
                                                width: "180px",
                                            },
                                            [theme.breakpoints.up("sm")]: {
                                                width: "480px",
                                            },
                                        }}
                                        value={props.progress}
                                    />
                                </>
                            )
                    }
                </DialogContent>
                {needHostname
                    ? (
                        <DialogActions>
                            <Button
                                type={"submit"}
                                onClick={(e) => {
                                    props.setHostname(hostname);
                                }}
                                variant={"contained"}
                            >
                                {t("dialog.connect.connect")}
                            </Button>
                        </DialogActions>
                    )
                    : props.halted > 8
                        ? (
                            <DialogActions>
                                <CodeButton code={"M999"} onClick={() => {
                                    setResetPending(true);
                                }}>
                                    <Tooltip title={t("main:button.reset.title")}>
                                        <LoadingButton variant={"contained"} loading={resetPending}>
                                            {t("button.reset.caption")}
                                        </LoadingButton>
                                    </Tooltip>
                                </CodeButton>
                            </DialogActions>
                        )
                        : (
                            <></>
                        )
                }
            </form>
        </Dialog>
    );
};

export default withTranslation()(ConnectionProgress);
