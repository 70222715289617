import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {Layout, Responsive, WidthProvider} from "react-grid-layout";
import {Icon, IconButton, styled, useTheme} from "@mui/material";
import {WithTranslation, withTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {LayoutBase, PanelData, PanelItem, setPanelData} from "../../Reducers/PanelContainer";
import {useAppDispatch, useAppSelector} from "../../ReduxStore";
import * as panelClasses from "../Panels";
import resizeHandle from "../../Images/resize-handle.svg"
import ThemeSwitcher from "../Misc/ThemeSwitcher";
import Switch from "../Inputs/Switch";
import DynamicPanelSelector from "../DynamicPanelSelector";
import {v4 as uuidv4} from "uuid";

const cloneDeep = require("lodash.clonedeep");

const ReactGridLayout = WidthProvider(Responsive);

interface DynamicPageLayoutProps extends WithTranslation {
    className?: string;
    panelName: string;
    panelId: string;
    populateButtonBar?: (element: JSX.Element) => void;
    clearButtonBar?: () => void;
}

const DynamicPageLayout = (props: DynamicPageLayoutProps) => {
    const {panelId} = props;
    const currentState = useAppSelector((state) => state.dashboards);
    const dispatch = useAppDispatch();
    let newState: PanelData = cloneDeep(currentState[panelId]);
    const breakpoint = useRef<string>("lg");
    const breakpoints = {xl: 1600, lg: 1280, md: 960, sm: 640, xs: 480, xxs: 320};
    const [editMode, setEditMode] = useState(false);
    const theme = useTheme()
    const [addPanelOpen, setAddPanelOpen] = useState(false);
    const addPanelOpenRef = useRef(null);
    const addPanelRef = useRef(null);

    // Just fill in some data for testing
    // useEffect(() => {
    //     let newState: PanelData = cloneDeep(currentState[panelId]);
    //     if (newState === undefined) {
    //         newState = { panels: []} as PanelData;
    //     }
    //     if (newState.panels.length === 0) {
    //         newState.panels = [
    //             {
    //                 elementType: "TestPanel",
    //                 name: "panel_1",
    //                 layouts: {
    //                     lg: {
    //                         x: 0,
    //                         y: 0,
    //                         h: 2,
    //                         w: 2,
    //                         i: "panel_1",
    //                     },
    //                 },
    //             },
    //             {
    //                 elementType: "ExtrusionPanel",
    //                 name: "panel_2",
    //                 layouts: {
    //                     lg: {
    //                         x: 0,
    //                         y: 0,
    //                         h: 7,
    //                         w: 4,
    //                         i: "panel_2",
    //                     },
    //                 },
    //             },
    //             {
    //                 elementType: "MotionPanel",
    //                 name: "panel_3",
    //                 layouts: {
    //                     lg: {
    //                         x: 0,
    //                         y: 0,
    //                         h: 8,
    //                         w: 4,
    //                         i: "panel_3",
    //                     },
    //                 },
    //             },
    //             {
    //                 elementType: "ConsolePanel",
    //                 name: "panel_4",
    //                 layouts: {
    //                     lg: {
    //                         x: 0,
    //                         y: 0,
    //                         h: 8,
    //                         w: 4,
    //                         i: "panel_4",
    //                     },
    //                 },
    //             },
    //             {
    //                 elementType: "StatusPanel",
    //                 name: "panel_5",
    //                 layouts: {
    //                     lg: {
    //                         x: 0,
    //                         y: 0,
    //                         h: 8,
    //                         w: 3,
    //                         i: "panel_5",
    //                     },
    //                 },
    //             },
    //         ];
    //         dispatch(setPanelData({...currentState, [panelId]: newState}));
    //     }
    // });

    useEffect(() => {
        props.populateButtonBar && props.populateButtonBar(
            <div style={{marginTop: "auto", marginBottom: "auto"}}>
                <IconButton ref={addPanelOpenRef} onClick={(e) => {
                    setAddPanelOpen(!addPanelOpen);
                    console.log(addPanelOpen);
                }}>
                    <Icon>add</Icon>
                </IconButton>
            </div>
        );
        return () => {
            props.clearButtonBar && props.clearButtonBar();
        }
    }, [props.panelId, addPanelOpen])

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (addPanelOpen
                && addPanelRef.current
                // @ts-ignore
                && !addPanelRef.current.contains(event.target)
                // @ts-ignore
                && !addPanelOpenRef.current.contains(event.target)) {
                setAddPanelOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [addPanelOpen, addPanelRef]);

    if (newState === undefined || newState.panels === undefined) {
        newState = { panels: []};
        dispatch(setPanelData({...currentState, [panelId]: newState}));
        return <></>;
    }

    const panelIndex = newState.panels.reduce(
        (acc, cur, idx) => {
            acc[cur.name] = idx;
            return acc;
        },
        {} as {[key: string]: number},
    );
    const cls = Object.entries(panelClasses);
    const panelMap = cls.reduce(
        (acc, cur, idx) => {
            acc[cur[0]] = cur[1];
            return acc;
        },
        {} as {[key: string]: any},
    );

    const clamp = (val: number, min: number, max: number) => Math.min(Math.max(val, min), max);

    const constrain = (layout: Layout, base: LayoutBase): Layout => {
        layout.w = clamp(layout.w, base.minW ? base.minW : 1, base.maxW ? base.maxW : 99);
        layout.h = clamp(layout.h, base.minH ? base.minH : 1, base.maxH ? base.maxH : 9999);

        return layout;
    };

    const generateLayout = (breakpoint: string) => {
        return newState.panels.map((p) => {
            let defProps = cloneDeep({...panelMap[p.elementType].defaultProps});
            delete defProps.meta;
            return {
                ...constrain({x: 0, y: 0, w: 2, h: 2, i: p.name}, defProps),
                ...defProps,
                ...p.layouts[breakpoint],
            } as Layout;
        });
    };

    const layouts = {
        xxs: generateLayout("xxs"),
        xs: generateLayout("xs"),
        sm: generateLayout("sm"),
        md: generateLayout("md"),
        lg: generateLayout("lg"),
    };

    const cols = {
        xxs: 2,
        xs: 3,
        sm: 4,
        md: 6,
        lg: 10,
        xl: 12,
    };

    const removePanel = (name: string) => {
        newState.panels = newState.panels.filter(p => p.name !== name);
        dispatch(setPanelData({...currentState, [panelId]: newState}));
    }

    const addPanel = (type: string) => {
        let defLayout: LayoutBase = cloneDeep(panelMap[type].defaultProps);
        delete defLayout.meta;
        let w = defLayout.minW, h = defLayout.minH;
        if (defLayout.maxW !== undefined) {
            w = defLayout.maxW;
        }
        if (defLayout.maxH !== undefined) {
            h = defLayout.maxH
        }

        const newPanel: PanelItem = {
            elementType: type,
            layouts: {
                lg: {
                    ...panelMap[type].defaultProps,
                    x: 0,
                    y: 0,
                    w: w,
                    h: h,
                }
            },
            name: uuidv4(),
        }

        newState.panels.push(newPanel);

        dispatch(setPanelData({...currentState, [panelId]: newState}));
        setAddPanelOpen(false);
    }

    return (
        <div className={props.className}>
            <div className={"inner-frame"}>
                <ThemeSwitcher>
                    <Switch checked={theme.palette.mode === "dark"} />
                </ThemeSwitcher>
                <div style={{clear: "both"}}>
                    <input
                        type={"checkbox"}
                        onChange={(e) => {
                            setEditMode(e.target.checked);
                        }}
                    />
                </div>
                <ReactGridLayout
                    isDraggable={editMode}
                    isResizable={editMode}
                    rowHeight={20}
                    layouts={layouts}
                    compactType={null}
                    preventCollision={true}
                    measureBeforeMount={false}
                    useCSSTransforms={true}
                    breakpoints={breakpoints}
                    onLayoutChange={(layout, layouts) => {
                        layouts[breakpoint.current].map((l) => {
                            newState.panels[panelIndex[l.i]].layouts[breakpoint.current] = l;
                            return undefined;
                        });
                        dispatch(setPanelData({...currentState, [panelId]: newState}));
                    }}
                    onBreakpointChange={(bp, cols) => {
                        breakpoint.current = bp;
                    }}
                    onDrop={(layout, item, e) => {

                    }}
                    cols={cols}
                >
                    {newState.panels.map((p) => {
                        return (
                            <div key={p.name} style={{position: "relative"}}>
                                {React.createElement(panelMap[p.elementType], {
                                    panel: newState.panels[panelIndex[p.name]],
                                    editMode: editMode,
                                    panelId: p.name,
                                })}
                                {editMode &&
                                    <>
                                        <div className={"delete-overlay"}></div>
                                        <div className={"delete-icon"}><IconButton onMouseDown={(e) => e.stopPropagation()} onClick={() => {
                                            removePanel(p.name);
                                        }
                                        }><Icon>cancel</Icon></IconButton></div>
                                    </>
                                }
                            </div>
                        );
                    })}
                </ReactGridLayout>
            </div>
            {addPanelOpen &&
                <div className={"add-panel"} ref={addPanelRef}>
                    <DynamicPanelSelector close={(type: string) => addPanel(type)}/>
                </div>
            }
        </div>
    );
};

export default styled(withTranslation()(DynamicPageLayout))((props) => {
    return {
        ".inner-frame": {
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            overflowX: "hidden",
            overflowY: "auto",
        },
        ".react-grid-layout": {
            position: "relative",
            transition: "height 200ms ease",
            ".react-grid-item": {
                transition: "all 200ms ease",
                transitionProperty: "left, top",
                boxSizing: "border-box",
            },
            ".react-grid-item.cssTransforms": {
                transitionProperty: "transform",
            },
            ".react-grid-item.resizing": {
                zIndex: "1",
                willChange: "width, height",
                opacity: "0.9",
            },
            ".react-grid-item.react-draggable-dragging": {
                transition: "none",
                zIndex: "3",
                willChange: "transform",
            },
            ".react-grid-item.react-grid-placeholder": {
                background: "blue",
                opacity: "0.1",
                transitionDuration: "100ms",
                zIndex: "2",
                userSelect: "none",
            },
            ".react-grid-item > .react-resizable-handle": {
                position: "absolute",
                width: "30px",
                height: "30px",
                bottom: "0",
                right: "0",
                // background: "url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=')",
                backgroundPosition: "bottom right",
                padding: "0 3px 3px 0",
                backgroundRepeat: "no-repeat",
                backgroundOrigin: "content-box",
                boxSizing: "border-box",
                cursor: "se-resize",
                zIndex: "5",
            },
            ".react-grid-layout": {
                background: "#eee",
            },
            ".layoutJSON": {
                background: "#ddd",
                border: "1px solid black",
                marginTop: "10px",
                padding: "10px",
            },
            ".columns": {
                columns: "120px",
            },
            ".react-grid-item:not(.react-grid-placeholder)": {
                border: "1px solid #c0c0c0",
                filter: "drop-shadow(#ddd 1px 1px 2px)",
                borderRadius: "6px",
            },
            ".react-grid-item.static": {
                //background: "#cce",
            },
            ".react-grid-item .text": {
                fontSize: "24px",
                textAalign: "center",
                position: "absolute",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                margin: "auto",
                height: "24px",
            },
            ".react-grid-item .minMax": {
                fontSize: "12px",
            },
            ".react-grid-item .add": {
                cursor: "pointer",
            },
            ".react-grid-dragHandleExample": {
                cursor: "grab",
            },
            "li b": {
                fontSize: "19px",
                lineHeight: "14px",
            },
            ".react-grid-item > .react-resizable-handle, .react-grid-item > .react-resizable-handle-se": {
                backgroundImage: `url(${resizeHandle})`,
                opacity: "0.5",
                "&::after": {
                    content: "none",
                }
            },
            ".delete-overlay": {
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                backgroundColor: "grey",
                zIndex: "1",
                opacity: "0.2",
            },
            ".delete-icon": {
                position: "absolute",
                left: "10px",
                top: "10px",
                zIndex: "101",
                "&>*": {},
            },
        },
        ".add-panel": {
            position: "absolute",
            top: "0",
            bottom: "0",
            right:"0",
            width: "320px",
            backgroundColor: props.theme.palette.background.default,
            borderLeft: "6px double #e0e0e0",
            borderTop: "1px solid #e0e0e0",
        }
    };
})
