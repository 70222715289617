import {styled, Typography} from "@mui/material";
import {withTranslation, WithTranslation} from "react-i18next";

interface FileProgressBarProps extends WithTranslation {
    className?: string;
    text?: string;
    textKey?: string;
    progress: number;
}

const FileProgressBar = (props: FileProgressBarProps) => {
    const {t} = props;

    return (
        <div className={props.className} style={{display: "flex", flexDirection: "row"}}>
            <div className={"frame"}>
                <div className={"gutter"}>
                    <div className={"gutter-wrap"}>
                        <div style={{flexGrow: "1", minWidth: "0"}}>
                            <Typography noWrap={true}>
                                {props.textKey !== undefined
                                    ? t(props.textKey, {"0": props.progress ? props.progress.toFixed(0) : 0})
                                    : props.text
                                }
                            </Typography>
                        </div>
                        <div>
                            {props.progress && props.progress.toFixed(0)}%
                        </div>
                    </div>
                </div>
                <div className={"bar"}>
                    <div className={"gutter-wrap"}>
                        <div style={{flexGrow: "1", minWidth: "0"}}>
                            <Typography noWrap={true}>
                                {props.textKey !== undefined
                                    ? t(props.textKey, {"0": props.progress.toFixed(0)})
                                    : props.text
                                }
                            </Typography>
                        </div>
                        <div>
                            {props.progress && props.progress.toFixed(0)}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default styled(withTranslation()(FileProgressBar))((props) => {
    const accentColor = props.theme.palette.info.light;

    return {
        "@keyframes move": {
            "100%": {
                backgroundPosition: "50px 50px",
            }
        },
        ".bar": {
            borderRadius: "4px",
            display: "flex",
            alignContent: "center",
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            overflow: "hidden",
            whiteSpace: "nowrap",
            bottom: 0,
            backgroundColor: props.theme.palette.info.main,
            color: "white",
            backgroundSize: "25px 25px",
            backgroundImage: props.progress !== 100 && props.progress !== 0 ? `linear-gradient(-45deg, ${accentColor} 25%, transparent 25%, transparent 50%, ${accentColor} 50%, ${accentColor} 75%, transparent 75%, transparent)`: undefined,
            animation: props.progress !== 100 && props.progress !== 0 ? "move 2s linear infinite" : undefined,
            clipPath: `polygon(0% 0%, ${props.progress}% 0%, ${props.progress}% 100%, 0% 100%)`,
        },
        ".gutter": {
            borderRadius: "4px",
            display: "flex",
            alignContent: "center",
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: props.theme.palette.action.hover,
        },
        ".gutter-wrap": {
            display: "flex",
            flexWrap: "nowrap",
            gap: "48px",
            width: "100%",
            marginTop: "auto",
            marginBottom: "auto",
            paddingLeft: "32px",
            paddingRight: "32px",
        },
        ".frame": {
            borderRadius: "4px",
            flex: "1 1",
            border: "1px solid",
            borderColor: props.theme.palette.action.selected,
            height: "32px",
            position: "relative",
        },
    };
})
