import {Button, styled} from "@mui/material";
import {Axis} from "@duet3d/objectmodel";
import JogButtonBar from "../JogButtonBar";
import {settings} from "../../../Machine/settings";

const AxisJogControl = (props: any) => {
    const {axis}: {axis: Axis} = props;
    let offsets;

    // @ts-ignore
    if (settings.moveSteps[axis.letter] === undefined) {
        offsets = settings.moveSteps["default"];
    } else {
        // @ts-ignore
        offsets = settings.moveSteps[axis.letter];
    }
    return (
        <div className={props.className}>
            <JogButtonBar offsets={offsets} axis={props.axis} editMode={props.editMode}/>
            <div>
                <big>
                    <strong>{axis.letter}</strong>
                </big>
            </div>
            <JogButtonBar offsets={offsets} reverse={true} axis={props.axis} editMode={props.editMode}/>
        </div>
    );
};

export default styled(AxisJogControl)((props) => {
    return {
        display: "flex",
        "> div:first-of-type": {
            flexGrow: "100",
        },
        "> div:nth-of-type(2)": {
            textAlign: "center",
            paddingLeft: "5px",
            paddingRight: "5px",
        },
        "> div:last-of-type": {
            flexGrow: "100",
        },
        gap: "5px",
    } as React.CSSProperties as unknown as any;
});
