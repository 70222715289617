import PubSub from "pubsub-js";
import {reduxStore} from "../../ReduxStore";
import {MessageType} from "@duet3d/objectmodel";
import c from "ansi-colors";
import {snackbarError, snackbarSuccess, snackbarWarning} from "../../Helpers/SnackbarMode";
import {enqueueSnackbar} from "notistack";

/* This module dispatches events that need to be modified. It is here in order
 * to keep knowledge of event details out of the event provider.
 */

export function dispatchEvent(topic: any, msg: any) {
    const message = JSON.parse(msg);

    if (topic === "console-message") {
        for (const m of message.messages) {
            if (m.content.startsWith("Error:")) m.type = MessageType.error;
            if (m.type === MessageType.error) {
                enqueueSnackbar(m.content, snackbarError());
            }
            else if (m.type === MessageType.warning) {
                enqueueSnackbar(m.content, snackbarWarning());
            }
            // else {
            //     enqueueSnackbar(m.content, snackbarSuccess());
            // }
        }

    }

    PubSub.publish(topic, msg);
}
