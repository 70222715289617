import {Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, styled} from "@mui/material";
import {WithTranslation, withTranslation} from "react-i18next";
import React, {useEffect} from "react";
import EventListener from "../../../EventSystem/EventListener";
import FileProgressBar from "../../../Helpers/FileProgressBar";

interface FileUploadDisplayProperties extends WithTranslation {
    upload?: any;
}

const Wrapper = (props: any) => {
    if (props && props.upload && props.upload.open) {
        return React.cloneElement(props.children, {...props, open: props.upload.open});
    }
    return <></>
}

const FileUploadFiles = (props: FileUploadDisplayProperties) => {
    const {t} = props;
    const activeElementRef = React.useRef<HTMLLIElement>(null);

    useEffect(() => {
        if (activeElementRef.current) {
            activeElementRef.current.scrollIntoView({behavior: "smooth", block: "center"});
        }
    });

    let firstzero = -1;
    let scrollKey = "";
    if (props.upload && props.upload.filelist) {
        firstzero = Object.keys(props.upload.filelist).findIndex((v, idx, arr) => props.upload.filelist[v].progress === 0);
        if (firstzero > 0) {
            scrollKey = Object.keys(props.upload.filelist)[firstzero - 1];
        }
        else if(firstzero === -1) {
            let idx = 0;
            if (props.upload.filelist[Object.keys(props.upload.filelist)[0]].progress !== 0) {
                scrollKey = Object.keys(props.upload.filelist)[Object.keys(props.upload.filelist).length - 1];
            }
        }
    }

    return (
        <Dialog fullWidth={true} maxWidth={"md"} open={true} disableEscapeKeyDown={true} onClose={() => {}}>
            <DialogTitle sx={{textAlign: "center"}}>{t("generic.uploading")} {props.upload.zipfile}</DialogTitle>
            <DialogContent>
                <List>
                    {props.upload && props.upload.filelist && Object.keys(props.upload.filelist).map((name) => {
                        return (
                            <ListItem key={name} ref={name === scrollKey ? activeElementRef: null}>
                                <FileProgressBar sx={{width: "100%"}} text={props.upload.filelist[name].basename} progress={props.upload.filelist[name].progress}/>
                            </ListItem>
                        )
                    })}
                </List>
            </DialogContent>
        </Dialog>
    )
}

const FileUploadDisplay = (props: FileUploadDisplayProperties) => {

    return (
        <EventListener topic={"upload"} prop={"upload"}>
            <Wrapper>
                <FileUploadFiles {...props} />
            </Wrapper>
        </EventListener>
    );
}

export default styled(withTranslation()(FileUploadDisplay))((props) => {
    return {

    };
})
