import {plotColors} from "../../../Helpers/PlotColors";
import {
    Icon as MuiIcon,
    IconButton,
    InputAdornment,
    InputProps,
    Menu,
    MenuItem,
    styled,
    TextField
} from "@mui/material";
import MenuButton from "../MenuButton";
import {settings} from "../../../Machine/settings";
import {NumberValidator} from "../../../Machine/Helpers";
import {WithTranslation, withTranslation} from "react-i18next";
import {useState} from "react";
import {Heater, Tool} from "@duet3d/objectmodel";

interface HeaterBoxProps extends WithTranslation {
    className?: string;
    onTempChange: (value: string) => void;
    temp: string;
    label: string;
    temps: number[];
    extraAdornments?: JSX.Element[];
}

const HeaterSet = (props: HeaterBoxProps) => {
    const [editing, setEditing] = useState(false);
    const [temp, setTemp] = useState(props.temp);

    const stopPropagation = (e: any) => {
        switch (e.key) {
            case "ArrowDown":
            case "ArrowUp":
            case "Home":
            case "End":
                break;
            default:
                e.stopPropagation();
        }
    };

    return (
        <div className={props.className}>
            <form onSubmit={(e) => {
                if (editing) {
                    // @ts-ignore
                    props.onTempChange(e.currentTarget.elements[0].value);
                    setEditing(false);
                }
                e.preventDefault();
                e.stopPropagation();
            }}>
                <TextField
                    sx={{"*": {pointerEvents: "all !important"}}}
                    autoComplete={"off"}
                    inputProps={{
                        style: {textAlign: "right"},
                        onClick: (e) => e.stopPropagation(),
                        onMouseUp: (e) => e.stopPropagation(),
                        onTouchEnd: (e) => e.stopPropagation(),
                    }}
                    InputProps={{
                        endAdornment: [
                            <InputAdornment
                                key={"0"}
                                position={"end"}
                                onMouseUp={e => e.stopPropagation()}
                                onTouchEnd={(e) => e.stopPropagation()}
                            >
                                <MenuButton
                                    menu={(
                                        <Menu open={false}>
                                            {props.temps.map((t) => {
                                                return (
                                                    <MenuItem key={t}>{t}</MenuItem>
                                                )
                                            })}
                                        </Menu>
                                    )}
                                    onSelection={(v) => {
                                        props.onTempChange(v);
                                    }}
                                >
                                    <IconButton disableRipple={true} sx={{margin: "0", padding: "0"}}>
                                        <MuiIcon sx={{
                                            width: "18px",
                                            height: "24px"
                                        }}>keyboard_arrow_down</MuiIcon>
                                    </IconButton>
                                </MenuButton>
                            </InputAdornment>,
                            ...(props.extraAdornments || []),
                        ]
                    }}
                    variant={"standard"}
                    id="name"
                    onChange={(e) => {
                        if (NumberValidator(e.target.value, 0, 300) || !e.target.value) {
                            setEditing(true);
                            setTemp(e.target.value as string);
                        }
                    }}
                    value={editing ? temp : props.temp}
                    focused={true}
                    // autoFocus={true}
                    onBlur={(e) => {
                        if (editing) {
                            setEditing(false);
                            props.onTempChange(e.target.value);
                        }
                    }}
                    onKeyDown={stopPropagation}
                />
            </form>
        </div>
    )
}

export default styled(withTranslation()(HeaterSet))((props) => {
    return {
        flexBasis: "0",
        flexGrow: "17",
        ".MuiTextField-root": {
            "&::before": {
                display: "block",
                position: "absolute",
                content: `'${props.label}'`,
                top: "-10px",
                fontSize: "0.75rem",
                color: props.theme.palette.primary.main,
            },
        },
    };
});
