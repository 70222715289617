import {BaseConnector} from "@duet3d/connectors";
import {CancellationToken, OnProgressCallback} from "@duet3d/connectors/dist/BaseConnector";
import {atom} from "jotai";
import {enqueueSnackbar} from "notistack";
import {progressSnackbar} from "../Helpers/SnackbarMode";
import {jotaiStore} from "../index";
import {ProgressSnackbarData} from "../Helpers/ProgressSnackbar";
import {PrimitiveAtom} from "jotai/vanilla/atom";

interface FileTransferData {
    id: number;
    fileName: string;
    type?: XMLHttpRequestResponseType;
    content: string | Blob | File;
    cancellationToken?: CancellationToken;
    resolve: (value?: any) => void;
    reject: (reason: any) => void;
    onProgressCallback?: OnProgressCallback;
    silent: boolean;
}

export class FileTransfer {
    private connection: BaseConnector | undefined = undefined;
    private downloads: FileTransferData[] = []
    private uploads: FileTransferData[] = []
    private completedDownloads: FileTransferData[] = [];
    private completedUploads: FileTransferData[] = [];
    private uploading: boolean = false;
    private active: boolean = false;
    private currentSnackbar: PrimitiveAtom<ProgressSnackbarData> | undefined = undefined;

    public setConnector(connection: BaseConnector) {
        this.connection = connection;
    }

    public connector() : BaseConnector {
        return this.connection as BaseConnector;
    }

    private cancel(id: number) {
        let idx = this.downloads.findIndex((e) => e.id === id);
        if (idx > 0) {
            this.downloads.splice(idx, 1);
        }
        idx = this.uploads.findIndex((e) => e.id === id);
        if (idx > 0) {
            this.uploads.splice(idx, 1);
        }
    }

    public download(filename: string, type?: XMLHttpRequestResponseType, cancellationToken?: CancellationToken, onProgress?: OnProgressCallback, silent: boolean = false): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const fileData: FileTransferData = {
                id: Math.random(),
                resolve: resolve,
                reject: reject,
                fileName: filename,
                content: "",
                type: type,
                cancellationToken: cancellationToken,
                onProgressCallback: onProgress,
                silent: silent,
            }
            if (cancellationToken) {
                cancellationToken.cancel = () => this.cancel(fileData.id);
            }
            this.downloads.push(fileData);

            if (!this.active) {
                this.processNext();
            }
        });
    }

    public upload(filename: string, content: string | Blob | File, cancellationToken?: CancellationToken, onProgress?: OnProgressCallback, silent: boolean = false): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const fileData: FileTransferData = {
                id: Math.random(),
                resolve: resolve,
                reject: reject,
                fileName: filename,
                content: content,
                cancellationToken: cancellationToken,
                onProgressCallback: onProgress,
                silent: silent,
            }
            if (cancellationToken) {
                cancellationToken.cancel = () => this.cancel(fileData.id);
            }
            this.uploads.push(fileData);

            if (!this.active) {
                this.processNext();
            }
        });
    }

    private processNext() {
        if (!this.active) {
            this.uploading = this.uploads.length > 0;
            this.active = true;
        }
        if (this.uploading) {
            if (this.uploads.length) {
                this.nextUpload();
                return;
            }
            else {
                this.completedUploads = [];
            }
            if (this.downloads.length) {
                this.uploading = false;
                this.nextDownload();
                return;
            }
            this.active = false;
            this.currentSnackbar = undefined;
            return;
        }
        if (this.downloads.length) {
            this.nextDownload();
            return;
        }
        else {
            this.completedDownloads = [];
        }
        if (this.uploads.length) {
            this.uploading = true;
            this.nextUpload();
            return;
        }
        this.active = false;
        this.currentSnackbar = undefined;
    }

    private createNewSnackbar(): void {
        this.currentSnackbar = atom(
            {
                fileNumber: 0,
                totalFiles: 0,
                percent: 0,
            }
        );
        if (this.uploading) {
            enqueueSnackbar("dialog.fileTransfer.uploadingTitle",
                progressSnackbar(
                    "dialog.fileTransfer.uploadDoneTitle",
                    "dialog.fileTransfer.uploadFailedTitle",
                    this.currentSnackbar));
        }
        else {
            enqueueSnackbar("dialog.fileTransfer.downloadingTitle",
                progressSnackbar(
                    "dialog.fileTransfer.downloadDoneTitle",
                    "dialog.fileTransfer.downloadFailedTitle",
                    this.currentSnackbar));
        }
    }

    private nextDownload() {
        if (this.connection === undefined) {
            this.active = false;
            this.currentSnackbar = undefined;
            return;
        }
        const data = this.downloads[0];
        if (this.currentSnackbar === undefined && !data.silent) {
            this.createNewSnackbar();
        }

        this.connection.download(
            data.fileName,
            data.type,
            data.cancellationToken,
            this.progress.bind(this, data),
        )
            .then((d) => {
                if (this.downloads.length === 1) {
                    this.setProgress(100, 1, 1);
                }
                this.completedDownloads.push(data);
                this.downloads.shift();
                data.resolve(d);
                this.processNext();
            })
            .catch((reason) => {
                this.downloads.shift();
                data.reject(reason);
                this.downloads.map((data) => {
                    data.reject(reason);
                    return undefined;
                })
                this.uploads.map((data) => {
                    data.reject(reason);
                    return undefined;
                })
                this.uploads = [];
                this.downloads = [];
                this.completedUploads = [];
                this.completedDownloads = [];
                this.active = false;
                if (this.currentSnackbar) {
                    jotaiStore.set(this.currentSnackbar, {... jotaiStore.get(this.currentSnackbar), percent: -1});
                }
                this.currentSnackbar = undefined;
            })
    }

    private nextUpload() {
        if (this.connection === undefined) {
            this.active = false;
            this.currentSnackbar = undefined;
            return;
        }
        const data = this.uploads[0];

        if (this.currentSnackbar === undefined && !data.silent) {
            this.createNewSnackbar();
        }

        let bytes = 0;
        // @ts-ignore
        if (typeof data.content === "string") {
            bytes = data.content.length;
        }
        else {
            bytes = data.content.size;
        }

        this.connection.upload(
                data.fileName,
                data.content as string | Blob | File,
                data.cancellationToken,
                this.progress.bind(this, data),
            )
            .then((d) => {
                if (bytes === 0) {
                    // Ensure progress fires at least once
                    if (data.onProgressCallback) {
                        data.onProgressCallback(0, 0, 0);
                    }
                }
                if (this.uploads.length === 1) {
                    this.setProgress(100, 1, 1);
                }
                this.completedUploads.push(data);
                this.uploads.shift();
                data.resolve();
                this.processNext();
            })
            .catch((reason) => {
                this.uploads.shift();
                data.reject(reason);
                this.downloads.map((data) => {
                    data.reject(reason);
                    return undefined;
                })
                this.uploads.map((data) => {
                    data.reject(reason);
                    return undefined;
                })
                this.uploads = [];
                this.downloads = [];
                this.completedUploads = [];
                this.completedDownloads = [];
                this.active = false;
                if (this.currentSnackbar) {
                    jotaiStore.set(this.currentSnackbar, {... jotaiStore.get(this.currentSnackbar), percent: -1});
                }
                this.currentSnackbar = undefined;
            })
    }

    private progress(data: FileTransferData, loaded: number, total: number, retries: number) {
        if (this.uploading) {
            let percent = 100 - (total - loaded) * 100 / total;
            if (data.onProgressCallback !== undefined) {
                data.onProgressCallback(loaded, total, retries);
            }
            if (this.uploads.length > 1 && percent >= 100)
                percent = 99;
            this.setProgress(percent, this.uploads.length + this.completedUploads.length, this.completedUploads.length + 1);
        }
        else {
            let percent = 100 - (total - loaded) * 100 / total;
            if (data.onProgressCallback !== undefined) {
                data.onProgressCallback(loaded, total, retries);
            }
            if (this.downloads.length > 1 && percent >= 100)
                percent = 99;
            this.setProgress(percent, this.downloads.length + this.completedDownloads.length, this.completedDownloads.length + 1);
        }
    }

    private setProgress(percent: number, total: number, current: number) {
        if (this.currentSnackbar !== undefined) {
            jotaiStore.set(this.currentSnackbar, {
                percent: percent,
                totalFiles: total,
                fileNumber: current,
            } as ProgressSnackbarData)
        }
    }
}
