import {Button, Tooltip, Typography} from "@mui/material";
import React, {cloneElement, ReactElement, useContext} from "react";
import {ConnectionCtx} from "../../MachineAccessWrapper";

interface CodeButtonProps {
    code: string;
    children?: ReactElement;
    caption?: string;
    onClick?: (e: any) => void;
    disabled?: boolean;
    tooltip?: string;
}

const CodeButton = (props: CodeButtonProps): JSX.Element => {
    const connection = useContext(ConnectionCtx);

    const handleClick = (e: any) => {
        if (connection !== undefined) {
            connection.sendCode(props.code, true);
            console.log(props.code);
            if (props.onClick) {
                props.onClick(e);
            }
        }
    };

    if (props.caption !== undefined) {
        return (
            <Button disabled={props.disabled} onClick={handleClick}>
                <Typography noWrap={true}>{props.caption}</Typography>
            </Button>
        );
    }

    return (
        <>
            {props.tooltip
                ? <Tooltip title={props.tooltip}>
                    {cloneElement(props.children as ReactElement, {onClick: handleClick})}
                </Tooltip>
                : cloneElement(props.children as ReactElement, {onClick: handleClick})
            }
        </>
    );
};

export default CodeButton;
