import {styled} from "@mui/material";
import {WithTranslation, withTranslation} from "react-i18next";

interface PanelHeaderProps extends WithTranslation{
    children?: React.ReactElement | React.ReactElement[] | false;
    captionId?: string;
    caption?: string;
    panelName?: string;
    className?: string;
    extraText?: string;
}

const PanelHeader = (props: PanelHeaderProps) => {
    const {t} = props;

    return (
        <div className={`${props.className} panel-header`}>
            <div className={"header-content"}>
                <div></div>
                <div className={"header-title"}>
                    {props.captionId && t(props.captionId)}{props.caption}
                    {props.extraText && " - " + props.extraText}
                </div>
                <div>{props.children}</div>
            </div>
        </div>
    );
}

export default styled(withTranslation()(PanelHeader))((props) => {
    return {
        height: "24px",
        opacity: "0.6",
        textAlign: "center",
        userSelect: "none",
        backgroundColor: props.theme.palette.panels[props.panelName as unknown as keyof typeof props.theme.palette.panels]?.titleBar.background,
        // @ts-ignore
        color: props.theme.palette.panels[props.panelName as unknown as keyof typeof props.theme.palette.panels]?.titleBar.foreground,
        ".header-content": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            "&>div": {
                flexGrow: "1",
                ":last-of-type": {
                    textAlign: "right",
                },
            },
        },
    };
})
