import {MachineMode} from "@duet3d/objectmodel";

export enum DashboardMode {
    fff = "FFF",
    cnc = "CNC",
    default = "default",
}

export enum UnitOfMeasure {
    metric = "mm",
    imperial = "inch"
}

export enum ToolChangeMacro {
    free = "free",
    pre = "pre",
    post = "post"
}

export const toolChangeParameter = () => {
    let pParam = 0;
    if (settings.toolChangeMacros.includes(ToolChangeMacro.free)) {
        pParam |= 1;
    }
    if (settings.toolChangeMacros.includes(ToolChangeMacro.pre)) {
        pParam |= 2;
    }
    if (settings.toolChangeMacros.includes(ToolChangeMacro.post)) {
        pParam |= 4;
    }
    return (pParam === 7) ? "" : ` P${pParam}`;
}

export const settings = {
    // Poll Connector
    ajaxRetries: 2,
    updateInterval: 250, // ms
    extendedUpdateEvery: 20,
    fileTransferRetryThreshold: 358400, // 350 KiB
    crcUploads: true,
    ignoreFileTimestamps: false,
    useBinaryPrefix: true,
    dashboardMode: DashboardMode.fff,
    notificationTimeout: 5000,
    dismissErrors: false,
    delayHeaterFault: true,
    /**
     * Units to display
     */
    displayUnits: UnitOfMeasure.metric,

    /**
     * Precision of the values to display
     */
    decimalPlaces: 1,

    // REST Connector
    pingInterval: 2000, // ms
    updateDelay: 0, // ms

    // UI
    babystepAmount: 0.05, // mm
    displayedExtraTemperatures: [] as number[],
    displayedExtruders: [0, 1, 2, 3, 4, 5],
    displayedFans: [-1, 0, 1, 2],
    moveSteps: {
        // mm
        X: [100, 50, 10, 1, 0.1],
        Y: [100, 50, 10, 1, 0.1],
        Z: [50, 25, 5, 0.5, 0.05],
        default: [100, 50, 10, 1, 0.1],
    },
    moveFeedrate: 6000, // mm/min
    // toolChangeMacros: [ToolChangeMacro.free, ToolChangeMacro.pre, ToolChangeMacro.post],
    extruderAmounts: [100, 50, 20, 10, 5, 1], // mm
    extruderFeedrates: [50, 10, 5, 2, 1], // mm/s
    temperatures: {
        tool: {
            active: [250, 235, 220, 205, 195, 160, 120, 100, 0],
            standby: [210, 180, 160, 140, 0],
        },
        bed: {
            active: [110, 100, 90, 70, 65, 60, 0],
            standby: [40, 30, 0],
        },
        chamber: [90, 80, 70, 60, 50, 40, 0],
    },
    spindleRPM: [10000, 75000, 5000, 2500, 1000, 0],
    feedUnit: "mm",
    feedRateUnit: "mm/s",
    groupTools: true,
    groupBedHeaters: true,
    groupChamberHeaters: true,
    toolChangeMacros: [
        "free",
        "pre",
        "post"
    ],
};
