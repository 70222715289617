import MenuButton from "../../Components/Inputs/MenuButton";
import {Button, Divider, Menu, MenuItem} from "@mui/material";
import React from "react";

const Playground = (props: any) => {
    return (
        <MenuButton
            onSelection={(s) => {

            }}
            menu={
                <Menu open={false}>
                    <MenuItem>
                        Edit
                    </MenuItem>
                    <MenuItem>
                        Duplicate
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem>
                        Archive
                    </MenuItem>
                    <MenuItem>
                        More
                    </MenuItem>
                </Menu>
            }
        ><Button>Click</Button></MenuButton>
    );
}

export default Playground;
