import {ButtonProps, styled} from "@mui/material";
import {ConfigElementProps} from "../ConfigItem";
import {withTranslation, WithTranslation} from "react-i18next";
import {resolvePath} from "../../../Helpers/objectPath";
import {settings} from "../../../Machine/settings";
import {saveSettings} from "../../../Machine/Helpers";
import React, {useEffect, useState} from "react";

interface ConfigSwitchElementProps extends ConfigElementProps, WithTranslation {
    className?: string;
    children?: React.ReactNode;
    item: string;
}

const ConfigSwitchElement = (props: ConfigSwitchElementProps) => {
    const obj = resolvePath(props.basePath, settings);
    const [on, setOn] = useState<boolean>(obj[props.item]);

    useEffect(() => {
        console.log(settings);
    }, [obj, props.item])

    function handleClick(e: any) {
        if (on) {
            setOn(false);
            obj[props.item] = false;
            saveSettings();
        }
        else {
            setOn(true);
            obj[props.item] = true;
            saveSettings();
        }
    }

    return (
        <>
            {React.Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, {
                    onClick: handleClick,
                    checked: on,
                });
            })}
        </>
    );
}

export default styled(withTranslation()(ConfigSwitchElement))((props) => {
    return {

    };
});
