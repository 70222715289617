import {createSlice} from "@reduxjs/toolkit"
import type {PayloadAction} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

const initialState: string[] = [];

const historySlice = createSlice({
    name: "uiState",
    initialState,
    reducers: {
        addHistory(state, action: PayloadAction<string>) {
            state.push(action.payload)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            Object.assign(state, initialState);
        });
    },
});

export const {addHistory} = historySlice.actions;
export default historySlice.reducer;
