import {withTranslation} from "react-i18next";
import {Button, ButtonGroup, styled} from "@mui/material";
import {useState} from "react";
import ValueEntryDialog from "../../Dialogs/ValueEntryDialog";

interface RadioButtonBarProps {
    values: string[] | number[];
    labels?: string[];
    selected?: string | number | undefined;
    className?: string;
    onChange: (v: string | number) => void;
    onEdit?: (values: string[] | number[]) => void;
    editMode?: boolean;
    disabled?: boolean;
    title?: string;
    prompt?: string;
    validator?: (value: string) => boolean;
}

const RadioButtonBar = (props: RadioButtonBarProps) => {
    const [selected, setSelected] = useState<string | number>(props.selected || props.values[0]);
    const [clickedButton, setClickedButton] = useState<number>(0);
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <div className={props.className}>
            <ButtonGroup fullWidth={true}>
                {props.values.map((v, idx) => {
                    return (
                        <Button
                            disabled={props.editMode || props.disabled}
                            key={v}
                            onClick={(e) => {
                                if (v != selected) {
                                    // @ts-ignore
                                    setSelected(v);
                                    props.onChange(v);
                                }
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            onMouseUp={e => e.stopPropagation()}
                            className={v === selected ? "selected" : ""}
                            onContextMenu={(e) => {
                                if (props.title) {
                                    setClickedButton(idx);
                                    setDialogOpen(true);
                                }
                            }}
                        >
                            {props.labels && props.labels[idx] !== undefined ? props.labels[idx] : v}
                        </Button>
                    );
                })}
            </ButtonGroup>
            <ValueEntryDialog
                open={dialogOpen}
                value={props.values[clickedButton].toString()}
                title={props.title}
                prompt={props.prompt}
                onConfirm={(v) => {
                    setDialogOpen(false);
                    let vals = props.values;
                    const type = typeof vals[0];
                    // @ts-ignore
                    vals.splice(clickedButton, 1, (type === "string" ? v : Number(v)));
                    props.onEdit?.(vals);
                }}
                onClose={() => { setDialogOpen(false);}}
                validator={props.validator}
            />
        </div>
    );
};

export default styled(withTranslation()(RadioButtonBar))((props) => {
    return {
        ".selected": {
            backgroundColor: props.theme.palette.primary.main,
            color: props.theme.palette.primary.contrastText,
            "&:disabled": {
                opacity: "0.5",
            },
            "&:hover": {
                backgroundColor: props.theme.palette.primary.main,
                color: props.theme.palette.primary.contrastText,
            },
        },
    };
});
