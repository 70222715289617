import {
    Breadcrumbs,
    Button,
    Icon, IconButton, Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon, Menu, MenuItem,
    styled,
    Typography, useTheme
} from "@mui/material";
import {withTranslation} from "react-i18next";
import {useContext, useEffect, useRef, useState} from "react";
import {LayoutBase} from "../../../Reducers/PanelContainer";
import objectModel from "../../../Reducers/ObjectModel";
import {purge, reduxStore, useAppSelector} from "../../../ReduxStore";
import CodeButton from "../../Inputs/CodeButton";
import i18n from "../../../i18n";
import {ConnectionCtx} from "../../MachineAccessWrapper";
import {FileListItem} from "@duet3d/connectors";
import Path, {escapeFilename} from "../../../Utils/path";
import PanelHeader from "../PanelComponents/PanelHeader";
import MenuButton from "../../Inputs/MenuButton";
import loadDirectory, {DirectoryList} from "../../../Utils/loadDirectory";

const MacroPanel = (props: any): JSX.Element => {
    const {t} = props;
    const connection = useContext(ConnectionCtx);
    const macroPath = reduxStore.getState().objectModel.current.model.directories.macros;
    const [path, setPath] = useState("/");
    const [directoryList, setDirectoryList] = useState({} as DirectoryList);
    const [currentMacroPath, setCurrentMacroPath] = useState("")
    const theme = useTheme();

    useEffect(() => {
        loadDirectory(connection, currentMacroPath, path)
            .then ((dir) => {
                setPath(path);
                setDirectoryList(dir);
            })
    }, [path, connection, currentMacroPath]);

    const macroPaths = macroPath.split(";");
    // const macroPaths = ["0:/macros", "1:/macros"];
    if (currentMacroPath === "") {
        setCurrentMacroPath(macroPaths[0]);
        return <></>
    }

    const pieces = path.split("/");
    pieces.splice(0, 1);

    const StyledSpan = styled("span")``;

    return (
        <div className={`${props.className} panel-inner`}>
            <PanelHeader captionId={"panel.macro.caption"} panelName={"macro"} extraText={currentMacroPath}>
                {macroPaths.length > 1 &&
                    <MenuButton
                        onSelection={(s) => {
                            setCurrentMacroPath(s);
                        }}
                        menu={
                            <Menu open={false}>
                                {macroPaths.map((p) => {
                                    return <MenuItem key={p}>{p}</MenuItem>;
                                })}
                            </Menu>
                        }
                    >
                        <IconButton sx={{height: "20px", color: theme.palette.panels.macro.titleBar.foreground}}><Icon>folder_open</Icon></IconButton>
                    </MenuButton>
                }
            </PanelHeader>
            <div className={"panel-content"}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <StyledSpan sx={{cursor: "pointer", ":hover": {textDecoration: "underline"}}} onClick={() => setPath("/")}>/</StyledSpan>
                    <Breadcrumbs sx={{flex: "1"}} maxItems={4} itemsBeforeCollapse={2} itemsAfterCollapse={1}>
                        { pieces.reduce((acc, cur, idx) => {
                            if (idx < pieces.length - 1) {
                                const x = pieces.slice(0, idx + 1).join("/");
                                acc = acc.concat(<StyledSpan key={x} sx={{cursor: "pointer", ":hover": {textDecoration: "underline"}}} onClick={() => {
                                    setPath("/" + x);
                                }}>{cur}</StyledSpan>);
                            }
                            else {
                                acc = acc.concat(<span key={cur}>{cur}</span>);
                            }
                            return acc;
                        }, [] as JSX.Element[])}
                    </Breadcrumbs>
                </div>
                { directoryList &&
                    <div className={"scroller"}>
                        <List dense>
                            <>
                                {path !== "/" &&
                                    <ListItem>
                                        <ListItemButton onClick={() => {
                                            let pieces = path.split("/");
                                            if (pieces.length === 2) {
                                                setPath("/");
                                            }
                                            else {
                                                pieces.splice(pieces.length - 1, 1);
                                                const p = pieces.join("/");
                                                setPath(p);
                                            }
                                        }}>
                                            <ListItemIcon sx={{minWidth: "0", marginRight: "20px"}}>
                                                <Icon>arrow_circle_up</Icon>
                                            </ListItemIcon>
                                            {t("list.baseFileList.goUp")}
                                        </ListItemButton>
                                    </ListItem>
                                }
                                {directoryList.dirs && directoryList.dirs.map((f) => {
                                    return <ListItem
                                        key={f.fullPath}
                                    >
                                        <ListItemButton
                                            onClick={async () => {
                                                let p = Path.combine(path, f.name);
                                                if (!p.startsWith("/")) {
                                                    p = "/" + p; // Keep it rooted
                                                }
                                                setPath(p);
                                            }}
                                        >
                                            <ListItemIcon sx={{minWidth: "0", marginRight: "20px"}}><Icon>folder</Icon></ListItemIcon>
                                            {Path.stripMacroFilename(f.name)}
                                        </ListItemButton>
                                    </ListItem>
                                })}
                                {directoryList.files && directoryList.files.map((f) => {
                                    return <ListItem
                                        key={f.fullPath}
                                    >
                                        <ListItemButton
                                            onClick={async () => {
                                                const filename = Path.combine(currentMacroPath, path.substring(1), f.fullName);
                                                connection && await connection.sendCode(`M98 P"${escapeFilename(filename)}"`, true)
                                            }}
                                        >
                                            <ListItemIcon sx={{minWidth: "0", marginRight: "20px"}}><Icon>insert_drive_file</Icon></ListItemIcon>
                                            {f.name}
                                        </ListItemButton>
                                    </ListItem>
                                })}
                            </>
                        </List>
                    </div>
                }
            </div>
        </div>
    );
};

const MacroPanelWrapped = styled(withTranslation()(MacroPanel))((props) => {
    return {
        userSelect: "none",
        ".scroller": {
            overflowX: "hidden",
            overflowY: "auto",
            flex: "1",
        },
        ".panel-content": {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
        },
        ".MuiBreadcrumbs-separator": {
            margin: "0px 2px !important",
        }
    }
})

MacroPanelWrapped.defaultProps = {
    minW: 2,
    minH: 3,
    meta: {
        friendlyName: i18n.t('panel.macro.friendlyName'),
        description: i18n.t('panel.macro.description'),
    },
} as LayoutBase;

export default MacroPanelWrapped;
