import {CustomContentProps, SnackbarContent, useSnackbar} from "notistack";
import React, {forwardRef, useEffect} from "react";
import {LinearProgress, styled, Typography, useTheme} from "@mui/material";
import {Atom, useAtom} from "jotai";
import {withTranslation, WithTranslation} from "react-i18next";
import i18n from "../../i18n";

interface ProgressSnackbarProps extends CustomContentProps {
    className?: string;
    atom: Atom<ProgressSnackbarData>;
    successMessage: string;
    errorMessage: string;
}

export interface ProgressSnackbarData {
    percent: number;
    totalFiles: number;
    fileNumber: number;
}

const ProgressSnackbar = forwardRef<HTMLDivElement, ProgressSnackbarProps>(({ id, ...props }, ref) => {
    const theme = useTheme();
    const {atom, successMessage, errorMessage} = props;
    // TODO: Implement!
    const [{percent, fileNumber, totalFiles}] = useAtom(atom);
    const {closeSnackbar} = useSnackbar();

    const t = i18n.t;

    useEffect(() => {
        if (percent >= 100) {
            setTimeout(() => {
                closeSnackbar(id);
            }, 3000)
        }
    }, [closeSnackbar, id, percent])

    const classes = `${props.className} notistack-MuiContent ${
        percent === -1
            ? "notistack-MuiContent-error"
            : percent >= 100
                ? "notistack-MuiContent-success"
                : "notistack-MuiContent-progress"
    }`;

    return (
        <SnackbarContent className={classes} ref={ref}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
                {
                    percent >= 100
                        ? props.iconVariant["success"]
                        : percent === -1
                            ? props.iconVariant["error"]
                            : props.iconVariant["info"]
                }
                <div style={{display: "flex", flexDirection: "column", alignItems: "stretch", width: "100%"}}>
                    <div><Typography>
                        {
                            percent >= 100
                                ? t(successMessage)
                                : percent === -1
                                    ? t(errorMessage)
                                    : t(props.message as string, {0: fileNumber, 1: totalFiles, 2: Math.floor(percent)})
                        }
                    </Typography></div>
                    {percent >= 0 && percent < 100 &&
                        <div><LinearProgress sx={{backgroundColor: theme.palette.info.light, "& .MuiLinearProgress-bar": {backgroundColor: theme.palette.info.contrastText}}} variant={"determinate"} value={Math.min(percent, 100)} /></div>
                    }
                </div>
                {props.action &&
                    <div>
                        {
                            // @ts-ignore
                            percent === -1 && React.cloneElement(props.action(id), {})
                        }
                    </div>
                }
            </div>
        </SnackbarContent>
    );
})

export default styled(ProgressSnackbar)((props) => {
    return {
        "&.notistack-MuiContent-progress": {
            backgroundColor: props.theme.palette.info.main,
            color: props.theme.palette.info.contrastText,
        },
        "&.notistack-MuiContent-error": {
            backgroundColor: props.theme.palette.error.main,
            color: props.theme.palette.error.contrastText,
        },
        "&.notistack-MuiContent-success": {
            backgroundColor: props.theme.palette.success.main,
            color: props.theme.palette.success.contrastText,
        },
        "& .notistack-MuiCloseButton": {
        },
        padding: "6px 16px 6px 20px",
        borderRadius: "4px",
        minHeight: "48px",
    };
});
