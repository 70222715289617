import {WithTranslation, withTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, styled} from "@mui/material";

interface ConfirmDialogProps extends WithTranslation {
    className?: string;
    title?: string;
    question: string;
    onConfirm: () => void;
    onClose: () => void;
    open: boolean;
    yesno?: boolean;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
    const {t} = props;

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            {props.title &&
                <DialogTitle>{props.title}</DialogTitle>
            }
            <DialogContent>{props.question}</DialogContent>
            <DialogActions>
                {props.yesno
                    ? <><Button variant={"outlined"} onClick={props.onClose}>{t("main:generic.no")}</Button>
                    <Button onClick={props.onConfirm} autoFocus>{t("main:generic.yes")}</Button></>
                    : <><Button variant={"outlined"} onClick={props.onClose}>{t("main:generic.cancel")}</Button>
                    <Button onClick={props.onConfirm} autoFocus>{t("main:generic.ok")}</Button></>
                }
            </DialogActions>
        </Dialog>
    );
}

export default styled(withTranslation()(ConfirmDialog))((props) => {
    return {

    };
})
