import {closeSnackbar, OptionsObject, SnackbarKey, CustomContentProps, SnackbarContent} from "notistack";
import SnackbarCloseButton from "../../Components/Misc/SnackbarCloseButton";
import {history} from "../../App";
import {forwardRef} from "react";
import {Atom} from "jotai";
import {ProgressSnackbarData} from "../ProgressSnackbar";
import {settings} from "../../Machine/settings";

export const snackbarError = (): OptionsObject => {
    return {
        variant: "error",
        persist: !settings.dismissErrors,
        action: (key: SnackbarKey) => {
            if (!settings.dismissErrors)
                return <SnackbarCloseButton snackbarKey={key} variant="error" />;
            return undefined;
        },
        autoHideDuration: settings.notificationTimeout,
    };
};
export const snackbarSuccess = (): OptionsObject => {return {variant: "success", autoHideDuration: settings.notificationTimeout}};
export const snackbarInfo = (): OptionsObject => {return {variant: "info", autoHideDuration: settings.notificationTimeout}};
export const snackbarWarning = (): OptionsObject => {return {variant: "warning", autoHideDuration: settings.notificationTimeout}};

export const progressSnackbar = (
    success: string,
    error: string,
    atom: Atom<ProgressSnackbarData>,
    otherOptions: OptionsObject = {} as OptionsObject,
): OptionsObject<"progress"> & {atom: Atom<number>, successMessage: string, errorMessage: string} => {
    // @ts-ignore
    return snackbarWithClose(true, {...otherOptions, variant: "progress", atom: atom, successMessage: success, errorMessage: error});
}
export const snackbarWithClose = (
    persist: boolean,
    otherOptions: OptionsObject = {} as OptionsObject,
): OptionsObject => {
    return {
        ...otherOptions,
        persist: persist,
        action: (key) => (
            <>
                {otherOptions.action && typeof otherOptions.action === "function" && otherOptions.action(key)}
                <SnackbarCloseButton snackbarKey={key} />
            </>
        ),
    };
};
export const snackbarWithLink = (
    title: string,
    link: string,
    otherOptions: OptionsObject = {} as OptionsObject,
): OptionsObject => {
    return {
        ...otherOptions,
        action: (key) => (
            <div>
                <>
                    <span
                        style={{cursor: "pointer", textDecoration: "underline"}}
                        onClick={(e) => {
                            closeSnackbar(key);
                            if (link.startsWith("/")) {
                                history.push(link);
                            } else {
                                window.location.href = link;
                            }
                        }}
                    >
                        {title}
                    </span>
                    {otherOptions.action && typeof otherOptions.action === "function" && otherOptions.action(key)}
                </>
            </div>
        ),
    };
};
