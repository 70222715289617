import {styled} from "@mui/material";
import {withTranslation} from "react-i18next";
import {reduxStore, useAppSelector} from "../../../ReduxStore";
import {MachineMode} from "@duet3d/objectmodel";
import {StatusType} from "../../../Machine/ModelEnums";

const StatusLabel = (props: any) => {
    const objectModel = useAppSelector(state => state.objectModel);

    const {t} = props;

    const statusText = () => {
        let type: string = objectModel.current.model.state.status;
        if (!objectModel.current.model.state.status) {
            type = 'unknown';
        } else if (objectModel.current.model.state.status === StatusType.processing && objectModel.current.model.state.machineMode === MachineMode.fff) {
            type = 'printing';
        }
        return t(`generic.status.${type}`);
    }

    return (
        <span className={props.className}>
            {statusText()}
        </span>
    );
}

export default styled(withTranslation()(StatusLabel))((props) => {

    return {
        paddingLeft: "8px",
        paddingRight: "8px",
        borderRadius: "6px",
        backgroundColor: props.theme.palette.status[
            reduxStore.getState().objectModel.current.model.state.status as keyof typeof props.theme.palette.status
            ].background,
        color: props.theme.palette.status[
            reduxStore.getState().objectModel.current.model.state.status as keyof typeof props.theme.palette.status
            ].foreground,
    };
});
