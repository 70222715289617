import {styled} from "@mui/material";
import {ConfigElementProps} from "../ConfigItem";
import {withTranslation, WithTranslation} from "react-i18next";
import {resolvePath} from "../../../Helpers/objectPath";
import {settings} from "../../../Machine/settings";
import {saveSettings} from "../../../Machine/Helpers";
import React, {useState} from "react";

interface ConfigSelectElementProps extends ConfigElementProps, WithTranslation {
    className?: string;
    children?: React.ReactNode;
    item: string;
}

const ConfigSelectElement = (props: ConfigSelectElementProps) => {
    const obj = resolvePath(props.basePath, settings);
    const [currentValue, setCurrentValue] = useState<string>(obj[props.item]);

    return (
        <>
            {React.Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, {
                    value: currentValue,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                        setCurrentValue(event.target.value);
                        obj[props.item] = event.target.value;
                        saveSettings();
                    }
                });
            })}
        </>
    );
}

export default styled(withTranslation()(ConfigSelectElement))((props) => {
    return {

    };
});
