import {ObjectModel} from "@duet3d/objectmodel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ObjectModelWrap {
    model: ObjectModel;
}

export interface ObjectModelState {
    current: ObjectModelWrap;
}

const initialState: ObjectModelState = {
    current: {
        model: new ObjectModel(),
    },
};

const objectModelSlice = createSlice({
    name: "objectModel",
    initialState,
    reducers: {
        setObjectModel(state, action: PayloadAction<ObjectModelState>) {
            state.current = action.payload.current;
        },
    },
});

export const {setObjectModel} = objectModelSlice.actions;
export default objectModelSlice.reducer;
