import {Axis} from "@duet3d/objectmodel";
import {StatusType} from "./ModelEnums";
import {UiState} from "../Reducers/UiState";
import {ObjectModelState} from "../Reducers/ObjectModel";
import {uploadController} from "../Components/MachineAccessWrapper";
import {UploadType} from "../Utils/UploadController";
import {settings} from "./settings";

export const canHome = (uiState: UiState, objectModel: ObjectModelState, axis: Axis) => {
    return (
        !uiState.frozen &&
        objectModel.current.model.state.status !== StatusType.pausing &&
        objectModel.current.model.state.status !== StatusType.processing &&
        objectModel.current.model.state.status !== StatusType.resuming
    );
};

export const canMove = (uiState: UiState, objectModel: ObjectModelState, axis: Axis) => {
    return (axis.homed || !objectModel.current.model.move.noMovesBeforeHoming) && canHome(uiState, objectModel, axis);
};

export const getHomeCode = (axis: Axis) => {
    return `G28 ${/[a-z]/.test(axis.letter) ? "'" : ""}${axis.letter.toUpperCase()}`;
};

export const saveSettings = () => {
    uploadController.upload([{
        filename: "mcui-settings.json",
        content: JSON.stringify(settings, undefined, 2),
    }], "/", UploadType.system, true)
}

export const NumberValidator = (v: string, min?: number, max?: number) => {
    if (!v.match(/^\d+$/) && v !== "") {
        return false;
    }
    const n = Number(v);
    if (min !== undefined && n < min) {
        return false;
    }
    return !(max !== undefined && n > max);

}
