import {WithTranslation, withTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, TextField, useTheme} from "@mui/material";
import {useEffect, useState} from "react";

interface ValueEntryDialogProps extends WithTranslation {
    className?: string;
    title?: string;
    prompt?: string;
    onConfirm: (value: string) => void;
    onClose: () => void;
    open: boolean;
    value: string;
    validator?: (value: string) => boolean;
}

const ValueEntryDialog = (props: ValueEntryDialogProps) => {
    const {t} = props;
    const theme = useTheme();
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(props.value);
    }, [props.open, props.value])

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            {props.title &&
                <DialogTitle>{props.title}</DialogTitle>
            }
            <form onSubmit={(e) => {
                props.onConfirm(value);
                e.preventDefault();
                e.stopPropagation();
            }}>
                <DialogContent>
                    <div>{props.prompt}</div>
                    <TextField
                        sx={{
                            [theme.breakpoints.down("sm")]: {
                                width: "180px",
                            },
                            [theme.breakpoints.up("sm")]: {
                                width: "480px",
                            },
                        }}
                        className={"text-entry"}
                        variant={"standard"}
                        id="name"
                        onChange={(e) => {
                            if (props.validator ? props.validator(e.target.value) : true) {
                                setValue(e.target.value);
                            }
                        }}
                        value={value}
                        focused={true}
                        autoFocus={true}
                        onBlur={() => { // iOS workaround to reset unwanted body scroll
                            window.scrollTo(0, 0);
                            document.body.scrollTop = 0;
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="button" variant={"outlined"} onClick={props.onClose}>{t("generic.cancel")}</Button>
                    <Button type="submit">{t("generic.ok")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default styled(withTranslation()(ValueEntryDialog))((props) => {
    return {

    };
})
