import {WithTranslation, withTranslation} from "react-i18next";
import {Button, ButtonGroup, styled} from "@mui/material";
import {useState} from "react";

interface MixButtonBarProps extends WithTranslation {
    values: (number | "mix")[];
    selected: (number | "mix")[];
    className?: string;
    onChange: (v: (number | "mix")[]) => void;
    editMode?: boolean;
    disabled?: boolean;
}

const MixButtonBar = (props: MixButtonBarProps) => {
    const [selected, setSelected] = useState<(number | "mix")[]>(props.selected);
    const {t} = props;

    return (
        <div className={props.className}>
            <ButtonGroup fullWidth={true}>
                {props.values.map((v) => {
                    return (
                        <Button
                            disabled={props.editMode || props.disabled}
                            key={v}
                            onClick={(e) => {
                                if (selected.includes(v)) {
                                    if (v !== "mix") {
                                        const newValue = selected.filter((e) => e !== v)
                                        if (newValue.length > 0) {
                                            setSelected(newValue);
                                            props.onChange(newValue);
                                        }
                                    }
                                }
                                else {
                                    let newValue: (number | "mix")[];
                                    if (v === "mix") {
                                        newValue = ["mix"];
                                    }
                                    else {
                                        newValue = selected.filter((e) => e !== "mix").concat(v);
                                    }
                                    setSelected(newValue);
                                    props.onChange(newValue);
                                }
                            }}
                            className={props.selected.includes(v) ? "selected" : ""}
                        >
                            {v === "mix"
                                ? t("panel.extrude.mix")
                                : `E${v}`
                            }
                        </Button>
                    );
                })}
            </ButtonGroup>
        </div>
    );
};

export default styled(withTranslation()(MixButtonBar))((props) => {
    return {
        ".selected": {
            backgroundColor: props.theme.palette.primary.main,
            color: props.theme.palette.primary.contrastText,
            "&:disabled": {
                opacity: "0.5",
            },
            "&:hover": {
                backgroundColor: props.theme.palette.primary.main,
                color: props.theme.palette.primary.contrastText,
            },
        },
    };
});
