import {createSlice} from "@reduxjs/toolkit"
import type {PayloadAction} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

const initialState: number[][] = [];

const temperatureSlice = createSlice({
    name: "uiState",
    initialState,
    reducers: {
        addTemperatures(state, action: PayloadAction<number[]>) {
            state.push(action.payload);
            if (state.length > 1000) {
                state.shift();
            }
        },
        clearTemperatures(state) {
            Object.assign(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            Object.assign(state, initialState);
        });
    },
});

export const {addTemperatures, clearTemperatures} = temperatureSlice.actions;
export default temperatureSlice.reducer;
