import {withTranslation} from "react-i18next";
import {styled, Typography} from "@mui/material";
import {AnalogSensor} from "@duet3d/objectmodel";
import {useAppSelector} from "../../../ReduxStore";
import ExtraSensorControl, {ExtraSensor} from "../../Inputs/ExtraSensorControl";

const ExtraSensorsList = (props: any) => {
    const objectModelState = useAppSelector(state => state.objectModel);
    const model = objectModelState.current.model;

    const heaters = model.heat.heaters;
    const extraSensors = model.sensors.analog
            .map((sensor, index) => ({
                sensor,
                index
            }))
            .filter(({ sensor, index }) => (sensor !== null) && !heaters.some(heater => (heater !== null) && (heater.sensor === index))) as Array<ExtraSensor>;

    if (extraSensors.length === 0) {
        return (
            <div className={props.className}>
                <Typography align={"center"}>No extra sensors found.</Typography>
            </div>
        );
    }

    return (
        <div className={props.className}>
            {extraSensors.map((sensor, index) => {
                return (
                    <ExtraSensorControl key={index} sensor={sensor} index={index} />
                );
            })}
        </div>
    )
}

export default styled(withTranslation()(ExtraSensorsList))((props) => {
    return {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
    };
});
