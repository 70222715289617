import {withTranslation} from "react-i18next";
import {LayoutBase} from "../../../Reducers/PanelContainer";
import {Button, Divider, Icon, Menu, MenuItem, styled} from "@mui/material";
import {reduxStore, useAppDispatch, useAppSelector} from "../../../ReduxStore";
import React, {useContext, useState} from "react";
import i18n from "../../../i18n";
import PanelHeader from "../PanelComponents/PanelHeader";
import ToolsList from "../../Misc/ToolsList";
import MenuButton from "../../Inputs/MenuButton";
import BedsList from "../../Misc/BedsList";
import {setUiState} from "../../../Reducers/UiState";
import RadioButtonBar from "../../Inputs/RadioButtonBar";
import HeaterSet from "../../Inputs/HeaterSet";
import {settings} from "../../../Machine/settings";
import {HeaterState} from "@duet3d/objectmodel";
import {DisconnectedError} from "@duet3d/connectors";
import {ConnectionCtx} from "../../MachineAccessWrapper";
import ExtraSensorsList from "../../Misc/ExtraSensorsList";

const ToolPanel = (props: any) => {
    const uiState = useAppSelector(state => state.uiState);
    const dispatch = useAppDispatch();
    const [selectedHeaters, setSelectedHeaters] = useState(0);
    const {t} = props;
    const modelHolder = reduxStore.getState().objectModel;
    const [anyHeaterOn, setAnyHeaterOn] = useState(false);
    const connection = useContext(ConnectionCtx);

    // Turn everything off
    const canTurnEverythingOff = () => {
        const heaters = modelHolder.current.model.heat.heaters, tools = modelHolder.current.model.tools;
        const bedHeaters = modelHolder.current.model.heat.bedHeaters, chamberHeaters = modelHolder.current.model.heat.chamberHeaters;
        return (!uiState.frozen && (
            tools.some((tool) => (tool !== null) &&
                tool.heaters.some(toolHeater => (toolHeater >= 0) && (toolHeater < heaters.length) &&
                    (heaters[toolHeater] !== null) && (heaters[toolHeater]!.state !== HeaterState.off)
                )
            ) ||
            bedHeaters.some((bedHeater) => (bedHeater >= 0) && (bedHeater < heaters.length) &&
                (heaters[bedHeater] !== null) && (heaters[bedHeater]!.state !== HeaterState.off)
            ) ||
            chamberHeaters.some(chamberHeater => (chamberHeater >= 0) && (chamberHeater < heaters.length) &&
                (heaters[chamberHeater] !== null) && (heaters[chamberHeater]!.state !== HeaterState.off)
            )));
    };

    const turnEverythingOff = async () => {
        let code = "";
        for (const tool of modelHolder.current.model.tools) {
            if ((tool !== null) && (tool.heaters.length > 0)) {
                code += `M568 P${tool.number} A0\n`;
            }
        }
        modelHolder.current.model.heat.bedHeaters.forEach((bedHeater, index) => {
            if (bedHeater >= 0 && bedHeater < modelHolder.current.model.heat.heaters.length) {
                code += `M140 P${index} S-273.15\n`;
            }
        });
        modelHolder.current.model.heat.chamberHeaters.forEach((chamberHeater, index) => {
            if (chamberHeater >= 0 && chamberHeater < modelHolder.current.model.heat.heaters.length) {
                code += `M141 P${index} S-273.15\n`;
            }
        });

        try {
            await connection.sendCode(code, false);
        } catch (e) {
            if (!(e instanceof DisconnectedError)) {
                // log(LogType.error, i18n.t("error.turnOffEverythingFailed"), getErrorMessage(e));
            }
        }
    }

    const controlHeaters = async (inputValue: number, active: boolean) => {
        let code = "";

        if (selectedHeaters === 0) { // Tools
            for (const tool of modelHolder.current.model.tools) {
                if (tool && tool.heaters.length > 0) {
                    const temps = tool.heaters.map(() => inputValue, this).join(':');
                    code += `M568 P${tool.number} ${active ? 'S' : 'R'}${temps}\n`;
                }
            }
        }
        if (selectedHeaters === 1) { // Beds
            for (let i = 0; i < modelHolder.current.model.heat.bedHeaters.length; i++) {
                const bedHeater = modelHolder.current.model.heat.bedHeaters[i];
                if (bedHeater >= 0 && bedHeater <= modelHolder.current.model.heat.heaters.length) {
                    code += `M140 P${i} ${active ? 'S' : 'R'}${inputValue}\n`;
                }
            }
        }
        if (selectedHeaters === 2) { // Chambers
            for (let i = 0; i < modelHolder.current.model.heat.chamberHeaters.length; i++) {
                const chamberHeater = modelHolder.current.model.heat.chamberHeaters[i];
                if (chamberHeater >= 0 && chamberHeater <= modelHolder.current.model.heat.heaters.length) {
                    code += `M141 P${i} ${active ? 'S' : 'R'}${inputValue}\n`;
                }
            }
        }
        if (code !== "") {
            await connection.sendCode(code, false);
        }
    }

    return (
        <div className={`${props.className} panel-inner`}>
            <PanelHeader captionId={"panel.tools.caption"} panelName={"tools"}>
                <MenuButton
                    onOpen={() => {
                        setAnyHeaterOn(canTurnEverythingOff);
                    }}
                    menu={
                        <Menu open={true}>
                            {[
                                <MenuItem key={"0"} value={0}><Icon sx={{marginRight: "5px"}}>{!uiState.toolsExtrasShown ? "check" : "blank"}</Icon>Show heaters</MenuItem>,
                                <MenuItem key={"1"} value={1}><Icon sx={{marginRight: "5px"}}>{uiState.toolsExtrasShown ? "check" : "blank"}</Icon>Show extra</MenuItem>,
                                <Divider key={"2"}  />,
                                <MenuItem
                                    key={"3"}
                                    disableTouchRipple={true}
                                >
                                    <Button
                                        color={"error"}
                                        disabled={!anyHeaterOn}
                                        sx={{width: "100%", pointerEvents: "all !important", "*": {pointerEvents: "all !important"}}}
                                        onClick={async (e) => {
                                            let target = e.target as HTMLElement;
                                            while (target.tagName !== "LI") {
                                                target = target.parentElement as HTMLElement;
                                            }
                                            const ee = new MouseEvent("mouseup", {"view": window, "bubbles": true, cancelable: true});
                                            // @ts-ignore
                                            target.dispatchEvent(ee);
                                            await turnEverythingOff();
                                        }}
                                        onMouseUp={(e) => e.stopPropagation()}
                                        onTouchEnd={(e) => e.stopPropagation()}
                                    >
                                        {t("panel.tools.turnEverythingOff")}
                                    </Button>
                                </MenuItem>,
                                <MenuItem key={"4"} disableTouchRipple={true}>
                                    <RadioButtonBar
                                        selected={selectedHeaters}
                                        sx={{pointerEvents: "all !important", "*": {pointerEvents: "all !important"}}}
                                        values={[0, 1, 2]}
                                        labels={[
                                            t("panel.tools.tool", {0: ""}).trim(),
                                            t("panel.tools.bed", {0: ""}).trim(),
                                            t("panel.tools.chamber", {0: ""}).trim(),
                                        ]}
                                        onChange={function(v: string | number): void {
                                            setSelectedHeaters(v as number);
                                        }}
                                    />
                                </MenuItem>,
                                // <MenuItem key={Math.random()} value={2} disableTouchRipple={true}>
                                //     <form onSubmit={(e) => {
                                //         e.stopPropagation();
                                //         e.preventDefault();
                                //         const ee = new MouseEvent("mouseup", {"view": window, "bubbles": true, cancelable: true});
                                //         // @ts-ignore
                                //         e.target.parentElement.dispatchEvent(ee);
                                //     }}>
                                //         <TextField
                                //             inputProps={{
                                //                 onClick: (e) => e.stopPropagation(),
                                //                 onMouseUp: (e) => e.stopPropagation(),
                                //                 onTouchEnd: (e) => e.stopPropagation(),
                                //             }}
                                //             InputProps={{
                                //                 endAdornment: (
                                //                     <InputAdornment position={"end"}>
                                //                         <IconButton>
                                //                             <MdiIcon path={mdiArrowRightThinCircleOutline} size={"1em"}/>
                                //                         </IconButton>
                                //                     </InputAdornment>
                                //                 ),
                                //             }}
                                //             sx={{"*": {pointerEvents: "all !important"}}}
                                //             defaultValue={""}
                                //             variant={"standard"}
                                //             label={"Active temp"}
                                //             onKeyDown={stopPropagation}
                                //             onChange={(v) => {
                                //
                                //             }}
                                //         >
                                //         </TextField>
                                //     </form>
                                // </MenuItem>,
                                // <MenuItem key={Math.random()} value={3} disableTouchRipple={true}>
                                //     <form onSubmit={(e) => {
                                //         e.stopPropagation();
                                //         e.preventDefault();
                                //         const ee = new MouseEvent("mouseup", {"view": window, "bubbles": true, cancelable: true});
                                //         // @ts-ignore
                                //         e.target.parentElement.dispatchEvent(ee);
                                //     }}>
                                //         <TextField
                                //             inputProps={{
                                //                 onClick: (e) => e.stopPropagation(),
                                //                 onMouseUp: (e) => e.stopPropagation(),
                                //                 onTouchEnd: (e) => e.stopPropagation(),
                                //             }}
                                //             InputProps={{
                                //                 endAdornment: (
                                //                     <InputAdornment position={"end"}>
                                //                         <IconButton>
                                //                             <MdiIcon path={mdiArrowRightThinCircleOutline} size={"1em"}/>
                                //                         </IconButton>
                                //                     </InputAdornment>
                                //                 ),
                                //             }}
                                //             sx={{"*": {pointerEvents: "all !important"}}}
                                //             defaultValue={""}
                                //             variant={"standard"}
                                //             label={"Standby temp"}
                                //             onKeyDown={stopPropagation}
                                //             onChange={(v) => {
                                //
                                //             }}
                                //         >
                                //         </TextField>
                                //     </form>
                                // </MenuItem>,
                                <MenuItem
                                    key={20} disableTouchRipple={true} disableRipple={true}
                                >
                                    <HeaterSet
                                        sx={{paddingTop: "8px"}}
                                        label={t("panel.tools.setActiveTemperatures")}
                                        onTempChange={async (value: string) => {
                                            await controlHeaters(Number(value), true);
                                        }}
                                        temp={""}
                                        temps={selectedHeaters === 0
                                            ? settings.temperatures.tool.active
                                            : selectedHeaters === 1
                                                ? settings.temperatures.bed.active
                                                : settings.temperatures.chamber
                                        }
                                    />
                                </MenuItem>,
                                <MenuItem
                                    key={21} disableTouchRipple={true} disableRipple={true}
                                >
                                    <HeaterSet
                                        sx={{paddingTop: "8px"}}
                                        label={t("panel.tools.setStandbyTemperatures")}
                                        onTempChange={async (value: string) => {
                                            await controlHeaters(Number(value), false);
                                        }}
                                        temp={""}
                                        temps={selectedHeaters === 0
                                            ? settings.temperatures.tool.standby
                                            : selectedHeaters === 1
                                                ? settings.temperatures.bed.standby
                                                : settings.temperatures.chamber
                                        }
                                    />
                                </MenuItem>
                            ]}
                        </Menu>
                    }
                    onSelection={(text, target) => {
                        const v = target && target.value !== undefined ? target.value : -1;
                        if (v !== -1) {
                            switch (v) {
                                case 0:
                                    dispatch(setUiState({...uiState, toolsExtrasShown: false}));
                                    break;
                                case 1:
                                    dispatch(setUiState({...uiState, toolsExtrasShown: true}));
                                    break;
                                case 2: {
                                    const input = target.getElementsByTagName("input")[0];
                                    const value = input.value;
                                    break;
                                }
                                case 3: {
                                    const input = target.getElementsByTagName("input")[0];
                                    const value = input.value;
                                    break;
                                }
                            }
                        }
                    }}
                >
                    <Button
                        disableElevation={true}
                        disableTouchRipple={true}
                        disableRipple={true}
                        variant={"contained"}
                        sx={{padding: "0 4px", margin: "0 5px 0 0", position: "relative", top: "-2px"}}
                    >
                        Options
                    </Button>
                </MenuButton>
            </PanelHeader>
            <div className={"panel-content"}>
                { uiState.toolsExtrasShown
                    ? <>
                        <ExtraSensorsList />
                    </>
                    : <>
                        <ToolsList />
                        <BedsList type={"bed"}/>
                        <BedsList type={"chamber"}/>
                    </>
                }
            </div>
        </div>
    );
};

const ToolPanelWrapped = styled(withTranslation()(ToolPanel))((props) => {
    return {
        containerType: "inline-size",
        containerName: "ToolPanel",
        display: "flex",
        flexDirection: "column",
        "panel-content": {
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            justifyContent: "start",
            alignItems: "stretch",
        },
    } as React.CSSProperties as unknown as any;
});

ToolPanelWrapped.defaultProps = {
    minW: 2,
    minH: 8,
    meta: {
        friendlyName: i18n.t('panel.tools.friendlyName'),
        description: i18n.t('panel.tools.panelDescription'),
    }
} as LayoutBase;

export default ToolPanelWrapped;
