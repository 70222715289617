import {styled} from "@mui/material";
import {withTranslation} from "react-i18next";

const ConfigGroup = (props: any) => {
    return (
        <div className={props.className}>
            <div className={"caption"}>{props.caption}</div>
            {props.children}
        </div>
    )
}

export default styled(withTranslation()(ConfigGroup))((props) => {
    return {
        position: "relative",
        border: "1px solid grey",
        padding: "5px 10px",
        borderRadius: "8px",
        flex: "1",
        display: "flex",
        flexDirection: "column",
        flexBasis: "0",
        minWidth: "320px",
        maxWidth: "32%",
        ".caption": {
            fontHeight: "12pt",
            lineHeight: "12pt",
            position: "absolute",
            top: "-10px",
            left: "15px",
            backgroundColor: props.theme.palette.background.default,
            padding: "0px 5px",
        }
    };
})
