import {configureStore} from "@reduxjs/toolkit";
import {persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./Reducers/Root";
import {persistStore} from "redux-persist";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import objectModel from "./Reducers/ObjectModel";

const persistConfig = {
    key: "root",
    version: 1,
    storage: storage,
    keyPrefix: "",
    blacklist: ["objectModel", "temperature", "terminal"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const reduxStore = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, "objectModel/setObjectModel"],
                ignoredPaths: ["objectModel.current"],
            },
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reduxStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof reduxStore.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(reduxStore);
export const purge = () => persistor.purge();
