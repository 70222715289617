import {withTranslation} from "react-i18next";
import {LayoutBase} from "../../../Reducers/PanelContainer";
import {styled} from "@mui/material";
import {reduxStore, useAppSelector} from "../../../ReduxStore";
import i18n from "../../../i18n";
import PanelHeader from "../PanelComponents/PanelHeader";
import Plot from "react-plotly.js";
// @ts-ignore
import Plotly from "plotly.js/dist/plotly.js";
import {useState} from "react";
import {useResizeDetector} from "react-resize-detector";
import {useTheme} from "@mui/material";
import {AnalogSensor, Heater} from "@duet3d/objectmodel";
import {plotColors} from "../../../Helpers/PlotColors";
import {Data} from "plotly.js";
import {settings} from "../../../Machine/settings";
import temperature from "../../../Reducers/Temperature";

const TemperaturePlotPanel = (props: any) => {
    const temperatures = useAppSelector(state => state.temperature);
    const {width, height, ref} = useResizeDetector();
    const theme = useTheme();

    const graphWidth = (width || 0);
    const zoomout = 1;
    const axisStepsPerDatapoint = 1;
    const totalDatapoints = Math.floor(graphWidth / axisStepsPerDatapoint * zoomout);
    const datapointsPerMinute= 30;
    const ticks = Array(Math.floor(totalDatapoints / datapointsPerMinute));
    const tickbase = Array.apply(0, ticks) as number[];
    const tickvals = tickbase.map((_, idx) =>
        idx * datapointsPerMinute + new Date(Date.now() + idx * 60 * 1000).getSeconds() / (60 / datapointsPerMinute));
    const ticktext = tickbase.map((_, idx) =>
        new Date(Date.now() - idx * 60 * 1000).toTimeString().substring(0, 5))

    const model = reduxStore.getState().objectModel.current.model;

    interface SensorData {
        sensor: AnalogSensor | null;
        index: number;
        heater: Heater | null;
        temperature: number[];
    }

    const sensors = model.sensors.analog
        .map((s, idx) => ({sensor: s, index: idx, heater: null, temperature: []} as SensorData))
        .filter(sensor => sensor.sensor !== null)
        .reduce((acc, cur, idx) => {
            const heater = model.heat.heaters.find((heater) => heater?.sensor === idx);
            acc.push({
                ...cur,
                heater: heater !== undefined ? heater : null,
                temperature: (temperatures.length > totalDatapoints + 1 ? temperatures.slice(temperatures.length - totalDatapoints, temperatures.length).reverse() : temperatures.slice().reverse()).map(v => v[idx])
            });
        return acc;
        }, [] as SensorData[]);

    const x = Array.apply(0, Array(totalDatapoints + 1)).map((_, idx) => idx);

    return (
        <div className={`${props.className} panel-inner`}>
            <PanelHeader captionId={"panel.temperature.caption"} panelName={"temperature"} />
            <div ref={ref} className={"panel-content"}>
                <Plot
                    style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0}}
                    divId={"graph"}
                    data={
                        temperatures.length > 0
                            ? sensors.reduce((acc, cur) => {
                                if (cur.heater === null && !settings.displayedExtraTemperatures.includes(cur.index)) {
                                    return acc;
                                }
                                acc.push({
                                    name: cur.sensor!.name || "",
                                    x: x,
                                    y: cur.temperature,
                                    type: "scatter",
                                    mode: "lines",
                                    line: {
                                        color: plotColors[cur.index],
                                    },
                                    hoverinfo: "none",
                                });
                                return acc;
                            }, [] as Data[])
                            : []
                    }
                    layout={{
                        plot_bgcolor: theme.palette.background.paper,
                        paper_bgcolor: theme.palette.background.paper,
                        margin: {
                            b: 32,
                            r: 10,
                            t: 0,
                            l: 32,
                        },
                        width: width,
                        height: height,
                        showlegend: true,
                        legend: {
                            x: 0,
                            y: 1,
                            xanchor: "left",
                        },
                        yaxis: {
                            showgrid: true,
                            fixedrange: true,
                            range: [-15, 280],
                            gridcolor: theme.palette.text.secondary + "40",
                            zerolinecolor: theme.palette.text.secondary + "40",
                            zerolinewidth: 1,
                        },
                        xaxis: {
                            showgrid: true,
                            fixedrange: true,
                            range: [(width || 0) / axisStepsPerDatapoint * zoomout, 0],
                            gridcolor: theme.palette.text.secondary + "40",
                            zerolinecolor: theme.palette.text.secondary + "40",
                            zerolinewidth: 1,
                            tickangle: -45,
                            automargin: true,
                            tickvals: tickvals,
                            ticktext: ticktext,
                        }
                    }}
                    config={{
                        displayModeBar: false,
                    }}
                ></Plot>
            </div>
        </div>
    );
};

const TemperturePlotPanelWrapped = styled(withTranslation()(TemperaturePlotPanel))((props) => {
    return {
        userSelect: "none",
        containerType: "inline-size",
        containerName: "TemperaturePlotPanel",
    } as React.CSSProperties as unknown as any;
});

TemperturePlotPanelWrapped.defaultProps = {
    minW: 1,
    minH: 4,
    meta: {
        friendlyName: i18n.t('panel.temperature.friendlyName'),
        description: i18n.t('panel.temperature.description'),
    }
} as LayoutBase;

export default TemperturePlotPanelWrapped;
