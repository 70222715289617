import {WithTranslation, withTranslation} from "react-i18next";
import {Button, Icon as MuiIcon, Menu, MenuItem, styled, useTheme} from "@mui/material";
import {plotColors} from "../../../Helpers/PlotColors";
import HeaterSet from "../HeaterSet";
import {settings} from "../../../Machine/settings";
import MenuButton from "../MenuButton";
import Icon from "@mdi/react";
import {
    mdiAlertCircle,
    mdiHeatPumpOutline,
    mdiPowerOff,
    mdiPowerOn,
    mdiPowerStandby,
    mdiRadiator,
    mdiTuneVertical
} from '@mdi/js';
import {Heater, HeaterState} from "@duet3d/objectmodel";
import {memo, useContext, useState} from "react";
import {useAppSelector} from "../../../ReduxStore";
import {displaySensorValue} from "../../../Utils/display";
import {ConnectionCtx} from "../../MachineAccessWrapper";

export interface HeaterDef {
    index: number;
    heater: Heater;
    heaterIndex: number;
}

interface BedControlProps extends WithTranslation {
    className?: string;
    heaters: HeaterDef[];
    type: "bed" | "chamber";
    totalHeaters?: number;
}

const BedControl = (props: BedControlProps) => {
    const {t} = props;
    const [selectedHeater, setSelectedHeater] = useState(-1);
    const model = useAppSelector(state => state.objectModel).current.model;
    const theme = useTheme();
    const uiState = useAppSelector(state => state.uiState);
    const uiFrozen = uiState.frozen;
    const connection = useContext(ConnectionCtx);

    const getHeaterIcon = () => <Icon path={props.type === "bed" ? mdiRadiator : mdiHeatPumpOutline} size={"1em"}/>

    const getHeaterName = (heater: Heater | null, heaterIndex: number)  => {
        if ((heater !== null) && (heater.sensor >= 0) && (heater.sensor < model.sensors.analog.length)) {
            const sensor = model.sensors.analog[heater.sensor];
            if ((sensor !== null) && sensor.name) {
                const matches = /(.*)\[(.*)\]$/.exec(sensor.name);
                if (matches) {
                    return matches[1];
                }
                return sensor.name;
            }
        }
        return t("panel.tools.heater", {0: heaterIndex.toString()});
    }

    const getHeaterValue = (heater: Heater | null) => {
        if ((heater !== null) && (heater.sensor >= 0) && (heater.sensor < model.sensors.analog.length)) {
            const sensor = model.sensors.analog[heater.sensor];
            if (sensor !== null) {
                return displaySensorValue(sensor);
            }
        }
        return t("generic.noValue");
    }

    const getHeaterStateIcon = (heater: Heater | null) => {
        if (heater === null) {
            return <></>
        }

        if (heater.state === HeaterState.active) {
            return <Icon style={{color: theme.palette.success.main}} size={"20px"} path={mdiPowerOn} />
        }
        else if (heater.state === HeaterState.off) {
            return <Icon style={{color: theme.palette.info.main}} size={"20px"} path={mdiPowerOff} />
        }
        else if (heater.state === HeaterState.standby) {
            return <Icon style={{color: theme.palette.warning.main}} size={"20px"} path={mdiPowerStandby} />
        }
        else if (heater.state === HeaterState.fault) {
            return <Icon size={"20px"} color={theme.palette.error.main} path={mdiAlertCircle} />
        }
        else if (heater.state === HeaterState.tuning) {
            return <Icon size={"20px"} path={mdiTuneVertical} />
        }
    }

    const firstHeater = props.heaters[0].heater;

    const allHeatersClick = async () => {
        // Get valid indices
        const heaters = (props.type === "bed") ? model.heat.bedHeaters : model.heat.chamberHeaters;
        const indices: Array<number> = [];
        for (let index = 0; index < heaters.length; index++) {
            const heaterIndex = heaters[index];
            if (heaterIndex >= 0 && heaterIndex < model.heat.heaters.length) {
                const bedHeater = model.heat.heaters[heaterIndex];
                if (bedHeater !== null) {
                    indices.push(index);

                    // Since there is no dedicate facility for resetting heater faults, check all bed heaters here
                    if (bedHeater.state === HeaterState.fault) {
                        // TODO: Handle faults
                        // emit("resetHeaterFault", heaterIndex);
                        return;
                    }
                }
            }
        }

        // Control heaters depending on the state of the first heater
        if (firstHeater !== null) {
            if (props.type === "bed") {
                switch (firstHeater.state) {
                    case HeaterState.off:       // Off -> Active
                        await connection.sendCode(indices.map(index => `M140 P${index} S${firstHeater!.active}`).join('\n'), false);
                        break;

                    case HeaterState.standby:   // Standby -> Off
                        await connection.sendCode(indices.map(index => `M140 P${index} S-273.15`).join('\n'), false);
                        break;

                    case HeaterState.active:    // Active -> Standby
                        await connection.sendCode(indices.map(index => `M144 P${index}\n`).join('\n'), false);
                        break;

                    // Faults are handled before we get here
                }
            } else {
                switch (firstHeater.state) {
                    case HeaterState.off:       // Off -> Active
                        await connection.sendCode(indices.map(index => `M141 P${index} S${firstHeater!.active}`).join('\n'), false);
                        break;

                    // Standby mode for chambers is not officially supported yet (there is no code for standby control)

                    default:    // Active -> Off
                        await connection.sendCode(indices.map(index => `M141 P${index} S-273.15`).join('\n'), false);
                        break;

                    // Faults are handled before we get here
                }
            }
        }
    }

    const heaterClick = async (idx: number) => {
        if (uiFrozen) {
            return;
        }

        if (props.heaters.length > 1 && selectedHeater === -1) {
            await allHeatersClick();
            return;
        }

        const heater = props.heaters[idx !== -1 ? idx : 0].heater;
        const index = props.heaters[idx !== -1 ? idx : 0].index;

        if (props.type === "bed") {
            switch (heater.state) {
                case HeaterState.off:       // Off -> Active
                    await connection.sendCode(`M140 P${index} S${heater.active}`, false);
                    break;

                case HeaterState.standby:   // Standby -> Off
                    await connection.sendCode(`M140 P${index} S-273.15`, false);
                    break;

                case HeaterState.active:    // Active -> Standby
                    await connection.sendCode(`M144 P${index}`, false);
                    break;

                case HeaterState.fault:     // Fault -> Ask for reset
                    // TODO: Handle flauts
                    // emit("resetHeaterFault", model.heat.heaters.indexOf(heater));
                    break;
            }
        } else {
            switch (heater.state) {
                case HeaterState.off:       // Off -> Active
                    await connection.sendCode(`M141 P${index} S${heater.active}`, false);
                    break;

                // Standby mode for chambers is not officially supported yet (there is no code for standby control)

                case HeaterState.fault:     // Fault -> Ask for reset
                    // emit("resetHeaterFault", model.heat.heaters.indexOf(heater));
                    break;

                default:    // Active -> Off
                    await connection.sendCode(`M141 P${index} S-273.15`, false);
                    break;
            }
        }
    }

    const setHeaterActiveTemp = (idx: number, value: string) => {
        if (value === "") {
            value = "0";
        }
        const heatersToSet = [];
        if (idx !== -1) {
            heatersToSet.push(props.heaters[idx].index);
        }
        else {
            heatersToSet.push(...props.heaters.map((h) => h.index));
        }

        heatersToSet.map((h) => {
            if (props.type === "bed") {
                connection.sendCode(`M140 P${h} S${value}`, false);
            }
            else { // Chamber
                connection.sendCode(`M141 P${h} S${value}`, false);
            }
            return undefined;
        })
    }

    const setHeaterStandbyTemp = (idx: number, value: string) => {
        if (value === "") {
            value = "0";
        }
        const heatersToSet = [];
        if (idx !== -1) {
            heatersToSet.push(props.heaters[idx].index);
        }
        else {
            heatersToSet.push(...props.heaters.map((h) => h.index));
        }

        heatersToSet.map((h) => {
            if (props.type === "bed") {
                connection.sendCode(`M140 P${h} R${value}`, false);
            }
            else { // Chamber
                connection.sendCode(`M141 P${h} R${value}`, false);
            }
            return undefined;
        })
    }

    const all = props.type === "bed" ? t("panel.tools.allBeds") : t("panel.tools.allChambers");

    const menuButtons: any[] = props.heaters.map((heater: HeaterDef, idx) => {
        return <MenuItem value={idx}>{getHeaterIcon()}&nbsp;{getHeaterName(heater.heater, heater.heaterIndex)}</MenuItem>
    })

    menuButtons.unshift(<MenuItem value={-1}>{getHeaterIcon()}&nbsp;{all}</MenuItem>);

    if (props.heaters.length === 0) {
        return <></>
    }

    return (
        <div className={props.className}>
            {props.heaters.length === 1
                ? <div className={"heaterBox"}>
                    <div className={"heaterName"}
                         style={{fontWeight: "bold", color: plotColors[props.heaters[0].heater.sensor], cursor: "pointer"}}
                         onClick={() => {
                             heaterClick(0);
                         }}
                    >
                        {getHeaterName(props.heaters[0].heater, props.heaters[0].heaterIndex)}
                    </div>
                    <div className={"heaterIcon"}>{getHeaterStateIcon(props.heaters[0].heater)}</div>
                    <div className={"heaterTemp"}>{getHeaterValue(props.heaters[0].heater)}</div>
                    <HeaterSet
                        label={"Active"}
                        onTempChange={(v) => setHeaterActiveTemp(0, v)}
                        temp={(props.heaters[0].heater?.active.toString() || undefined) || "0"}
                        temps={props.type === "bed" ? settings.temperatures.bed.active : settings.temperatures.chamber}
                    />
                    <HeaterSet
                        label={"Standby"}
                        onTempChange={(v) => setHeaterStandbyTemp(0, v)}
                        temp={(props.heaters[0].heater?.standby.toString() || undefined) || "0"}
                        temps={props.type === "bed" ? settings.temperatures.bed.standby : settings.temperatures.chamber}
                    />
                </div>

                : <div className={"heaterBox"}>
                    <div className={"heaterName"}
                         style={{fontWeight: "bold", color: theme.palette.primary.main, cursor: "pointer"}}
                         onClick={() => {
                             heaterClick(selectedHeater);
                         }}
                    >
                        {selectedHeater !== -1 ? getHeaterName(props.heaters[selectedHeater].heater, selectedHeater) : all}
                    </div>
                    <div className={"heaterIcon"}>{getHeaterStateIcon(props.heaters[selectedHeater === -1 ? 0 : selectedHeater].heater)}</div>
                    <div className={"heaterTemp"}>{getHeaterValue(props.heaters[selectedHeater === -1 ? 0 : selectedHeater].heater)}</div>
                    <HeaterSet
                        label={"Active"}
                        onTempChange={(v) => setHeaterActiveTemp(selectedHeater, v)}
                        temp={(props.heaters[selectedHeater === -1 ? 0 : selectedHeater].heater?.active.toString() || undefined) || "0"}
                        temps={props.type === "bed" ? settings.temperatures.bed.active : settings.temperatures.chamber}
                    />
                    <HeaterSet
                        label={"Standby"}
                        onTempChange={(v) => setHeaterStandbyTemp(selectedHeater, v)}
                        temp={(props.heaters[selectedHeater === -1 ? 0 : selectedHeater].heater?.standby.toString() || undefined) || "0"}
                        temps={props.type === "bed" ? settings.temperatures.bed.standby : settings.temperatures.chamber}
                    />
                </div>
            }

            <div className={"toolName"}>
                {props.heaters.length === 1
                    ? <div
                        className={"toolHeading"}
                        // onClick={() => {
                        //     props.toolClick(props.tool[0].number);
                        // }}
                    >
                        {props.totalHeaters === 1
                            ? <><Icon path={props.type === "bed" ? mdiRadiator : mdiHeatPumpOutline} size={"1em"}/> {t(`panel.tools.${props.type}`, {0: ""})}</>
                            : <><Icon path={props.type === "bed" ? mdiRadiator : mdiHeatPumpOutline} size={"1em"}/> {t(`panel.tools.${props.type}`, {0: props.heaters[0].index})}</>
                        }
                    </div>
                    : <MenuButton
                        sx={{display: "inherit", height: ""}}
                        menu={
                            <Menu open={false}>
                                {menuButtons}
                            </Menu>
                        }
                        onSelection={(item, e) => {
                            setSelectedHeater(e.value);
                        }}
                    >
                        <Button
                            sx={{
                                verticalAlign: "unset",
                                padding: "0",
                                margin: "0",
                                fontSize: "inherit",
                                height: "0.75rem",
                                lineHeight: "0.75rem"
                            }}
                            variant={"text"}
                        >
                            {getHeaterIcon()}&nbsp; {t(`panel.tools.${props.type}`, {0: ""})} <MuiIcon
                            sx={{transform: "scale(0.5)"}}>keyboard_arrow_down</MuiIcon>
                        </Button>
                    </MenuButton>
                }
            </div>
        </div>
    )
}

export default styled(withTranslation()(BedControl))((props) => {
    return {
        position: "relative",
        borderTop: `1px solid ${props.theme.palette.primary.light}`,
        containerType: "inline-size",
        containerName: "toolContainer",
        marginTop: "0.37rem",
        paddingTop: "1rem",
        paddingBottom: "20px",
        gap: "7px",
        ".heaterBox": {
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            flexDirection: "row",
            gap: "10px",
            marginRight: "10px",
        },
        ".toolName": {
            position: "absolute",
            top: "-0.5rem",
            left: "7px",
            fontSize: "0.75rem",
            height: "1rem",
            backgroundColor: props.theme.palette.background.paper,
            paddingLeft: "7px",
            paddingRight: "7px",
        },
        ".toolHeading": {
            color: props.theme.palette.primary.main,
            cursor: "pointer",
        },
        ".heaterBox>div.heaterName": {
            flexBasis: "0",
            flexGrow: "25",
            paddingLeft: "20px",
        },
        ".heaterBox>div.heaterIcon": {
            flexBasis: "0",
            flexGrow: "0",
            paddingTop: "0.45rem",
        },
        ".heaterBox>div.heaterTemp": {
            flexBasis: "0",
            flexGrow: "20",
            minWidth: "4rem",
            textAlign: "right",
            whiteSpace: "nowrap",
        },
        // "@container toolContainer (width < 330px)": {
        //     "&>*": {
        //         backgroundColor: "red",
        //     },
        // },
        ".MuiInputAdornment-root": {
            margin: "0 !important",
        },
    };
});
