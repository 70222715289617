import {withTranslation} from "react-i18next";
import FileList from "../../Components/FileList";
import {Editor} from "@monaco-editor/react";

const FilesPage = (props: any) => {
    return (
        <FileList />
    )
}

export default withTranslation()(FilesPage);
