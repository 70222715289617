import {styled, Theme, useMediaQuery, useTheme} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../ReduxStore";
import React, {useEffect, useRef, useState} from "react";
import {setUiState} from "../../../Reducers/UiState";
import Sidebar from "../Sidebar";
import DynamicPanelSelector from "../../DynamicPanelSelector";

interface AppFramingProps extends React.ComponentProps<"div"> {
    navbar: JSX.Element;
    children?: React.ReactNode | React.ReactNode[];
}

const AppFraming = (props: AppFramingProps) => {
    const uiState = useAppSelector(state => state.uiState);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const menuOverlay = useMediaQuery(theme.breakpoints.down("md"));
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const sidebarRef = useRef(null);
    const menuButtonRef = useRef(null);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (menuOverlay
                && menuOpen
                && sidebarRef.current
                // @ts-ignore
                && !sidebarRef.current.contains(event.target)
                // @ts-ignore
                && !menuButtonRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [menuOpen, menuOverlay, sidebarRef]);

    return (
        <div className={props.className}>
            <props.navbar.type
                {...props.navbar.props}
                className={"navbar"}
                menuRef={menuButtonRef}
                onMenuClick={() => {
                if (!menuOverlay) {
                    setMenuOpen(false);
                    dispatch(setUiState({...uiState, menuOpen: !uiState.menuOpen}));
                }
                else {
                    setMenuOpen(!menuOpen);
                }
            }}
            />
            <div className={"outer-box"}>
                <div ref={sidebarRef} className={`left-sidebar ${!menuOverlay && uiState.menuOpen && "visible"} ${menuOverlay && menuOpen && "overlay"}`}>
                    <Sidebar closeMenu={() => {
                        if (menuOverlay) {
                            setMenuOpen(false);
                        }
                    }}/>
                </div>
                <div className={"main-content"}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default styled(AppFraming)((props) => {
    return {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        margin: "0",
        padding: "0",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignContent: "stretch",
        ".navbar": {
            height: "64px",
        },
        ".outer-box": {
            position: "relative",
            display: "flex",
            flexGrow: "100",
            minHeight: "0",
        },
        ".left-sidebar": {
            // @ts-ignore
            [props.theme.breakpoints.up("md")]: {
                "&.visible": {
                    width: "280px",
                    display: "block",
                    borderRight: "6px double #e0e0e0",
                },
            },
            // @ts-ignore
            [props.theme.breakpoints.down("md")]: {
                "&.overlay": {
                    display: "block",
                    float: "left",
                    position: "absolute",
                    width: "280px",
                    // @ts-ignore
                    backgroundColor: props.theme.palette.background.default,
                    zIndex: "100",
                    minHeight: "calc(100vh - 64px)",
                    borderRight: "6px double #e0e0e0",
                    borderTop: "1px solid #e0e0e0",
                },
            },
            display: "none",
            overflowX: "hidden",
            overflowY: "auto",
        },
        ".main-content": {
            flexGrow: "100",
            overflowX: "hidden",
            overflowY: "auto",
            position: "relative",
        },
        ".main-overlay": {
            position: "absolute",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            backgroundColor: "grey",
            opacity: "0.6",
        },
    } as React.CSSProperties as unknown as any;
})
