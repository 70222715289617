import {Divider, Menu, MenuItem, styled} from "@mui/material";
import React, {memo, ReactChildren, useRef, useState} from "react";

interface MenuButtonProps {
    children: React.ReactElement;
    className?: string;
    menu: React.ReactElement;
    onSelection: (s: string, t: HTMLLIElement) => void;
    onOpen?: () => void;
}

const MenuButton = (props: MenuButtonProps) => {
    const buttonRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleClick = (e: any) => {
        setOpen(true);
        props.onOpen?.();
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleItemClick = (e: any) => {
        let target = e.target as HTMLElement;
        while (target.nodeName !== "LI") {
            target = target.parentElement as HTMLElement;
        }

        props.onSelection(target.innerText.trim(), target as HTMLLIElement);
        handleClose();
    }

    const children = props.menu.props.children.map((item: any) => {
        return React.cloneElement(item as React.ReactElement, {
            onMouseUp: handleItemClick,
            onTouchEnd: handleItemClick,
            key: Math.random(), // Bad but can't do better
        });
    })

    return (
        <div className={props.className}>
            {React.Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, {
                    onClick: handleClick,
                    ref: buttonRef,
                });
            })}

            {React.cloneElement(props.menu as React.ReactElement, {
                onClose: handleClose,
                open: open,
                anchorEl: buttonRef.current,
                children: children,
                keepMounted: true,
                sx: {"li *": {pointerEvents: "none"}}
            })}
        </div>
    );
}

export default memo(styled(MenuButton)((props) => {
    return {
    };
}))
