import React, {useRef, useState} from "react";
import {BaseConnector, Callbacks, connect, DefaultSettings, Settings} from "@duet3d/connectors";
import {reduxStore, useAppDispatch, useAppSelector} from "../../ReduxStore";
import {setObjectModel} from "../../Reducers/ObjectModel";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import {styled} from "@mui/material";
import {withTranslation} from "react-i18next";
import ConnectionProgress from "../Dialogs/ConnectionProgress";
import {Heat, Heater, MachineStatus, Message} from "@duet3d/objectmodel";
import {FileTransfer} from "../../Utils/FileTransfer";
import {UploadController} from "../../Utils/UploadController";
import {dispatchEvent} from "../../EventSystem/EventDispatcher";
import {addTemperatures, clearTemperatures} from "../../Reducers/Temperature";
import {settings} from "../../Machine/settings";
import {saveSettings} from "../../Machine/Helpers";

interface ConnectionState {
    conn: BaseConnector;
    connected: boolean;
    connecting: boolean;
    connectionError: boolean;
    halted: number;
}

interface MessageRecord {
    index: number;
    message: Message;
}

export const consoleMessages:MessageRecord[] = [];

const fileTransfer: FileTransfer = new FileTransfer();
export const uploadController: UploadController = new UploadController(fileTransfer);

export const ConnectionCtx = React.createContext<BaseConnector>(undefined as unknown as BaseConnector);
export const FileTransferCtx = React.createContext<FileTransfer>(undefined as unknown as FileTransfer)
export const UploadControllerCtx = React.createContext<UploadController>(undefined as unknown as UploadController);

const MachineAccessWrapper = (props: any) => {
    const objectModelState = useAppSelector((state) => state.objectModel);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingProgress, setLoadingProgress] = useState<number>(0);
    const [needHostname, setNeedHostname] = useState<boolean>(false);
    const [hostname, setHostname] = useState<string>("192.168.201.200");
    // const [hostname, setHostname] = useState<string>("localhost");
    const [connection, setConnection] = useState<BaseConnector>(undefined as unknown as BaseConnector);
    const machineName = useRef<string>("");
    const lastTemperatureReading = useRef<Date>(new Date(0));

    const connectionState = useRef<ConnectionState>({
        conn: undefined as unknown as BaseConnector,
        connecting: false,
        connected: false,
        connectionError: false,
        halted: 0,
    } as ConnectionState);

    // @ts-ignore
    const connSettings: Settings = {
        ...DefaultSettings,
        // your custom settings
        protocol: "http:",
        maxRetries: 4,
    };

    const callbacks: Callbacks = {
        onConnectProgress: function (connector: BaseConnector, progress: number): void {
            if (progress === -1) {
                console.log("Connection attempt complete");
                connectionState.current.connected = true;
                connectionState.current.connectionError = false;
                setLoading(false);
            } else {
                console.log("Connection progress: " + progress + "%");
                setLoadingProgress(progress);
            }
        },
        onConnectionError: function (connector: BaseConnector, reason: unknown): void {
            console.log("Connection error: " + reason);
            connectionState.current.connectionError = true;
            setLoadingProgress(-1);
            setLoading(true);
            connectionState.current.conn
                .reconnect()
                .then(() => {
                    // No action needed
                })
                .catch((e: any) => {
                    callbacks.onConnectionError(connectionState.current.conn, undefined);
                });
        },
        onReconnected: function (connector: BaseConnector): void {
            // TODO: This doesn't get called for me in standalone, why?
            console.log("Connection established again");
        },
        onUpdate: function (connector: BaseConnector, data: any): void {
            // We must already be connected if we get this message
            setLoading(false);
            // Note that this is called before the final connector instance is returned!
            if (objectModelState.current.model.update(data) !== null) {
                dispatch(setObjectModel({current: {model: objectModelState.current.model}}));
                if (objectModelState.current.model.messages.length) {
                    consoleMessages.push(...objectModelState.current.model.messages.map((m) => {
                        return {
                            index: consoleMessages.length ? consoleMessages[consoleMessages.length - 1].index + 1 : 1,
                            message: m,
                        } as MessageRecord;
                    }));
                    if (consoleMessages.length > 1000) {
                        consoleMessages.splice(0, 1000 - consoleMessages.length);
                    }
                    dispatchEvent("console-message", JSON.stringify({length: objectModelState.current.model.messages.length, messages: objectModelState.current.model.messages}));
                    objectModelState.current.model.messages.splice(0);
                }
                if (machineName.current !== objectModelState.current.model.network.name) {
                    machineName.current = objectModelState.current.model.network.name;
                    dispatchEvent("machine-name", JSON.stringify({name: objectModelState.current.model.network.name}));
                }
                if (Date.now() > lastTemperatureReading.current.getTime() + 2000) {
                    const temps = objectModelState.current.model.sensors.analog.reduce((acc, cur) => {
                        if (cur !== null)
                            acc.push(cur.lastReading || 0);
                        return acc;
                    }, [] as number[])
                    // const temps = objectModelState.current.model.heat.heaters.map((h, idx): number => {
                    //     if (h === null)
                    //         return 0;
                    //     return h.current;
                    // });
                    lastTemperatureReading.current = new Date();
                    if (temps.length > 0) {
                        dispatch(addTemperatures(temps));
                    }
                }
            }
            if (objectModelState.current.model.state.status === MachineStatus.halted) {
                connectionState.current.halted++;
            }
            else {
                if (connectionState.current.halted > 0) {
                    connectionState.current.halted = 0;
                    setLoading(false);
                }
            }
        },
        onVolumeChanged: function (connector: BaseConnector, volumeIndex: number): void {
            // TODO reload file browser lists of the given volume
        },
    };

    if (connectionState.current.halted > 2 && !loading) {
        setLoading(true);
    }

    if (!connectionState.current.connecting && !connectionState.current.connected) {
        connectionState.current.connecting = true;

        dispatch(clearTemperatures());

        setLoading(true);
        connect(hostname, connSettings) //window.location.hostname, settings)
            .then((conn) => {
                conn.download("/sys/mcui-settings.json")
                    .then((mcuiSettings) => {
                        const keys = Object.keys(settings);
                        Object.assign(settings, keys.reduce((acc, cur, idx) => {
                            if (mcuiSettings.hasOwnProperty(cur)) {
                                // @ts-ignore
                                acc[cur] = mcuiSettings[cur];
                            }
                            return acc;
                        }, {} as typeof settings))
                    })
                    .catch((e) => {
                        // conn.upload("/sys/mcui-settings.json", JSON.stringify(settings, undefined, 2));
                        saveSettings();
                        console.log("No saved settings, using default");
                    });
                connectionState.current.connected = true;
                connectionState.current.conn = conn;
                connectionState.current.connectionError = false;
                fileTransfer.setConnector(conn);
                setConnection(conn);
                conn.setCallbacks(callbacks);
                //  @ts-ignore  Check the shape of the connection. If it's websocket, we will never get a progress callback
                if (conn.socket !== undefined) {
                    setLoading(false);
                }
            })
            .catch((e) => {
                // Network error, we should prompt the user for a hostname to connect to.
                setNeedHostname(true);
            });
    }

    uploadController.setStore(reduxStore);
    return (
        <div className={props.className}>
            <ConnectionCtx.Provider value={connection}>
                <FileTransferCtx.Provider value={fileTransfer}>
                    <UploadControllerCtx.Provider value={uploadController}>
                        <LoadingOverlayWrapper
                            active={loading}
                            classNamePrefix={"AppLoader_"}
                            // @ts-ignore
                            spinner={
                                <ConnectionProgress
                                    progress={loadingProgress}
                                    needHostname={needHostname}
                                    halted={connectionState.current.halted}
                                    setHostname={(h: string) => {
                                        setHostname(h);
                                        setNeedHostname(false);
                                        connectionState.current.connected = false;
                                        connectionState.current.connecting = false;
                                        setLoadingProgress(0);
                                    }}
                                />
                            }
                        >
                            {props.children}
                        </LoadingOverlayWrapper>
                    </UploadControllerCtx.Provider>
                </FileTransferCtx.Provider>
            </ConnectionCtx.Provider>
        </div>
    );
};

export default styled(withTranslation()(MachineAccessWrapper))`
    .AppLoader_wrapper {
        height: 100vh;
        overflow: hidden;
    }
`;
