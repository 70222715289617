import {SnackbarKey, useSnackbar} from "notistack";
import {Icon, IconButton, styled, useTheme} from "@mui/material";

interface SnackbarCloseButtonProps {
    className?: string;
    snackbarKey: SnackbarKey;
    variant?: string;
}

export default styled(function (props: SnackbarCloseButtonProps) {
    const {closeSnackbar} = useSnackbar();
    const theme = useTheme();
    const palette = (theme.palette as any)[props.variant || "primary"];
    const color = palette.contrastText;

    return (
        <IconButton className={"notistack-MuiCloseButton"} onClick={() => closeSnackbar(props.snackbarKey)}>
            <Icon className={props.className} sx={{color: color}}>close</Icon>
        </IconButton>
    );
})``;
