import {Button, Dialog, DialogTitle, useTheme} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Atom, atom, useSetAtom} from "jotai";
import SnackbarCloseButton from "../../Components/Misc/SnackbarCloseButton";
import {reduxStore} from "../../ReduxStore";
import {jotaiStore} from "../../index";
import {FileTransferCtx} from "../../Components/MachineAccessWrapper";
import FileUpload from "../../Components/FileUpload";
import EventListener from "../../EventSystem/EventListener";
import {dispatchEvent} from "../../EventSystem/EventDispatcher";
import FileUploadDisplay from "../../Components/Dialogs/FileUploadDisplay";
import FileProgressBar from "../../Helpers/FileProgressBar";
import ThemeSwitcher from "../../Components/Misc/ThemeSwitcher";
import DuetSwitch from "../../Components/Inputs/Switch";

const Playpen = (props: any) => {
    const theme = useTheme();

    return (
        <div>
            <ThemeSwitcher>
                <DuetSwitch checked={theme.palette.mode === "dark"} />
            </ThemeSwitcher>

            <FileProgressBar text={"yugedf;jsn;kjsdbfn;sjdf;fjkdhs;kfjn;dksjnv 'ksd;aj hfm;cla.dkjfc ;adjx,fxairchfl;adcfj,cd;sk.m  ghdf;ac,xj;dscf ,jf;d,cjfd;fx.;doricfg,;ads.xfj,;soi,jcfsdomjcfosdmn'fan ;kasdfn;fasdfa"}
                             progress={50}/>
            <Button onClick={() => {
                // dispatchEvent("upload", JSON.stringify({open: true}));
            }}>Test</Button>
        </div>
    );
}

export default Playpen;
