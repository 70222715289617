import {createSlice} from "@reduxjs/toolkit"
import type {PayloadAction} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

export interface TerminalState {
    id: string;
    scrollback: string;
    lastMessage: number;
}

const initialState: {[key: string]: TerminalState} = {};

const terminalSlice = createSlice({
    name: "uiState",
    initialState,
    reducers: {
        saveTerminal(state, action: PayloadAction<TerminalState>) {
            state[action.payload.id] = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            Object.assign(state, initialState);
        });
    },
});

export const {saveTerminal} = terminalSlice.actions;
export default terminalSlice.reducer;
