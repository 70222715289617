import {WithTranslation, withTranslation} from "react-i18next";
import {styled, Typography} from "@mui/material";

export interface ConfigElementProps {
    basePath: string;
}

interface ConfigItemProps extends  WithTranslation {
    className?: string;
    children?: React.ReactNode;
    label: string;
}
// ConfigItem is a wrapper for a ConfigElement
const ConfigItem = (props: ConfigItemProps) => {
    return (
        <div className={props.className}>
            <div className={"label"}><Typography>{props.label}</Typography></div>
            <div className={"element"}>{props.children}</div>
        </div>
    );
}

export default styled(withTranslation()(ConfigItem))((props) => {
    return {
        // border: "1px solid red",
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        padding: "6px 10px",
        minHeight: "48px",
        alignItems: "center",
        ".label": {
            flexGrow: "2",
        },
        ".element": {
            flexGrow: "0",
            flexShrink: "1",
            maxWidth: "32%",
        },
    };
});
