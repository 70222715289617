import {WithTranslation, withTranslation} from "react-i18next";
import {styled, TextFieldProps} from "@mui/material";
import {resolvePath} from "../../../Helpers/objectPath";
import {settings} from "../../../Machine/settings";
import React, {useState} from "react";
import {ConfigElementProps} from "../ConfigItem";
import {saveSettings} from "../../../Machine/Helpers";

interface ConfigTextFieldElementProps extends ConfigElementProps, WithTranslation {
    className?: string;
    children?: React.ReactNode;
    item: string;
    validator?: (v: string | undefined) => boolean;
    helperText?: string;
}

const ConfigTextFieldElement = (props: ConfigTextFieldElementProps) => {
    const obj = resolvePath(props.basePath, settings);
    const [text, setText] = useState<string>(obj[props.item]);
    const [error, setError] = useState<boolean>(false);

    return (
        <form className={props.className} onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // @ts-ignore
            if (props.validator && !props.validator(e.currentTarget.elements[0].value)) {
                setError(true);
                return;
            }
            obj[props.item] = text;
            saveSettings();
        }}>
            {React.Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, {
                    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                        if (props.validator && !props.validator(e.target.value)) {
                            setError(true);
                            setText(obj[props.item]);
                            return;
                        }
                        obj[props.item] = text;
                        saveSettings();
                    },
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        if (props.validator && !props.validator(e.target.value)) {
                            setError(true);
                            // if (e.target.value === "" || e.target.value === undefined) {
                            //     setError(true);
                            // }
                            // else {
                            //     setError(true);
                            //     return;
                            // }
                        }
                        else {
                            if (props.validator) {
                                setError(false);
                            }
                        }
                        setText(e.target.value);
                    },
                    value: text,
                    error: error,
                    helperText: error && props.helperText,
                });
            })}
        </form>
    )
}

export default styled(withTranslation()(ConfigTextFieldElement))((props) => {
    return {
    };
})
