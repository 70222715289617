import {PURGE} from "redux-persist";
import {Layout} from "react-grid-layout";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UiState} from "./UiState";

type ResizeHandle = "s" | "w" | "e" | "n" | "sw" | "nw" | "se" | "ne";

export interface LayoutBase {
    minW?: number | undefined;
    maxW?: number | undefined;
    minH?: number | undefined;
    maxH?: number | undefined;
    static?: boolean | undefined;
    isDraggable?: boolean | undefined;
    isResizable?: boolean | undefined;
    resizeHandles?: ResizeHandle[] | undefined;
    isBounded?: boolean | undefined;
    meta?: any;
}
export interface PanelItem {
    elementType: string;
    name: string;
    layouts: {[key: string]: Layout};
}

export interface PanelData {
    panels: PanelItem[];
}

export interface Dashboards {
    [key: string]: PanelData;
}

const initialState = {
} as Dashboards;

const dashboardsSlice = createSlice({
    name: "dashboards",
    initialState,
    reducers: {
        setPanelData(state, action: PayloadAction<Dashboards>) {
            Object.assign(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            Object.assign(state, initialState);
        });
    },
});

export const {setPanelData} = dashboardsSlice.actions;
export default dashboardsSlice.reducer;
