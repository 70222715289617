import {CombinedState, combineReducers, Reducer, ReducersMapObject} from "redux";
import PanelContainer from "./PanelContainer";
import ObjectModel from "./ObjectModel";
import UiState from "./UiState";
import Temperature from "./Temperature";
import Terminal from "./Terminal";
import TerminalHistory from "./History";

// function combineReducers<S>(reducers: ReducersMapObject<S, any>): Reducer<CombinedState<S>> {
//     const reducerKeys = Object.keys(reducers);
//     type reducerKeyType = keyof typeof reducers;
//
//     return (state, action): any => {
//         let newState: CombinedState<S> = {} as CombinedState<S>;
//
//         let pieces: string[] = action.type.split("/", 2);
//
//         if (pieces.length === 2 && reducerKeys.includes(pieces[0])) {
//             reducerKeys.forEach((key) => {
//                 if (key === pieces[0]) {
//                     let fn = reducers[key as reducerKeyType];
//                     // @ts-ignore
//                     newState[key as reducerKeyType] = fn(
//                         state === undefined ? undefined : state[key as reducerKeyType],
//                         action,
//                     );
//                 } else {
//                     if (state !== undefined) {
//                         newState[key as reducerKeyType] = state[key as reducerKeyType];
//                     }
//                 }
//             });
//         } else {
//             reducerKeys.forEach((key) => {
//                 let fn = reducers[key as reducerKeyType];
//                 // @ts-ignore
//                 newState[key as reducerKeyType] = fn(
//                     state === undefined ? undefined : state[key as reducerKeyType],
//                     action,
//                 );
//             });
//         }
//         return newState;
//     };
// }

const rootReducer = combineReducers({
    dashboards: PanelContainer,
    objectModel: ObjectModel,
    uiState: UiState,
    temperature: Temperature,
    terminal: Terminal,
    history: TerminalHistory,
});

export default rootReducer;
