import {withTranslation} from "react-i18next";
import {MenuItem, Select, styled, TextField} from "@mui/material";
import Switch from "../../Components/Inputs/Switch";
import ThemeSwitcher from "../../Components/Misc/ThemeSwitcher";
import ConfigItem from "../../Components/Misc/ConfigItem";
import ConfigSwitchElement from "../../Components/Misc/ConfigSwitchElement";
import ConfigTextFieldElement from "../../Components/Misc/ConfigTextFieldElement";
import ConfigGroup from "../../Components/Misc/ConfigGroup";
import {NumberValidator} from "../../Machine/Helpers";
import ConfigSelectElement from "../../Components/Misc/ConfigSelectElement";

const SettingsGeneral = (props: any) => {
    return (
        <div className={props.className}>
            <ConfigGroup caption={"User interface"}>
                <ConfigItem label={"Dark theme"}>
                    <ThemeSwitcher>
                        <Switch />
                    </ThemeSwitcher>
                </ConfigItem>
                <ConfigItem label={"Group identical tools"}>
                    <ConfigSwitchElement basePath={""} item={"groupTools"}>
                        <Switch checked={false} />
                    </ConfigSwitchElement>
                </ConfigItem>
                <ConfigItem label={"Single bed heater control"}>
                    <ConfigSwitchElement basePath={""} item={"groupBedHeaters"}>
                        <Switch checked={false} />
                    </ConfigSwitchElement>
                </ConfigItem>
                <ConfigItem label={"Single chamber heater control"}>
                    <ConfigSwitchElement basePath={""} item={"groupChamberHeaters"}>
                        <Switch checked={false} />
                    </ConfigSwitchElement>
                </ConfigItem>
                <ConfigItem label={"Delay when resetting heater fault"}>
                    <ConfigSwitchElement basePath={""} item={"delayHeaterFault"}>
                        <Switch checked={false} />
                    </ConfigSwitchElement>
                </ConfigItem>
            </ConfigGroup>
            <ConfigGroup caption={"Notifications"}>
                <ConfigItem label={"Dismiss errors automatically"}>
                    <ConfigSwitchElement basePath={""} item={"dismissErrors"}>
                        <Switch checked={false} />
                    </ConfigSwitchElement>
                </ConfigItem>
                <ConfigItem label={"Default notification Timeout (ms)"}>
                    <ConfigTextFieldElement
                        basePath={""}
                        item={"notificationTimeout"}
                        validator={(v) => NumberValidator(v || "", 250, 20000)}
                        helperText={"Enter a number between 250 and 20000"}
                    >
                        <TextField variant={"standard"}/>
                    </ConfigTextFieldElement>
                </ConfigItem>
            </ConfigGroup>
            <ConfigGroup caption={"Network"}>
                <ConfigItem label={"Ajax retries"}>
                    <ConfigTextFieldElement
                        basePath={""}
                        item={"ajaxRetries"}
                        validator={(v) => NumberValidator(v || "", 1, 4)}
                        helperText={"Value must be between 1 and 4"}
                    >
                        <TextField variant={"standard"}/>
                    </ConfigTextFieldElement>
                </ConfigItem>
                <ConfigItem label={"Ping interval (ms)"}>
                    <ConfigTextFieldElement
                        basePath={""}
                        item={"pingInterval"}
                        validator={(v) => NumberValidator(v || "", 1000, 10000)}
                        helperText={"Enter a number between 1000 and 10000"}
                    >
                        <TextField variant={"standard"}/>
                    </ConfigTextFieldElement>
                </ConfigItem>
            </ConfigGroup>
            <ConfigGroup caption={"Numbers and units"}>
                <ConfigItem label={"Display units"}>
                    <ConfigSelectElement
                        basePath={""}
                        item={"displayUnits"}
                    >
                        <Select variant={"standard"}>
                            <MenuItem value={"mm"}>Millimeters</MenuItem>
                            <MenuItem value={"inch"}>Inches</MenuItem>
                        </Select>
                    </ConfigSelectElement>
                </ConfigItem>
                <ConfigItem label={"Decimal places"}>
                    <ConfigSelectElement
                        basePath={""}
                        item={"decimalPlaces"}
                    >
                        <Select variant={"standard"}>
                            <MenuItem value={"0"}>0</MenuItem>
                            <MenuItem value={"1"}>1</MenuItem>
                            <MenuItem value={"2"}>2</MenuItem>
                            <MenuItem value={"3"}>3</MenuItem>
                            <MenuItem value={"4"}>4</MenuItem>
                        </Select>
                    </ConfigSelectElement>
                </ConfigItem>
            </ConfigGroup>
            <ConfigGroup caption={"Speed and distance"}>
                <ConfigItem label={"Babystep amount"}>
                    <ConfigTextFieldElement
                        basePath={""}
                        item={"babystepAmount"}
                        validator={(v) => NumberValidator(v || "", 0.005, 0.2)}
                        helperText={"Enter a number between 0.005 and 0.2"}
                    >
                        <TextField variant={"standard"}/>
                    </ConfigTextFieldElement>
                </ConfigItem>
                <ConfigItem label={"Move feedrate"}>
                    <ConfigTextFieldElement
                        basePath={""}
                        item={"moveFeedrate"}
                        validator={(v) => NumberValidator(v || "", 200, 50000)}
                        helperText={"Enter a number between 200 and 50000"}
                    >
                        <TextField variant={"standard"}/>
                    </ConfigTextFieldElement>
                </ConfigItem>
            </ConfigGroup>
        </div>
    )
}

export default styled(withTranslation()(SettingsGeneral))((props) => {
    return {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        padding: "20px",
        gap: "20px",
        flexWrap: "wrap",
    };
});
