import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import {persistor, reduxStore} from "./ReduxStore";
import {PersistGate} from "redux-persist/integration/react";
import {CssBaseline, StyledEngineProvider} from "@mui/material";
import CustomThemeProvider from "./Themes/CustomThemeProvider";
import i18n from "./i18n";
import {I18nextProvider} from "react-i18next";
import MachineAccessWrapper from "./Components/MachineAccessWrapper";
import 'material-icons/iconfont/material-icons.css';
import ProgressSnackbar from "./Helpers/ProgressSnackbar";
import {PrimitiveAtom} from "jotai/vanilla/atom";
import {SnackbarProvider} from "notistack";
import {Atom, createStore} from "jotai";
import {Provider as JotaiProvider} from "jotai";
import "../node_modules/@xterm/xterm/css/xterm.css"

window.Buffer = window.Buffer || require("buffer").Buffer;

declare module "notistack" {
    interface VariantOverrides {
        progress: {
            atom?: Atom<unknown>;
            errorMessage?: string;
            successMessage?: string;
        }
    }
}

const breakpoints = {
    xxs: 0,
    xs: 480,
    sm: 768,
    md: 996,
    lg: 1200,
};

export const jotaiStore = createStore();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={reduxStore}>
        <JotaiProvider store={jotaiStore}>
            <PersistGate persistor={persistor} loading={null}>
                <StyledEngineProvider injectFirst={true}>
                    <CustomThemeProvider>
                        <CssBaseline enableColorScheme={true}>
                            <SnackbarProvider
                                maxSnack={3}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                autoHideDuration={5000}
                                className={"snackbar"}
                                Components={{
                                    progress: ProgressSnackbar,
                                }}
                            >
                                <Suspense fallback="loading">
                                    <I18nextProvider i18n={i18n} defaultNS="main">
                                        <MachineAccessWrapper>
                                            <App />
                                        </MachineAccessWrapper>
                                    </I18nextProvider>
                                </Suspense>
                            </SnackbarProvider>
                        </CssBaseline>
                    </CustomThemeProvider>
                </StyledEngineProvider>
            </PersistGate>
        </JotaiProvider>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
