import {Button, styled, Theme, Typography, useTheme} from "@mui/material";
import {useAppSelector} from "../../../ReduxStore";
import CodeButton from "../CodeButton";
import {withTranslation, WithTranslation} from "react-i18next";
import {Axis} from "@duet3d/objectmodel";
import {StatusType} from "../../../Machine/ModelEnums";
import {canHome, getHomeCode} from "../../../Machine/Helpers";

interface HomingControlProps extends WithTranslation {
    className?: string;
    editMode?:boolean;
}

const HomingControl = ({t, ...props}: HomingControlProps) => {
    const objectModel = useAppSelector((state) => state.objectModel);
    const uiState = useAppSelector((state) => state.uiState);
    const theme = useTheme();

    return (
        <div className={props.className}>
            <CodeButton code="G28">
                <Button
                    disabled={uiState.frozen || props.editMode}
                    sx={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                    }}
                >
                    <Typography noWrap={true}>{t("button.home.captionAll")}</Typography>
                </Button>
            </CodeButton>
            <div className={"break"}></div>

            {objectModel.current.model.move.axes.map((a) => {
                return (
                    <CodeButton key={a.letter} code={getHomeCode(a)}>
                        <Button
                            disabled={!canHome(uiState, objectModel, a) || props.editMode}
                            sx={{
                                backgroundColor: a.homed ? theme.palette.primary.main : theme.palette.buttons.notHomed,
                            }}
                        >
                            {t("main:button.home.caption", {letter: a.letter})}
                        </Button>
                    </CodeButton>
                );
            })}
        </div>
    );
};

export default styled(withTranslation()(HomingControl))((props) => {
    return {
        display: "flex",
        "@container motionpanel (width < 640px)": {
            flexWrap: "wrap",
            gap: "5px",
            ".break": {
                flexBasis: "100%",
                height: "0",
            },
        },
        "@container motionpanel (width >= 640px)": {
            display: "flex",
            flexDirection: "column",
            gap: "5px",
        },
    } as React.CSSProperties as unknown as any;
});
