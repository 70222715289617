import {Icon, IconButton, Link, styled} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {loader, Editor, Monaco, useMonaco} from "@monaco-editor/react";
import * as monaco from "monaco-editor"
import {withTranslation, WithTranslation} from "react-i18next";
import ConfirmDialog from "../ConfirmDialog";
import {gcodeFDMLanguage, gcodeCNCLanguage} from "@duet3d/monacotokens";

(window as any).MonacoEnvironment = {
    getWorker: function (_: any, label: string) {
        if (label === "json") {
            return new Worker(
                /* webpackChunkName: "monaco-json-worker" */
                new URL("monaco-editor/esm/vs/language/json/json.worker.js", import.meta.url)
            );
        }
        return new Worker(
            /* webpackChunkName: "monaco-worker" */
            new URL("monaco-editor/esm/vs/editor/editor.worker.js", import.meta.url)
        )
    }
}

loader.config({monaco});

interface MonacoEditorProps extends WithTranslation {
    className?: string;
    buffer?: string;
    language?: string;
    open: boolean;
    fullPath: string;
    onSave: (buffer: string) => void;
    onClose: () => void;
}

const MonacoEditor = (props: MonacoEditorProps) => {
    const frameRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
    const {t} = props;
    const [confirmExitOpen, setConfirmExitOpen] = useState(false);
    const [confirmSaveEmptyOpen, setConfirmSaveEmptyOpen] = useState(false);

    monaco.languages.setMonarchTokensProvider("gcode-fdm", gcodeFDMLanguage);
    monaco.languages.setMonarchTokensProvider("gcode-cnc", gcodeCNCLanguage);

    monaco.languages.register({id: "gcode-fdm"});
    monaco.languages.register({id: "gcode-cnc"});

    useEffect(() => {
        if (props.open && frameRef.current) {
            frameRef.current.className = props.className + " slide";
        }
    }, [props.className, props.open]);

    if (!props.open) {
        return <></>;
    }

    const handleEditorDidMount = (editor: monaco.editor.IStandaloneCodeEditor, monaco: Monaco) => {
        editorRef.current = editor;
    }

    return (
        <div ref={frameRef} className={props.className}>
            <div className={"header"}>
                <div><IconButton onClick={() => {
                    if (editorRef.current) {
                        const buffer = editorRef.current?.getValue();
                        if (props.buffer !== buffer) {
                            setConfirmExitOpen(true);
                        }
                        else {
                            props.onClose();
                        }
                    }
                    else {
                        props.onClose();
                    }
                }}><Icon>close</Icon></IconButton></div>
                <div style={{flex: "1"}}>{props.fullPath}</div>
                <Link target={"_blank"} sx={{textDecoration: "none", color: "inherit"}} href={"https://docs.duet3d.com/en/User_manual/Reference/Gcodes"}><div style={{display: "flex", alignItems: "center"}}><Icon>question_mark</Icon>&nbsp;{t("dialog.fileEdit.gcodeReference")}</div></Link>
                <div style={{cursor: "pointer", display: "flex", alignItems: "center"}} onClick={() => {
                    if (editorRef.current) {
                        const buffer = editorRef.current?.getValue();
                        if (buffer === "") {
                            setConfirmSaveEmptyOpen(true);
                        }
                        else {
                            if (buffer === props.buffer) {
                                props.onClose();
                            }
                            else {
                                props.onSave(buffer);
                            }
                        }
                    }
                }}><Icon>save</Icon>&nbsp;{t("dialog.fileEdit.save")}</div>
            </div>
            <Editor
                onMount={handleEditorDidMount}
                value={props.buffer}
                language={props.language}
                options={{
                    fontSize: 16,
                }}
            />
            <ConfirmDialog
                open={confirmExitOpen}
                onClose={() => {setConfirmExitOpen(false)}}
                onConfirm={() => {
                    setConfirmExitOpen(false);
                    props.onClose();
                }}
                question={t("dialog.fileEdit.confirmClose")}
            />
            <ConfirmDialog
                open={confirmSaveEmptyOpen}
                onClose={() => {
                    setConfirmSaveEmptyOpen(false);
                }}
                onConfirm={() => {
                    setConfirmSaveEmptyOpen(false);
                    props.onSave("");
                }}
                question={t("dialog.fileEdit.confirmSaveEmpty")}
            />
        </div>
    );
}

export default styled(withTranslation()(MonacoEditor))((props) => {
    return {
        position: "fixed",
        top: "100vh",
        left: "0",
        right: "0",
        height: "100vh",
        backgroundColor: props.theme.palette.background.default,
        "&.slide": {
            top: "0",
            transition: "top 0.25s"
        },
        "&>.header": {
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            height: "44px",
            backgroundColor: props.theme.palette.background.default,
            display: "flex",
            alignItems: "center",
            paddingLeft: "15px",
            paddingRight: "15px",
            boxShadow: "#d0d0d0 0px 1px 4px",
            gap: "20px",
        },
        "&>section": {
            position: "absolute",
            top: "48px",
            left: "0",
            right: "0",
            bottom: "0",
        }
    };
})
