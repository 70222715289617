import {
    Button,
    Icon,
    IconButton,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {WithTranslation, withTranslation} from "react-i18next";
import * as panelClasses from "../Panels";
import {PanelData} from "../../Reducers/PanelContainer";
import {useAppSelector} from "../../ReduxStore";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

interface DynamicPanelSelectorProps extends WithTranslation {
    className?: string;
    close: (panelType: string) => void;
}

const DynamicPanelSelector = (props: DynamicPanelSelectorProps) => {
    const theme = useTheme();
    const mobileVersion = useMediaQuery(theme.breakpoints.down("lg"));
    const cls = Object.entries(panelClasses);
    const panelMap = cls.reduce(
        (acc, cur, idx) => {
            acc[cur[0]] = cur[1];
            return acc;
        },
        {} as {[key: string]: any},
    );
    const {t} = props;

    const addPanel = (type: string) => {
        console.log(`Selected ${type}`);
        props.close(type);
    }

    if (mobileVersion) {
        return (
            <>
                <div>
                    Please select a widget to add it to the current screen
                </div>
                <div className={props.className}>
                    {Object.keys(panelMap).map((name) => {
                        return (
                            <div key={name}>
                                <Typography sx={{textAlign: "center"}}><big>{panelMap[name].defaultProps.meta?.friendlyName}</big></Typography>
                                <Typography>{panelMap[name].defaultProps.meta?.description}</Typography>
                                <div style={{position: "relative"}}>
                                    {React.createElement(panelMap[name], {
                                            panel: name,
                                            editMode: false,
                                            sx: {width: "90%", border: "1 px solid red", marginLeft: "auto", marginRight: "auto", marginTop: "10px", marginBottom: "8px"},
                                        }
                                    )}
                                    <div className={"select-overlay"}>
                                    </div>
                                    <div className={"add-caption"} style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                        <div>
                                            <IconButton onClick={() => addPanel(name)}><Icon sx={{transform: "scale(2, 2)"}}>add_circle</Icon></IconButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }

    const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
            padding: "0px",
        },
    });

    return (
        <div className={props.className}>
            {Object.keys(panelMap).map((name) => {
                let widthWanted = panelMap[name].defaultProps.minW;
                if (panelMap[name].defaultProps.maxW !== undefined) {
                    widthWanted = panelMap[name].defaultProps.maxW;
                }
                const ratio = panelMap[name].defaultProps.minW / panelMap[name].defaultProps.minH;
                const width = Math.min(widthWanted, 960);
                const height = width * ratio * 12;

                return (
                    <NoMaxWidthTooltip arrow placement={"left"} title={(
                        <div style={{
                            border: "1px solid",
                            borderColor: theme.palette.text.primary,
                            borderRadius: "4px",
                            backgroundColor: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            fontSize: "initial",
                            display: "flex",
                            flexDirection: "column",
                            padding: "4px",
                        }}>
                            {React.createElement(panelMap[name], {
                                    panel: name,
                                    editMode: false,
                                    sx: {
                                        width: `${width * 160}px`,
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        ".panel-content": {
                                            margin: "5px",
                                        },
                                    },
                                }
                            )}
                            <div className={"select-overlay"} style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                backgroundColor: theme.palette.background.default,
                                opacity: "0.6",
                                pointerEvents: "none",
                            }}>
                            </div>
                        </div>
                    )} key={name}>
                        <Button onClick={() => addPanel(name)} variant={"outlined"} sx={{display: "flex", flexDirection: "column"}}>
                            <Typography sx={{textAlign: "center"}}><big>{panelMap[name].defaultProps.meta?.friendlyName}</big></Typography>
                            <Typography>{panelMap[name].defaultProps.meta?.description}</Typography>
                            <div className={"add-button"}><Typography>Add this panel</Typography></div>
                        </Button>
                    </NoMaxWidthTooltip>
                );
            })}
        </div>
    );
}

export default styled(withTranslation()(DynamicPanelSelector))((props) => {
    return {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        ">*": {
            position: "relative",
            border: "1px solid",
            borderColor: props.theme.palette.text.primary,
            borderRadius: "4px",
            marginBottom: "4px",
            marginRight: "2px",
            marginLeft: "2px",
            padding: "5px",
            ".panel-inner": {
                position: "relative",
            },
        },
        ".select-overlay": {
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: props.theme.palette.background.default,
            opacity: "0.6",
            pointerEvents: "none",
        },
        ".add-caption": {
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            textAlign: "center"
        },
        ".add-button": {
            border: "1px solid",
            borderColor: props.theme.palette.primary.main,
            backgroundColor: props.theme.palette.primary.main,
            color: props.theme.palette.primary.contrastText,
            paddingTop: "7px",
            paddingBottom: "7px",
            paddingLeft: "12px",
            paddingRight: "12px",
            borderRadius: "6px",
            margin: "4px",
        }
    };
})
