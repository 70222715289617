import {Button, Icon, Menu, MenuItem, styled, Theme, useMediaQuery} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useAppSelector} from "../../../ReduxStore";
import {WithTranslation, withTranslation} from "react-i18next";
import CodeButton from "../../Inputs/CodeButton";
import FileUpload from "../../FileUpload";
import {useContext, useState} from "react";
import {UploadControllerCtx} from "../../MachineAccessWrapper";
import {UploadFileData, UploadType} from "../../../Utils/UploadController";
import {setUiState} from "../../../Reducers/UiState";
import MenuButton from "../../Inputs/MenuButton";
import EventListener from "../../../EventSystem/EventListener";

interface TopBarProps extends React.ComponentProps<"div">, WithTranslation {
    onMenuClick?: () => void;
    onAddClick?: () => void;
    menuRef?: React.Ref<any>;
    buttonBar?: JSX.Element;
}

const NameDisplay = (props: any) => {
    const [name, setName] = useState("");

    if (props.name?.name && name !== props.name?.name) {
        setName(props.name?.name);
    }

    return (
        <div>{name}</div>
    )
}

const TopBar = (props: TopBarProps) => {
    const {t} = props;
    const uploadController = useContext(UploadControllerCtx);
    const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

    return (
        <div className={props.className}>
            <div ref={props.menuRef} style={{lineHeight: "64px"}}><MenuIcon onClick={props.onMenuClick}/></div>
            <EventListener topic={"machine-name"} prop={"name"}><NameDisplay /></EventListener>
            <div className={"right-box"}>
                {/*<div ref={props.addButtonRef} onClick={props.onAddClick}>*/}
                {/*    <Icon>add</Icon>*/}
                {/*</div>*/}
                {smallScreen
                    ? <MenuButton
                        onSelection={(s) => {

                        }}
                        menu={
                            <Menu open={false}>
                                <MenuItem sx={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                                    <FileUpload tooltip={t("button.upload.start.title")} accept={uploadController.accept(UploadType.start) || "*"} onFiles={(files) => {
                                        uploadController.upload(files.map((f) => {
                                            return {filename: f.name, content: f} as UploadFileData;
                                        }), "/", UploadType.start);
                                    }}>
                                        <Button variant={"outlined"}><Icon>upload</Icon>{t("button.upload.start.caption")}</Button>
                                    </FileUpload>
                                </MenuItem>
                                <MenuItem>
                                    <CodeButton code={"M112\\nM999"}>
                                        <Button className={"emergency-stop"}><Icon>flash_on</Icon>{t("button.emergencyStop.caption")}</Button>
                                    </CodeButton>
                                </MenuItem>
                            </Menu>
                        }
                    >
                        <Button variant={"text"} sx={{paddingLeft: "5px", paddingRight: "2px", marginRight: "20px"}}><Icon>more_vert</Icon></Button>
                    </MenuButton>
                    : <div className={"top-buttons"} style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "15px", paddingRight: "15px"}}>
                        <FileUpload tooltip={t("button.upload.start.title")} accept={uploadController.accept(UploadType.start) || "*"} onFiles={(files) => {
                            uploadController.upload(files.map((f) => {
                                return {filename: f.name, content: f} as UploadFileData;
                            }), "/", UploadType.start);
                        }}>
                            <Button variant={"outlined"}><Icon>upload</Icon>{t("button.upload.start.caption")}</Button>
                        </FileUpload>
                        <CodeButton code={"M112\\nM999"}>
                            <Button className={"emergency-stop"}><Icon>flash_on</Icon>{t("button.emergencyStop.caption")}</Button>
                        </CodeButton>
                    </div>
                }
                {props.buttonBar}
            </div>
        </div>
    );
}

export default styled(withTranslation()(TopBar))((props) => {
    return {
        display: "flex",
        flexDirection: "row",
        alignContent: "stretch",
        boxShadow: "2px 2px 8px #c0c0c0",
        fontSize: "16pt",
        ">*": {
            lineHeight: "56px",
            textWrap: "nowrap",
            marginLeft: "20px",
            marginRight: "20px",
            minWidth: 0,
            flexShrink: 0,
        },
        ">div:last-of-type": {
            flexGrow: "100",
            display: "flex",
            justifyContent: "right",
        },
        ".emergency-stop": {
            backgroundColor: props.theme.palette.error.main,
            color: props.theme.palette.error.contrastText,
        },
    } as React.CSSProperties as any; // Make intellisense work
})
