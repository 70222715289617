import React, {useEffect, useState} from "react";
import PubSub from "pubsub-js";

interface EventListenerFilters {
    [key: string]: string | string[];
}

interface EventListenerProps {
    children?: React.ReactNode;
    topic: string | string[];
    prop: string;
    filters?: EventListenerFilters;
}

function EventListener(props: EventListenerProps) {
    const [lastMessage, setLastMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (lastMessage !== undefined) {
            setLastMessage(undefined);
        }
    }, [lastMessage]);

    useEffect(() => {
        let listener: string[] = [];

        function onMessage(topic: any, message: any) {
            let msg = JSON.parse(message);

            if (checkFilters(msg)) {
                setLastMessage(message);
            }
        }

        function checkFilters(msg: any) {
            if (props.filters === undefined || Object.keys(props.filters).length === 0) {
                return true;
            }

            let accepted = false;

            // eslint-disable-next-line array-callback-return
            Object.keys(props.filters).map((k) => {
                if (msg.hasOwnProperty(k)) {
                    // @ts-ignore
                    if (Array.isArray(props.filters[k])) {
                        // @ts-ignore
                        // eslint-disable-next-line array-callback-return
                        props.filters[k].map((v) => {
                            if (msg[k] === v) {
                                accepted = true;
                            }
                        });
                    } else {
                        // @ts-ignore
                        if (msg[k] === props.filters[k]) {
                            accepted = true;
                        }
                    }
                }
            });

            return accepted;
        }

        if (Array.isArray(props.topic)) {
            // eslint-disable-next-line array-callback-return
            props.topic.map((t) => {
                listener.push(PubSub.subscribe(t, onMessage));
            });
        } else {
            listener.push(PubSub.subscribe(props.topic, onMessage));
        }

        return () => {
            // eslint-disable-next-line array-callback-return
            listener.map((l) => {
                PubSub.unsubscribe(l);
            });
        };
    }, [props.filters, props.topic]);

    // let childProps: any = {};
    // childProps[props.prop] = JSON.parse(lastMessage || "{}");
    return (
        <>
            {React.Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, lastMessage !== undefined ? {[props.prop]: JSON.parse(lastMessage)} : undefined);
            })}
        </>
    );
}

export default EventListener;
