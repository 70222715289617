import {styled, Switch as MuiSwitch, SwitchProps, useTheme} from "@mui/material";

const Switch = (props: SwitchProps): JSX.Element => {
    const theme = useTheme();

    return (
        <MuiSwitch
            sx={{
                "& .MuiSwitch-thumb": {
                    color: theme.palette.primary.main,
                },
                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                    backgroundColor: theme.palette.primary.main,
                },
            }}
            {...props}
        ></MuiSwitch>
    );
};

export default styled(Switch)``;
