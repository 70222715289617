import React, {useContext} from "react";
import {styled} from "@mui/material";
import {CustomThemeContext} from "../../../Themes/CustomThemeProvider";

interface ThemeSwitcherProps {
    className?: string;
    children?: React.ReactNode;
}

const ThemeSwitcher = (props: ThemeSwitcherProps): JSX.Element => {
    const {currentTheme, setTheme} = useContext(CustomThemeContext);

    function handleClick(e: any) {
        if (setTheme) {
            if (currentTheme === "light") {
                setTheme("dark");
            } else {
                setTheme("light");
            }
        }
    }

    return (
        // <Button sx={{color: "white"}} className={"button"} onClick={handleClick}>
        //     {props.children}
        // </Button>
        <>
            {React.Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, {
                    onClick: handleClick,
                    currenttheme: currentTheme,
                });
            })}
        </>
    );
}

export default styled(ThemeSwitcher)``;
