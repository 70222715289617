import {settings, toolChangeParameter} from "../../../Machine/settings";
import {Heater, HeaterState, ObjectModel, SpindleState, Tool} from "@duet3d/objectmodel";
import {useContext, useRef, useState} from "react";
import {useAppSelector} from "../../../ReduxStore";
import {DisconnectedError} from "@duet3d/connectors";
import {ConnectionCtx} from "../../MachineAccessWrapper";
import {WithTranslation, withTranslation} from "react-i18next";
import {displaySensorValue} from "../../../Utils/display";
import {styled} from "@mui/material";
import ToolPanel from "../../Panels/ToolPanel";
import ToolControl from "../../Inputs/ToolControl/ToolControl";

interface ToolsListProps extends WithTranslation{
    className?: string;
}

const ToolsList = (props: ToolsListProps) => {
    const objectModelState = useAppSelector(state => state.objectModel);
    const model = objectModelState.current.model;
    const currentToolNumber = model.state.currentTool;
    const currentTool = model.tools[currentToolNumber];
    const toolChangeParam = toolChangeParameter()
    const uiState = useAppSelector(state => state.uiState);
    const uiFrozen = uiState.frozen;
    const connection = useContext(ConnectionCtx);

    // Tool display
    const toolsToDisplay = () => {
        if (!settings.groupTools) {
            return model.tools.filter((tool: any) => tool !== null) as Array<Tool>;
        }

        const tools: Array<Tool> = [];
        for (const item of model.tools) {
            if (item !== null) {
                let equalToolFound = false;

                for (let i = 0; i < tools.length; i++) {
                    const tool = tools[i];
                    if ((item.extruders.length === tool.extruders.length && item.extruders.every((extruder, index) => extruder === tool.extruders[index])) &&
                        (item.heaters.length === tool.heaters.length && item.heaters.every((heater, index) => heater === tool.heaters[index])) &&
                        (item.offsets.length === tool.offsets.length && item.offsets.every((offset, index) => offset === tool.offsets[index])) &&
                        item.spindle === tool.spindle)
                    {
                        // Tool is identical
                        equalToolFound = true;
                        if (item.number === model.state.currentTool) {
                            // If another tool is selected, prefer the displayed tool
                            tools[i] = item;
                        }
                    }
                }

                if (!equalToolFound) {
                    tools.push(item);
                }
            }
        }
        return tools;
    }


    const isToolCollapsed = (tool: Tool) => {
        if (toolsToDisplay.length < model.tools.length) {
            for (const item of model.tools) {
                if (item !== null && item !== tool) {
                    if ((item.extruders.length === tool.extruders.length && item.extruders.every((extruder, index) => extruder === tool.extruders[index])) &&
                        (item.heaters.length === tool.heaters.length && item.heaters.every((heater, index) => heater === tool.heaters[index])) &&
                        (item.offsets.length === tool.offsets.length && item.offsets.every((offset, index) => offset === tool.offsets[index])) &&
                        item.spindle === tool.spindle)
                    {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    function getCollapsedTools(tool: Tool) {
        const tools: Array<Tool> = [];
        for (const item of model.tools) {
            if (item !== null &&
                (item.extruders.length === tool.extruders.length && item.extruders.every((extruder, index) => extruder === tool.extruders[index])) &&
                (item.heaters.length === tool.heaters.length && item.heaters.every((heater, index) => heater === tool.heaters[index])) &&
                (item.offsets.length === tool.offsets.length && item.offsets.every((offset, index) => offset === tool.offsets[index])) &&
                item.spindle === tool.spindle)
            {
                // Tool is identical
                tools.push(item);
            }
        }
        return tools;
    }

    const toolClick = async(tool: Tool) => {
        if (uiFrozen) {
            return;
        }

        try {
            if (model.state.currentTool === tool.number) {
                // Deselect current tool
                await connection.sendCode("T-1" + toolChangeParam, false)
            } else {
                // Select new tool
                await connection.sendCode(`T${tool.number}${toolChangeParam}`, false);
            }
        } catch (e) {
            if (!(e instanceof DisconnectedError)) {
                console.log(e);
            }
        }
    }

// Tool heaters
    return (
        <div className={props.className}>
            {toolsToDisplay().map((tool, idx) => {
                if (isToolCollapsed(tool)) {
                    return (
                        <div key={idx}>
                            <ToolControl
                                toolClick={(tool) => {
                                    model.tools[tool] !== null && toolClick(model.tools[tool] as Tool);
                                }}
                                tool={getCollapsedTools(tool)}
                                index={idx}
                                model={model}
                                currentTool={currentTool}
                            />
                        </div>
                    );
                }
                return (
                    <div key={idx}>
                        <ToolControl
                            toolClick={(tool) => {
                                model.tools[tool] !== null && toolClick(model.tools[tool] as Tool);
                            }}
                            tool={[tool]}
                            index={idx}
                            model={model}
                            currentTool={currentTool}
                        />
                    </div>
                );
            })}
        </div>
    );
}

export default styled(withTranslation()(ToolsList))((props) => {
    return {

    };
});

