import {withTranslation} from "react-i18next";
import {LayoutBase} from "../../../Reducers/PanelContainer";
import {Button, styled, Typography} from "@mui/material";
import RadioButtonBar from "../../Inputs/RadioButtonBar";
import {settings} from "../../../Machine/settings";
import {reduxStore, useAppDispatch, useAppSelector} from "../../../ReduxStore";
import {setUiState} from "../../../Reducers/UiState";
import i18n from "../../../i18n";
import PanelHeader from "../PanelComponents/PanelHeader";
import EventListener from "../../../EventSystem/EventListener";
import {useContext, useState} from "react";
import {MachineStatus, Tool} from "@duet3d/objectmodel";
import MixButtonBar from "../../Inputs/MixButtonBar";
import {ConnectionCtx} from "../../MachineAccessWrapper";
import {NumberValidator, saveSettings} from "../../../Machine/Helpers";

const ExtrusionPanel = (props: any) => {
    const uiState = useAppSelector((state) => state.uiState);
    const dispatch = useAppDispatch();
    const model = useAppSelector((state) => state.objectModel).current.model;
    const currentToolIndex = model.state.currentTool;
    const currentTool = model.tools[currentToolIndex] || null;
    const connection = useContext(ConnectionCtx);
    const [busy, setBusy] = useState(false);

    const {t} = props;

    if (
        (currentTool === null || currentTool.extruders.length < 2) &&
        (!uiState.extrudeMix || uiState.extrudeMix.length !== 1 || uiState.extrudeMix[0] !== "mix")) {
        dispatch(setUiState({...uiState, extrudeMix: ["mix"]}));
    }

    const canMove = (temp: number): boolean => {
        return (!busy && model.state.status !== MachineStatus.off &&
            model.state.status !== MachineStatus.pausing &&
            model.state.status !== MachineStatus.processing &&
            model.state.status !== MachineStatus.resuming &&
            (currentTool !== null) && (currentTool.extruders.length > 0) &&
            !currentTool.heaters.some(heaterNumber => {
                if (heaterNumber >= 0 && heaterNumber < model.heat.heaters.length && model.heat.heaters[heaterNumber] !== null) {
                    const heaterSensor = model.heat.heaters[heaterNumber]!.sensor;
                    if (heaterSensor >= 0 && heaterSensor < model.sensors.analog.length) {
                        const sensor = model.sensors.analog[heaterSensor];
                        return (sensor === null) || ((sensor.lastReading !== null) && (sensor.lastReading < temp));
                    }
                }
                return true;
            }, this));
    }

    const canExtrude = () => {
        return canMove(model.heat.coldExtrudeTemperature);
    }

    const canRetract = () => {
        return canMove(model.heat.coldRetractTemperature);
    }

    const buttonClicked = async (extrude: boolean) => {
        if (!currentTool || currentTool.extruders.length === 0) {
            return;
        }

        let amounts;
        if (uiState.extrudeMix[0] === "mix") {
            // Split total amount to extrude evenly
            amounts = [uiState.extrudeAmount];
        } else {
            // Extrude given amount via each selected extruder drive
            amounts = currentTool.extruders.map(extruder => uiState.extrudeMix.includes(extruder) ? uiState.extrudeAmount : 0);
        }

        setBusy(true);
        try {
            const amount = amounts.map(amount => extrude ? amount : -amount).join(":");
            await connection.sendCode(`M120\nM83\nG1 E${amount} F${uiState.extrudeFeedrate * 60} M400\nM121`, false);
            await connection.sendCode("M115", false);
        } catch (e) {
            // handled before we get here
        }
        setBusy(false);
    }

    return (
        <div className={`${props.className} panel-inner`}>
            <PanelHeader captionId={"panel.extrude.caption"} panelName={"extrusion"} />
            <div className={"panel-content"}>
                <div className={"flex-container"}>
                    {currentTool && currentTool.extruders.length > 1 &&
                        <div className={"extrusion-mix-box"}>
                            <Typography noWrap={true}>{t("panel.extrude.mixRatio")}</Typography>
                            <MixButtonBar
                                editMode={props.editMode}
                                className={"extrusion-mix"}
                                onChange={(v) => {
                                    dispatch(setUiState({...uiState, extrudeMix: v}));
                                }}
                                values={(["mix"] as (number | "mix")[]).concat(currentTool.extruders)}
                                selected={uiState.extrudeMix || ["mix"]}
                            />
                        </div>
                    }
                    <div className={"extrusion-amount-box"}>
                        <Typography noWrap={true}>{t("panel.extrude.amount", {0: settings.feedUnit})}</Typography>
                        <RadioButtonBar
                            disabled={currentTool === null}
                            editMode={props.editMode}
                            className={"extrusion-amount"}
                            onChange={(v) => {
                                dispatch(setUiState({...uiState, extrudeAmount: Number(v)}));
                            }}
                            values={settings.extruderAmounts}
                            selected={uiState.extrudeAmount}
                            title={t("dialog.editExtrusionAmount.title")}
                            prompt={t("dialog.editExtrusionAmount.prompt")}
                            onEdit={(v) => {
                                settings.extruderAmounts = v.map(Number);
                                saveSettings();
                            }}
                            validator={(v) => NumberValidator(v, 0, 100)}
                        />
                    </div>
                    <div className={"horizontal-box"}>
                        <div className={"extrusion-rate"}>
                            <Typography noWrap={true}>
                                {t("panel.extrude.feedrate", {0: settings.feedRateUnit})}
                            </Typography>
                            <RadioButtonBar
                                disabled={currentTool === null}
                                editMode={props.editMode}
                                onChange={(v) => {
                                    dispatch(setUiState({...uiState, extrudeFeedrate: Number(v)}));
                                }}
                                values={settings.extruderFeedrates}
                                selected={uiState.extrudeFeedrate}
                                title={t("dialog.editExtrusionFeedrate.title")}
                                prompt={t("dialog.editExtrusionFeedrate.prompt")}
                                onEdit={(v) => {
                                    settings.extruderFeedrates = v.map(Number);
                                    saveSettings();
                                }}
                                validator={(v) => NumberValidator(v, 0, 50)}
                            />
                        </div>
                        <div className={"button-box"}>
                            <Button
                                disabled={props.editMode || currentTool === null || !canRetract()}
                                onClick={() => buttonClicked(false)}
                            >Retract</Button>
                            <Button
                                disabled={props.editMode || currentTool === null || !canExtrude()}
                                onClick={() => buttonClicked(true)}
                            >Extrude</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ExtrusionPanelWrapped = styled(withTranslation()(ExtrusionPanel))((props) => {
    return {
        userSelect: "none",
        containerType: "inline-size",
        containerName: "ExtrusionPanel",
        ".button-box": {
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            "& > *": {
                paddingTop: "0",
                paddingBottom: "0",
            },
            flexGrow: "1",
        },
        ".extrusion-amount-box": {
            flexGrow: "1",
        },
        ".extrusion-rate": {
            flexGrow: "1",
        },
        ".horizontal-box": {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            gap: "10px",
            flexGrow: "1",
            flexWrap: "wrap",
        },
        ".flex-container": {
            display: "flex",
            alignItems: "stretch",
            flexDirection: "column",
            gap: "10px",
        },
        "@container ExtrusionPanel (width > 617px)": {
            ".flex-container": {
                flexDirection: "row",
            },
        },
    } as React.CSSProperties as unknown as any;
});

ExtrusionPanelWrapped.defaultProps = {
    minW: 2,
    minH: 4,
    meta: {
        friendlyName: i18n.t('panel.extrude.friendlyName'),
        description: i18n.t('panel.extrude.description'),
    }
} as LayoutBase;

export default ExtrusionPanelWrapped;
