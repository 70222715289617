import {styled} from "@mui/material";
import {WithTranslation, withTranslation} from "react-i18next";
import {LayoutBase} from "../../../Reducers/PanelContainer";
import JogControl from "../../Inputs/JogControl";
import HomingControl from "../../Inputs/HomingControl";
import i18n from "../../../i18n";
import PanelHeader from "../PanelComponents/PanelHeader";

const MotionPanel = (props: any) => {
    const {t} = props;

    return (
        <div className={`${props.className} panel-inner`}>
            <PanelHeader captionId={"panel.movement.caption"} panelName={"movement"} />
            <div className={"panel-content"}>
                <div className={"axes-container"}>
                    <HomingControl editMode={props.editMode}/>
                    <JogControl editMode={props.editMode}/>
                </div>
            </div>
        </div>
    );
};

const MotionPanelWrapped = styled(withTranslation()(MotionPanel))((props) => {
    return {
        userSelect: "none",
        containerType: "inline-size",
        containerName: "motionpanel",
        ".axes-container": {
            display: "flex",
            gap: "5px",
        },
        "@container motionpanel (width < 640px)": {
            ".axes-container": {
                alignItems: "stretch",
                flexDirection: "column",
            },
        },
        "@container motionpanel (width >= 640px)": {
            ".axes-container": {
                alignItems: "flex-start",
            },
        },
    } as React.CSSProperties as unknown as any;
});

MotionPanelWrapped.defaultProps = {
    minW: 4,
    minH: 8,
    meta: {
        friendlyName: i18n.t('panel.movement.friendlyName'),
        description: i18n.t('panel.movement.description'),
    },
} as LayoutBase;

export default MotionPanelWrapped;
