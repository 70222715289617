import {WithTranslation, withTranslation} from "react-i18next";
import {styled} from "@mui/material";
import {Heater, HeaterState} from "@duet3d/objectmodel";
import {useAppSelector} from "../../../ReduxStore";
import {settings} from "../../../Machine/settings";
import {useContext, useState} from "react";
import {ConnectionCtx} from "../../MachineAccessWrapper";
import {displaySensorValue} from "../../../Utils/display";
import BedControl from "../../Inputs/BedControl";

interface BedsListProps extends WithTranslation {
    className?: string;
    type: "bed" | "chamber";
}

const BedsList = (props: BedsListProps) => {
    const uiState = useAppSelector(state => state.uiState);
    const model = useAppSelector(state => state.objectModel).current.model;
    const uiFrozen = uiState.frozen;
    const {t} = props;
    const connection = useContext(ConnectionCtx);

    // Settings
    const singleControl = props.type === "bed" ? settings.groupBedHeaters : settings.groupChamberHeaters;

    // Heater abstraction
    const heaterItems = (() => {
        const heaterIndices = (props.type === "bed") ? model.heat.bedHeaters : model.heat.chamberHeaters;
        const heaterList: Array<{ index: number, heater: Heater, heaterIndex: number }> = [];
        for (let index = 0; index < heaterIndices.length; index++) {
            const heaterIndex = heaterIndices[index];
            if (heaterIndex >= 0 && heaterIndex < model.heat.heaters.length) {
                const heater = model.heat.heaters[heaterIndex];
                if (heater !== null) {
                    heaterList.push({
                        index,
                        heater,
                        heaterIndex
                    });
                }
            }
        }
        return heaterList;
    })();

    const firstHeater = (heaterItems.length > 0) ? heaterItems[0].heater : null;

    if (firstHeater === null) { // Are there any bed heaters at all?
        return <></>
    }

    if (singleControl && heaterItems.length > 1) {
        return (
            <BedControl type={props.type} heaters={heaterItems} />
        )
    }
    return (
        <div className={props.className}>
            {heaterItems.map((h) => {
                return <BedControl type={props.type} key={h.heaterIndex} heaters={[h]} totalHeaters={heaterItems.length}/>
            })}
        </div>
    )
}

export default styled(withTranslation()(BedsList))((props) => {
    return {

    };
});
