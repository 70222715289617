import {WithTranslation, withTranslation} from "react-i18next";
import {Button, Icon as MuiIcon, Menu, styled} from "@mui/material";
import {plotColors} from "../../../Helpers/PlotColors";
import HeaterSet from "../HeaterSet";
import {settings} from "../../../Machine/settings";
import Icon from "@mdi/react";
import {mdiHeatPumpOutline, mdiRadiator, mdiThermometerLines} from "@mdi/js";
import MenuButton from "../MenuButton";
import {AnalogSensor} from "@duet3d/objectmodel";
import {displaySensorValue} from "../../../Utils/display";
import Switch from "../Switch";
import i18n from "../../../i18n";
import {saveSettings} from "../../../Machine/Helpers";

export interface ExtraSensor {
    sensor: AnalogSensor;
    index: number;
}

interface ExtraSensorControlProps extends WithTranslation {
    className?: string;
    sensor: ExtraSensor;
    index: number;
}

const ExtraSensorControl = (props: ExtraSensorControlProps) => {
    const {t} = props;

    return (
        <div className={props.className}>
            <div className={"heaterBox"}>
                <div className={"heaterName"}
                     style={{
                         fontWeight: "bold",
                         color: plotColors[props.sensor.index],
                         cursor: "pointer"
                     }}
                >
                    {props.sensor.sensor.name}
                </div>
                <div className={"heaterTemp"}>{displaySensorValue(props.sensor.sensor)}</div>
                <div className={"heaterSwitch"}>
                    <Switch
                        onChange={(e, v) => {
                            if (v) {
                                if (!settings.displayedExtraTemperatures.includes(props.sensor.index)) {
                                    settings.displayedExtraTemperatures.push(props.sensor.index);
                                    saveSettings();
                                }
                            }
                            else {
                                if (settings.displayedExtraTemperatures.includes(props.sensor.index)) {
                                    settings.displayedExtraTemperatures = settings.displayedExtraTemperatures.filter(v => v !== props.sensor.index);
                                    saveSettings();
                                }
                            }
                        }}
                        checked={settings.displayedExtraTemperatures.includes(props.sensor.index)}
                    />
                </div>
            </div>

            <div className={"toolName"}>
                <div
                    className={"toolHeading"}
                    // onClick={() => {
                    //     props.toolClick(props.tool[0].number);
                    // }}
                >
                    <Icon path={mdiThermometerLines} size={"1em"}/>
                    {t("panel.temperature.sensor", {0: props.index})}
                </div>
            </div>
        </div>
    );
}

export default styled(withTranslation()(ExtraSensorControl))((props) => {
    return {
        position: "relative",
        borderTop: `1px solid ${props.theme.palette.primary.light}`,
        containerType: "inline-size",
        containerName: "toolContainer",
        marginTop: "0.37rem",
        gap: "7px",
        ".heaterBox": {
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            flexDirection: "row",
            gap: "10px",
            marginRight: "10px",
            paddingTop: "0.5rem",
        },
        ".toolName": {
            position: "absolute",
            top: "-0.5rem",
            left: "7px",
            fontSize: "0.75rem",
            height: "1rem",
            backgroundColor: props.theme.palette.background.paper,
            paddingLeft: "7px",
            paddingRight: "7px",
        },
        ".toolHeading": {
            color: props.theme.palette.primary.main,
            cursor: "pointer",
        },
        ".heaterBox>div.heaterName": {
            flexBasis: "0",
            flexGrow: "25",
            paddingLeft: "20px",
        },
        ".heaterBox>div.heaterTemp": {
            flexBasis: "0",
            flexGrow: "20",
            minWidth: "4rem",
            textAlign: "right",
            whiteSpace: "nowrap",
        },
        ".heaterBox>div.heaterSwitch": {
            flexBasis: "0",
            flexGrow: "20",
            minWidth: "4rem",
            textAlign: "right",
            whiteSpace: "nowrap",
            "&::before": {
                display: "block",
                position: "absolute",
                content: `'${i18n.t("panel.tools.extra.showInChart")}'`,
                top: "2px",
                right: "10px",
                fontSize: "0.75rem",
                color: props.theme.palette.primary.main,
                overflow: "visible",
            },
        },
    };
});
