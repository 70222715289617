export function resolvePath(path: string | string[], obj: any, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);
    if (properties[0] === "")
        return obj;
    return properties.reduce((prev, curr) => {
        if (prev && prev[curr])
            return prev[curr];
        prev[curr] = {};
        return prev[curr];
    }, obj);
}
