import i18n from "../i18n";
import {Localization, deDE, enUS, esES, frFR, jaJP, plPL, ptBR, ruRU, trTR} from "@mui/material/locale";
import {ThemeOptions} from "@mui/material";
import {deDE as dg_deDE, enUS as dg_enUS, esES as dg_esES, frFR as dg_frFR, jaJP as dg_jaJP, plPL as dg_plPL, ptBR as dg_ptBR, ruRU as dg_ruRU, trTR as dg_trTR} from "@mui/x-data-grid/locales"

const languages: {[key: string]: Localization} = {
    de: {...deDE, components: {...deDE.components, ...dg_deDE.components}},
    en_GB: {...enUS, components: {...enUS.components, ...dg_enUS.components}},
    en_US: {...enUS, components: {...enUS.components, ...dg_enUS.components}},
    es: {...esES, components: {...esES.components, ...dg_esES.components}},
    fr: {...frFR, components: {...frFR.components, ...dg_frFR.components}},
    ja: {...jaJP, components: {...jaJP.components, ...dg_jaJP.components}},
    pl: {...plPL, components: {...plPL.components, ...dg_plPL.components}},
    pt_BR: {...ptBR, components: {...ptBR.components, ...dg_ptBR.components}},
    ru: {...ruRU, components: {...ruRU.components, ...dg_ruRU.components}},
    tr: {...trTR, components: {...trTR.components, ...dg_trTR.components}},
};

export const getCurrentLocale = () => {
    let current = i18n.language;
    current = current.replace("-", "_");
    if (!languages.hasOwnProperty(current)) {
        if (current.indexOf("_") !== -1) {
            current = current.substring(0, current.indexOf("_") - 1);
            if (!languages.hasOwnProperty(current)) {
                current = "en_GB";
            }
        } else {
            current = "en_GB";
        }
    }
    return languages[current];
};

export const themeCommon: ThemeOptions = {
    breakpoints: {
        values: {
            xxs: 0,
            xs: 480,
            sm: 640,
            md: 960,
            lg: 1280,
            xl: 1600,
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: "contained",
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
    },
    typography: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
            "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    },
};
