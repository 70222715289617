import {withTranslation} from "react-i18next";
import {styled, Tooltip} from "@mui/material";
import {LayoutBase} from "../../../Reducers/PanelContainer";
import StatusLabel from "../../Misc/StatusLabel";
import {useAppSelector} from "../../../ReduxStore";
import {Axis, Board, MachineMode, Probe, ProbeType} from "@duet3d/objectmodel";
import {settings, DashboardMode} from "../../../Machine/settings";
import {display, displayMoveSpeed} from "../../../Utils/display";
import {useState} from "react";
import i18n from "../../../i18n";
import PanelHeader from "../PanelComponents/PanelHeader";

const StatusPanel = (props: any) => {
    const objectModel = useAppSelector(state => state.objectModel);
    const {t} = props;
    const [displayVolumetricFlow, setDisplayVolumetricFlow] = useState(false);

    const isFFForUnset = (): boolean => {
        if (settings.dashboardMode === DashboardMode.default) {
            return !objectModel.current.model.state.machineMode || objectModel.current.model.state.machineMode === MachineMode.fff;
        }
        return settings.dashboardMode === DashboardMode.fff;
    }

    const virtualEPos = (): number => {
        return objectModel.current.model.move.virtualEPos;
    }

    const volumetricFlow = (): number => {
        if (objectModel.current.model.state.currentTool >= 0 && objectModel.current.model.state.currentTool < objectModel.current.model.tools.length) {
            const selectedTool = objectModel.current.model.tools[objectModel.current.model.state.currentTool];
            if (selectedTool !== null) {
                // Get the average extruder diameter x mix ratio
                let numExtruders = 0, filamentArea = 0;
                for (let i = 0; i < selectedTool.extruders.length; i++) {
                    const extruderIndex = selectedTool.extruders[i];
                    if (extruderIndex >= 0 && extruderIndex < objectModel.current.model.move.extruders.length) {
                        const extruder = objectModel.current.model.move.extruders[extruderIndex];
                        if (extruder !== null) {
                            filamentArea += selectedTool.mix[i] * (Math.PI * Math.pow((extruder.filamentDiameter / 2), 2));
                            numExtruders++;
                        }
                    }
                }

                // Compute volumetric flow
                if (numExtruders > 0) {
                    filamentArea /= numExtruders;
                    return filamentArea * objectModel.current.model.move.currentMove.extrusionRate;
                }
            }
        }
        return NaN;
    }

    const fanRPM = (): Array<{ name: string, rpm: number }> => {
        return objectModel.current.model.fans
            .filter(fan => (fan !== null) && (fan.rpm >= 0))
            .map((fan, index) => ({
                name: fan!.name || t("panel.fan.fan", [index]),
                rpm: fan!.rpm
            }), this);
    }

    const validProbes = (): Array<Probe> => {
        return objectModel.current.model.sensors.probes.filter((probe) => (probe !== null) && (probe.type !== ProbeType.none)) as Array<Probe>;
    }

    const mainboard = (): Board | null => {
        return objectModel.current.model.boards.find(board => !board.canAddress) ?? null;
    }

    const sensorsPresent = (): boolean => {
        return ((mainboard() !== null) && ((mainboard()?.vIn !== null) || (mainboard()?.v12 !== null) || (mainboard()?.mcuTemp !== null))) ||
            (fanRPM().length > 0) || (validProbes().length > 0);
    }

    const visibleAxes = (): Array<Axis> => {
        return objectModel.current.model.move.axes.filter(axis => axis.visible);
    }

    const isFilamentSensorPresent = (extruderIndex: number) => {
        return (extruderIndex >= 0) && (extruderIndex < objectModel.current.model.sensors.filamentMonitors.length) &&
            (objectModel.current.model.sensors.filamentMonitors[extruderIndex] !== null) && objectModel.current.model.sensors.filamentMonitors[extruderIndex]!.enabled &&
            (typeof (objectModel.current.model.sensors.filamentMonitors[extruderIndex] as any).filamentPresent === "boolean");
    }

    const isFilamentPresent = (extruderIndex: number) => {
        return (objectModel.current.model.sensors.filamentMonitors[extruderIndex] as any).filamentPresent;
    }

    const formatProbeValues = (values: Array<number>) => {
        if (values.length === 1) {
            return values[0];
        }
        return `${values[0]} (${values.slice(1).join(", ")})`;
    }

    const isValidProbe = (probe: Probe | null) => {
        return (probe !== null) && (probe.type !== ProbeType.none);
    }

    return (
        <div className={`${props.className} panel-inner`}>
            <PanelHeader captionId={"panel.status.caption"} panelName={"status"} />
            <div className={"panel-content"}>
                <div className={"header-container"}>
                    <div className={"left-dummy"}></div>
                    <div className={"label"}>
                        <StatusLabel />
                    </div>
                    <div className={"mode"}>{t('panel.status.mode', {0: objectModel.current.model.state.machineMode})}</div>
                </div>
                {(sensorsPresent() || (visibleAxes().length + objectModel.current.model.move.extruders.length > 0))
                    ? <>
                        {visibleAxes().length > 0 &&
                            <div className={"axes-container"}>
                                <div className={"axes-header"}>
                                    {t('panel.status.toolPosition')}
                                </div>
                                <div className={"axes-wrapper"}>
                                    {objectModel.current.model.move.axes.filter(a => a.visible).map((a) => {
                                        return (
                                            <div key={a.letter} className={"axis"}>
                                                <div className={a.letter.toLowerCase()}>{a.letter.toUpperCase()}</div>
                                                <div>{a.machinePosition?.toFixed(1)}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                        {objectModel.current.model.move.extruders.length > 0 &&
                            <div className={"extruder-container"}>
                                <div className={"extruder-header"}>
                                    {t('panel.status.extruders')}
                                </div>
                                <div className={"extruder-wrapper"}>
                                    {objectModel.current.model.move.extruders.reduce((acc, cur, idx) => {
                                        acc.push(
                                            <div key={idx} className={"extruder"}>
                                                <div >{t("panel.status.extruderDrive", {0: idx.toString()})}</div>
                                                <div>{cur.position?.toFixed(1)}</div>
                                            </div>

                                        );
                                        return acc;
                                    }, [] as JSX.Element[])}
                                </div>
                            </div>
                        }
                        {(isFinite(objectModel.current.model.move.currentMove.requestedSpeed) || isFinite(objectModel.current.model.move.currentMove.topSpeed)) &&
                            <div className={"speeds-container"}>
                                <div className={"speeds-header"}>
                                    {t('panel.status.speeds')}
                                </div>
                                <div className={"speeds-wrapper"}>
                                    {isFinite(objectModel.current.model.move.currentMove.requestedSpeed) &&
                                        <div className={"speed"}>
                                            <div >{t("panel.status.requestedSpeed")}</div>
                                            <div>{displayMoveSpeed(objectModel.current.model.move.currentMove.requestedSpeed)}</div>
                                        </div>
                                    }
                                    {isFinite(objectModel.current.model.move.currentMove.topSpeed) &&
                                        <div className={"speed"}>
                                            <div >{t("panel.status.topSpeed")}</div>
                                            <div>{displayMoveSpeed(objectModel.current.model.move.currentMove.topSpeed)}</div>
                                        </div>
                                    }
                                    {(isFinite(objectModel.current.model.move.currentMove.extrusionRate) && isFFForUnset()) &&
                                        <div className={"speed"}>
                                            <div onClick={() => setDisplayVolumetricFlow(!displayVolumetricFlow)}>
                                                {displayVolumetricFlow
                                                    ? t("panel.status.volumetricFlow")
                                                    : t("panel.status.extrusionRate")
                                                }
                                            </div>
                                            <div>
                                                {displayVolumetricFlow
                                                    ? display(volumetricFlow(), 1, "mm³/s")
                                                    : displayMoveSpeed(objectModel.current.model.move.currentMove.extrusionRate)
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {sensorsPresent() &&
                            <div className={"sensors-container"}>
                                <div className={"sensors-header"}>
                                    {t('panel.status.sensors')}
                                </div>
                                <div className={"sensors-wrapper"}>
                                    <>
                                        {mainboard() !== null &&
                                            <>
                                                {mainboard()?.vIn !== null &&
                                                    <>
                                                        <Tooltip title={
                                                            t('panel.status.minMax',
                                                                {
                                                                    0: display(mainboard()?.vIn?.min, 1, 'V'),
                                                                    1: display(mainboard()?.vIn?.max, 1, 'V'),
                                                                })
                                                        }>
                                                            <div className={"sensor"}>
                                                                <div >{t("panel.status.vIn")}</div>
                                                                <div>{display(mainboard()?.vIn?.current)}</div>
                                                            </div>
                                                        </Tooltip>
                                                    </>
                                                }
                                                {mainboard()?.v12 !== null &&
                                                    <>
                                                        <Tooltip title={
                                                            t('panel.status.minMax',
                                                                {
                                                                    0: display(mainboard()?.v12?.min, 1, 'V'),
                                                                    1: display(mainboard()?.v12?.max, 1, 'V'),
                                                                })
                                                        }>
                                                            <div className={"sensor"}>
                                                                <div >{t("panel.status.v12")}</div>
                                                                <div>{display(mainboard()?.v12?.current)}</div>
                                                            </div>
                                                        </Tooltip>
                                                    </>
                                                }
                                                {mainboard()?.mcuTemp !== null &&
                                                    <>
                                                        <Tooltip title={
                                                            t('panel.status.minMax',
                                                                {
                                                                    0: display(mainboard()?.mcuTemp?.min, 1, 'V'),
                                                                    1: display(mainboard()?.mcuTemp?.max, 1, 'V'),
                                                                })
                                                        }>
                                                            <div className={"sensor"} style={{minWidth: "150px"}}>
                                                                <div >{t("panel.status.mcuTemp")}</div>
                                                                <div>{display(mainboard()?.mcuTemp?.current)}</div>
                                                            </div>
                                                        </Tooltip>
                                                    </>
                                                }
                                            </>
                                        }
                                        {fanRPM().length > 0 &&
                                            fanRPM().map((f) => {
                                                return (
                                                    <div key={f.name} className={"sensor"}>
                                                        <div >{f.name}</div>
                                                        <div>{f.rpm}</div>
                                                    </div>
                                                );
                                            })
                                        }
                                        {validProbes().length > 0 &&
                                            <div className={"sensor"} style={{minWidth: validProbes().length * 75}}>
                                                <div >{t("panel.status.probe", {count: validProbes().length})}</div>
                                                <div className={"z-probe-container"}>
                                                    {validProbes().map((p, idx) => {
                                                        return (
                                                            <div key={idx}>{formatProbeValues(p.value)}</div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        }

                                    </>
                                </div>
                            </div>
                        }
                    </>
                    : <>
                        <div className={"no-status"}>
                            <div>
                                {t("panel.status.noStatus")}
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

const StatusPanelWrapped = styled(withTranslation()(StatusPanel))((props) => {
    return {
        ".panel-content": {
            userSelect: "none",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
        },
        ".header-container": {
            marginBottom: "4px",
            display: "flex",
            width: "100%",
            position: "relative",
            containerType: "inline-size",
            containerName: "topline",
            "&>.mode": {
                minWidth: "140px",
                textAlign: "right",
            },
            "&>.label": {
                flexGrow: "100",
                textAlign: "center",
            },
            "@container topline (width > 330px)": {
                "&>.left-dummy": {
                    width: "140px",
                },
            },
            "@container topline (width <= 330px)": {
                "&>.left-dummy": {
                    display: "none",
                },
                "&>.label": {
                    flexBasis: "0",
                },
                "&>.mode": {
                    flexBasis: "0",
                    flexGrow: "100",
                    textAlign: "center",
                },
            },
        },
        ".axes-container, .speeds-container, .extruder-container, .sensors-container": {
            display: "flex",
            flexDirection: "column",
            gap: "2px",
            "&>.axes-header, &>.speeds-header, &>.extruder-header, &>.sensors-header": {
                textAlign: "center",
                backgroundColor: props.theme.palette.panels.status.subheader.background,
                foregroundColor: props.theme.palette.panels.status.subheader.foreground,
            },
            "&>.axes-wrapper, &>.speeds-wrapper, &>.extruder-wrapper, &>.sensors-wrapper": {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                "&>.axis, &>.speed, &>.extruder, &>.sensor": {
                    "&>div:first-of-type": {
                        flexGrow: "1",
                    },
                    "&>div": {
                        textAlign: "center",
                        "&:first-of-type": {
                            marginLeft: "4px",
                            marginRight: "4px",
                            "&.x": {
                                backgroundColor: "#fce4ec",
                            },
                            "&.y": {
                                backgroundColor: "#e8f5e9",
                            },
                            "&.z": {
                                backgroundColor: "#e3f2fd",
                            },
                        },
                    },
                    ".z-probe-container": {
                        display: "flex",
                        flexDirection: "row",
                        "&>div": {
                            flexBasis: "0",
                            flexGrow: "1",
                            minWidth: "72px",
                        }
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    flexGrow: "1",
                    flexBasis: "0",
                    minWidth: "72px",
                },
            },
        },
        ".no-status": {
            display: "flex",
            justifyContent: "center",
            "&>div": {
                backgroundColor: props.theme.palette.status.disconnected.background,
                color: props.theme.palette.status.disconnected.foreground,
                textAlign: "center",
                paddingLeft: "10px",
                paddingRight: "10px",
                borderRadius: "4px",
            }
        },
    } as React.CSSProperties as unknown as any;
});

StatusPanelWrapped.defaultProps = {
    minW: 2,
    minH: 8,
    meta: {
        friendlyName: i18n.t('panel.status.friendlyName'),
        description: i18n.t('panel.status.description'),
    }
} as LayoutBase;

export default StatusPanelWrapped;
